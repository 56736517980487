import { gql, useQuery } from "@apollo/client";
import { Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useUser } from "../../../auth/use-user";
import { VideoDetailModel } from "../../../models/video-detail.model";
import { VideoModuleModel } from "../../../models/video-module.model";
import { LoadingDots } from "../../../ui/loading-dots";
import { MultiStreamLimiter } from "./multi-stream-limiter";
import { Player } from "./player";
import { ProvidePlayer } from "./player-context";
import { VideoModuleStream } from "./video-module-stream";

const QueryVideo = gql`
    query video_module_info($module_id: bigint!) {
        video_module_info(id: $module_id) {
            youtube_url
            vimeo_url
        }
    }
`;

type Props = {
    video: VideoDetailModel;
    video_module: VideoModuleModel;
    next_checkpoint?: VideoModuleModel;
}

export const VideoModulePlayer: React.FC<Props> = ({
    video,
    video_module,
    next_checkpoint,
    children,
}) => {
    const classes = useStyles({});
    const loaded_at = React.useMemo(() => new Date(), []);
    const {
        user: {
            updated_at,
            current_video_module_id,
        }
    } = useUser();
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery(QueryVideo, {
        variables: {
            module_id: video_module.id,
        }
    });

    const streaming_id = React.useMemo(() => {
        if (!!current_video_module_id && new Date(updated_at) >= loaded_at) {
            return current_video_module_id;
        }
        return null;
    }, [
        current_video_module_id, updated_at, loaded_at
    ])

    const youtube_id = data?.video_module_info?.vimeo_url || data?.video_module_info?.youtube_url;
    
    if (!youtube_id || loading) {
        return <div className={classes.wrapper}>
            <Container maxWidth='lg' >
                <div className={classes.player}>
                    <div className={classes.error}>
                        {loading ? <LoadingDots /> : <>
                            <Typography variant='h6'>Video not available</Typography>
                            <Typography>{JSON.stringify(error)}</Typography>
                        </>}
                    </div>
                </div>
            </Container>
        </div>;
    }

    if (!!streaming_id && streaming_id !== video_module.id) {
        return <MultiStreamLimiter
            video_module_id={streaming_id}
            current_module_id={video_module.id}
            reload={() => refetch()}
        />
    }

    return <VideoModuleStream
        video_module_id={video_module.id}>{stream => <ProvidePlayer
            video_id={video.id}
            video_module_stream={stream}
            youtube_id={youtube_id}
            is_vimeo={!!data?.video_module_info?.vimeo_url}
            duration={video_module.duration}
            finish_threshold={video_module.finish_threshold}>
            <Player
                video_module={video_module}
                video={video}
                next_checkpoint={next_checkpoint} />
            {children}
        </ProvidePlayer>}</VideoModuleStream>
}

const useStyles = makeStyles(theme => createStyles({
    wrapper: {
        background: 'black',
    },
    player: {
        width: '100%',
        height: 0,
        background: 'black',
        paddingTop: '56.25%',
        position: 'relative',
        '& iframe': {
            position: 'absolute',
            left: 0,
            top: 0,
        }
    },
    error: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        color: 'white',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    diagnostics: {

    },

}))