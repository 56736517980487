export type ProfileModel = {
	id: number;
	created_at: string;
	user_id: string;
	name: string;
	bio?: string;
	profile_image_key?: string;
	logo_image_key?: string;
	email?: string;
	website?: string;
	facebook?: string;
	linkedin?: string;
	twitter?: string;
	instagram?: string;
	phone?: string;
};

export const ProfileFragment = `
	id
	created_at
	user_id
	name
	bio
	profile_image_key
	logo_image_key
	email
	website
	facebook
	linkedin
	twitter
	instagram
	phone
`;