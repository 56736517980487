import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import ReactPlayer from 'react-player';
import { ImageSize, LazyImage } from '../../../image-upload';
import { usePlayer } from './player-context';

type Props = {
	cover_image_key?: string;
}

export const YouTubeSourcePlayer = ({
	cover_image_key
}: Props) => {
	const classes = useStyles({});
	const container_ref = React.useRef<HTMLDivElement>(null);
	const {
		player_ref,
		is_playing,
		youtube_id,
		is_fullscreen,
		is_ended,
		volume,
		playback_position,
		setIsEnded,
		setIsReady,
		playback_rate,
		onProgress,
		setIsPlaying,
		onPlay
	} = usePlayer();

	function downHandler(e: KeyboardEvent) {
		if (!container_ref.current || !e.target) {
			return;
		}
		const target = e.target as HTMLElement;
		if (e.key === ' ' && !target.matches('input') && !target.matches('textarea')) {
			e.preventDefault();
			onPlay(!is_playing)
		}
	}
	React.useEffect(() => {
		window.addEventListener("keydown", downHandler);
		return () => {
			window.removeEventListener("keydown", downHandler);
		};
	}, [container_ref.current, is_playing]);

	return <div ref={container_ref} className={clsx(classes.wrapper, {
		[classes.fullscreen]: is_fullscreen
	})}>
		<ReactPlayer
			url={youtube_id}
			ref={player_ref}
			width='100%'
			height='100%'
			onReady={() => setIsReady(true)}
			progressInterval={1000}
			playbackRate={playback_rate}
			onPlay={() => setIsPlaying(true)}
			onPause={() => setIsPlaying(false)}
			onProgress={onProgress}
			onEnded={() => setIsEnded(true)}
			controls={false}
			volume={volume}
			config={{
				youtube: {
					playerVars: {
						start: Math.floor(playback_position),
						modestbranding: 1,
						fs: 0,
					},
				}
			}}
		/>
		{!!cover_image_key ? <div
			className={`thumb ${(playback_position < 1 && !is_playing) || is_ended ? 'active' : ''}`}>
			<LazyImage
				image_opt={{
					aspect_ratio: 'none',
					size: ImageSize.xlarge,
				}}
				image_key={cover_image_key}
			/>
		</div> : null}
	</div>;
}

const useStyles = makeStyles((theme) => createStyles({
	wrapper: {
		width: '100%',
		paddingTop: '56.25%',
		height: 0,
		background: 'black',
		'& iframe': {
			position: 'absolute',
			left: 0,
			top: 0,
		},
		'& .thumb': {
			position: 'absolute',
			left: 0,
			top: 0,
			right: 0,
			bottom: 0,
			opacity: 0,
			transition: 'all .3s ease',
			'& img': {
				width: '100%',
				height: '100%',
				objectFit: 'cover',
			},
			'&.active': {
				opacity: 1,
			}
		}
	},
	fullscreen: {
		height: '100vh',
		paddingTop: 0,
	}
}));