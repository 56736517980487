import { VideoModuleFragment, VideoModuleModel } from "./video-module.model"
import { VideoProfileFragment, VideoProfileModel } from "./video-profile.model"
import { VideoPublishStatus } from "./video-publish-status"
import { VideoUserFragment, VideoUserModel } from "./video-user.model"

export type VideoDetailModel = {
    id: number;
    name: string;
    created_at: string;
    description: string;
    objectives?: string[];
    trailer_youtube_id: string;
    trailer_image_key: string;
    cover_image_key: string;
    module_order?: number[];
    publish_status: VideoPublishStatus;
    video_module_summary: {
        id: number;
        total_modules?: number;
        total_duration?: number;
    };
    premieres_at?: string;
    chat_id?: number;
    video_profiles: VideoProfileModel[];
    video_modules?: VideoModuleModel[];
    video_users?: VideoUserModel[];
    video_user_tally?: {
        id: number;
        total_users?: number;
    };
    track: {
        id: number;
        name: string;
        rcdso_category: string;
        pace_code: string;
    }
}

export const VideoDetailFragment = `
    id
    name
    created_at
    description
    objectives
    trailer_youtube_id
    trailer_image_key
    cover_image_key
    module_order
    publish_status
    video_module_summary {
        id
        total_modules
        total_duration
    }
    premieres_at
    chat_id
    video_profiles {
        ${VideoProfileFragment}
    }
    video_modules {
        ${VideoModuleFragment}
    }
    video_users {
        ${VideoUserFragment}
    }
    video_user_tally {
        id
        total_users
    }
    track {
        id
        name
        rcdso_category
        pace_code
    }
`