import { createStyles, makeStyles } from "@material-ui/core";
import StarIcon from '@material-ui/icons/Star';
import React from "react";
import { useList } from "../../../apollo/networking";
import { VideoEvaluationPublicFragment, VideoEvaluationPublicModel } from "../../../models/video-evaluation-public.model";
import { EmptyListComponent } from "../../../ui/empty-list.component";
import { LoadingDots } from "../../../ui/loading-dots";
import { ReviewItem } from "./review-item";

type Props = {
    video_id: number;
}

export const VideoRatings = ({
    video_id,
}: Props) => {
    const classes = useStyles({});

    const {
        items,
        loading,
    } = useList<VideoEvaluationPublicModel>({
        entity_name: 'video_evaluation_public',
        fragment: VideoEvaluationPublicFragment,
        query_inner: `where: {
            video_id: {_eq: $video_id},
        }, order_by: {id: desc}`,
        clause_outer: `$video_id: bigint!`,
        variables: {
            video_id,
        }
    });

    return <>
        {items.map(item => <ReviewItem
            key={item.id} item={item} />)}
        {!loading && items.length === 0 ? <EmptyListComponent
            icon={<StarIcon />}
            message='No reviews yet.' /> : null}
        {loading ? <LoadingDots /> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))