import { RouteComponentProps } from "@reach/router"
import React from "react"
import { ActionType } from "../../action-manager/action-manager"
import { useGetByPk } from "../../apollo/networking/use-get-by-pk"
import { useUser } from "../../auth/use-user"
import { AppLayout } from "../../layout/app-layout"
import { VideoDetailFragment, VideoDetailModel } from "../../models/video-detail.model"
import { ErrorPage } from "../../ui/errors"
import { LoadingDots } from "../../ui/loading-dots"
import { useStartVideo } from "./use-start-video"
import { VideoUser } from "./video-user"

type Props = {
    video_id: number;
}

export const VideoPlayPage = ({
    video_id,
}: RouteComponentProps<Props>) => {
    const { manager } = useUser();
    const {
        startVideo,
        loading,
        data,
        is_called,
    } = useStartVideo({
        video_id: video_id as number,
    })
    const {
        entity: video,
        loading: video_loading,
        error: video_error,
    } = useGetByPk<VideoDetailModel>({
        entity_name: 'video',
        fragment: VideoDetailFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: video_id,
        }],
    });

    React.useEffect(() => {
        startVideo();
        manager.insertAction(ActionType.open_video, {
            video_id,
        });
    }, [video_id]);

    if (!is_called || loading || video_loading) {
        return <LoadingDots />
    }

    if (!data || !data.success || !video) {
        return <ErrorPage
            error={data?.failure}
            title='Failed to load this video' />
    }

    return <AppLayout no_container>
        <VideoUser
            video={video}
        />
    </AppLayout>;
}
