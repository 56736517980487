import { Button, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import PlayIcon from '@material-ui/icons/PlayArrow';

import { VideoModuleModel } from '../../../models/video-module.model';
import { VideoNextButton } from '../video-next-button';
import { usePlayer } from './player-context';

type Props = {
	next_checkpoint?: VideoModuleModel
}

export const EndScreen = ({
	next_checkpoint,
}: Props) => {
	const classes = useStyles({});
	const {
		setIsEnded,
		seekTo,
		is_fullscreen,
	} = usePlayer();

	const rewatch = () => {
		seekTo(0);
		setIsEnded(false);
	}

	return <div className={classes.overlay}>
		<div className='buttons'>
			<Button variant='contained'
				size='large'
				startIcon={<PlayIcon />}
				onClick={rewatch}>Re watch</Button>
			<VideoNextButton next_checkpoint={next_checkpoint} />
		</div>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	overlay: {
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		alignItems: 'stretch',
		justifyContent: 'center',
		'& .buttons': {
			padding: theme.spacing(2, 0),
			margin: '0 auto',
			'& button': {
				margin: theme.spacing(.5),
			}
		}
	},
}));