import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';


type Props = {
	max_lines?: number;
	text: string;
	component?: any;
	read_more?: string | false;
	onExpand?: (expand?: boolean) => void;
}


export const TruncateText = ({
	max_lines = 2,
	text,
	component,
	read_more = 'Read more',
	onExpand,
}: Props) => {
	const classes = useStyles({});
	const [limit, setLimit] = React.useState(true);

	const onToggle = (e: React.MouseEvent<any>) => {
		if (!!read_more) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (onExpand) {
			return onExpand(!limit);
		}
		setLimit(!limit);
	}

	if (!text) {
		return null;
	}

	return <LinesEllipsis
		text={text}
		onClick={onToggle}
		maxLine={limit ? max_lines : Number.MAX_SAFE_INTEGER - 2}
		ellipsis={<span className={classes.readMore}>...<strong>
			{read_more}
		</strong></span>}
		component={component}
		basedOn='words'
		style={{ whiteSpace: 'pre-wrap' }}
	/>
}

const useStyles = makeStyles((theme) => createStyles({
	readMore: {
		cursor: 'pointer',
		'& strong': {
			'&:hover': {
				textDecoration: 'underline',
			}
		}
	}
}));