import { createStyles, makeStyles } from "@material-ui/core"
import React from "react"

import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

type Props = {
    text?: string;
}

export const Markdown = ({
    text,
}: Props) => {
   const classes = useStyles({}); 
    return <div className={classes.content}>
    <ReactMarkdown
        plugins={[gfm]}
        linkTarget='_blank'
        children={text || ''} />
</div>
}

const useStyles = makeStyles(theme => createStyles({
    content: {
        flex: 1,
        '& h1': {
            margin: 0,
            marginBottom: theme.spacing(.5),
        },
		'& p': {
			fontSize: theme.typography.body1.fontSize,
			lineHeight: theme.typography.body1.lineHeight,
			wordBreak: 'break-word',
			marginTop: 0,
		},
		'& a': {
			color: theme.palette.info.main,
			textDecoration: 'underline',
			'&:hover': {
				color: theme.palette.info.light,
			}
		},
		'& img': {
			maxWidth: '100%',
		},
	},
}))