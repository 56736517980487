import { FormHelperText } from '@material-ui/core';
import { ErrorMessage, FieldProps } from 'formik';
import React from 'react';
import { getValueAtPath } from '../../utils/get-value-at-path';
import { StarRating } from './star-rating';

interface Props extends FieldProps {
    label?: string;
}

export const StarRatingField = ({
    label,
    field: {
        value,
        name,
    },
    form: {
        setFieldValue,
        errors,
        touched,
    },
}: Props) => {

    const hasError = Boolean(getValueAtPath(errors, name))
        && Boolean(getValueAtPath(touched, name));


    return <>
        <StarRating
            id={name}
            error={hasError}
            label={label}
            rating={value}
            setRating={r => setFieldValue(name, r)} />
        <ErrorMessage name={name} render={err => <FormHelperText
            error>{err}</FormHelperText>} />
    </>
}