import React from 'react';
import { VideoResourceModel } from '../../../../models/video-resource.model';
import { BasicDialog, BasicDialogTitle } from '../../../../ui/dialog';
import { AddVideoResourceContainer } from './video-resource-add.container';
import { EditVideoResourceContainer } from './video-resource-edit.container';

type Props = {
	onClose: () => void;
	video_id: number;
	action: 'add' | 'edit' | 'clone';
	resource?: VideoResourceModel;
	onFinish: (model: VideoResourceModel, is_new?: boolean) => void;
}

export const VideoResourceEditorDialog = ({
	onClose,
	video_id,
	action,
	resource,
	onFinish,
}: Props) => {
	return <BasicDialog
		open={true}
		onClose={onClose}
		id='edit-ticket'>
		<BasicDialogTitle title={action === 'edit'
			? 'Edit Resource'
			: 'Create Resource'}
			onClose={onClose} />
		{action === 'edit' && resource
			? <EditVideoResourceContainer
				resource={resource}
				onFinish={onFinish}
			/>
			: <AddVideoResourceContainer
				video_id={video_id}
				onFinish={onFinish}
			/>}
	</BasicDialog>
}