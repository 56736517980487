import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

type Props = {
	seekTo: (time: number) => void;
	progress_tracker?: string;
	percent_complete: number;
	finish_threshold: number;
}

const UnitOfTime = 30;

export const ModuleProgressbar = ({
	seekTo,
	progress_tracker = '',
	percent_complete,
	finish_threshold,
}: Props) => {
	const classes = useStyles({});

	const completed: {
		start: number;
		width: number;
		filled: boolean;
	}[] = React.useMemo(() => {
		if (!progress_tracker) {
			return [];
		}
		const total_fractions = progress_tracker.length;
		return progress_tracker.split('').map((value, i) => {
			const start = i * UnitOfTime;
			return {
				start,
				filled: value === '1',
				width: 100 / total_fractions,
			}
		});
	}, [progress_tracker])

	return <div className={classes.root}>
		<span className={clsx(
			classes.total,
			finish_threshold < percent_complete ? 'success' : '',
		)}>{Math.floor(percent_complete)}%</span>
		<div className={classes.bar}>
			{completed.map((i, idx) => <div
				onClick={() => seekTo(i.start - 1)}
				key={idx}
				className={clsx(classes.barItem,
					i.filled ? 'filled' : 'unfilled')}
				style={{ width: `${i.width}%` }}
			/>)}
		</div>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		display: 'flex',
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		alignItems: 'center',
	},
	bar: {
		flex: 1,
		display: 'flex',
		borderRadius: theme.shape.borderRadius,
		overflow: 'hidden',
	},
	total: {
		border: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: theme.shape.borderRadius,
		paddingLeft: theme.spacing(.5),
		paddingRight: theme.spacing(.5),
		marginRight: theme.spacing(1),
		background: theme.palette.background.default,
		fontWeight: theme.typography.fontWeightBold,
		'&.success': {
			background: theme.palette.success.main,
		}
	},
	barItem: {
		cursor: 'pointer',
		background: theme.palette.info.light,
		height: theme.spacing(1),
		transition: 'all .3s ease',
		'&.filled': {
		},
		'&.unfilled': {
			background: theme.palette.secondary.dark,
		},
		'&:hover': {
			background: theme.palette.info.main,
			'&.unfilled': {
				background: theme.palette.secondary.main,
			},
		},
	}
}));