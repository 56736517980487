import { createStyles, makeStyles } from '@material-ui/core';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { MarkdownEditorField } from '../../../../ui/mardown-editor.field';

interface Props extends FormikProps<any> {
}

export const VideoResourceFormComponent = ({
	values,
	setFieldValue,
}: Props) => {
	const classes = useStyles({});

	return <div className={classes.root}>
		<Field
			component={TextField}
			name="name"
			type="text"
			label="Resource name"
			placeholder='e.g. Video Handouts'
			fullWidth
			margin='normal'
		/>
		<Field
			component={MarkdownEditorField}
			name='description'
		/>
		<Field
			component={TextField}
			name="link"
			type="text"
			label="Link"
			placeholder='e.g. http://edropin.com/important123'
			fullWidth
			margin='normal'
		/>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		paddingTop: theme.spacing(2),
	},
}));