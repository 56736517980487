import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import RightIcon from '@material-ui/icons/CheckCircle';
import WrongIcon from '@material-ui/icons/Close';
import React from "react";
import { useList } from "../../../apollo/networking";
import { VideoQuestionFragment, VideoQuestionModel } from "../../../models/video-question.model";
import { LoadingDots } from "../../../ui/loading-dots";
import { Markdown } from "../../../ui/markdown";
import { Section } from "../../../ui/section";
import { QuestionEditorDialog } from "./question-editor";

type Props = {
    video_id: number;
}


export const VideoQuestions = ({
    video_id,
}: Props) => {
    const classes = useStyles({});
    const [editor, setEditor] = React.useState<{
        action: 'edit' | 'create';
        question?: VideoQuestionModel;
    } | undefined>();
    const {
        items,
        loading,
        refetch,
    } = useList<VideoQuestionModel>({
        entity_name: 'video_question',
        fragment: VideoQuestionFragment,
        query_inner: `where: {
            video_id: {_eq: $video_id}
        }, order_by: {id: asc}`,
        clause_outer: `$video_id: bigint!`,
        variables: {
            video_id,
        }
    });

    const onFinish = (_: any, is_new?: boolean) => {
        setEditor(undefined);
        if (is_new) {
            refetch();
        }
    }

    const onEdit = (item: VideoQuestionModel) => {
        setEditor({
            action: 'edit',
            question: item,
        })
    }

    const onDelete = (item: VideoQuestionModel) => {
    }

    const onCreate = () => {
        setEditor({
            action: 'create',
        })
    }
    return <Section title='Questions'>
        {editor ? <QuestionEditorDialog
            video_id={video_id}
            action={editor.action}
            question={editor.question}
            onClose={() => setEditor(undefined)}
            onFinish={onFinish}
        /> : null}
        <Button variant='contained' color='secondary' onClick={onCreate}>+ Add Question</Button>
        {items.map(item => <div
            className={classes.item}
            key={item.id}>
            <Markdown text={item.prompt} />
            {item.options.map(o => <div className={classes.option}
                key={o.id}>
                {o.is_correct ? <RightIcon
                    className='right' /> : <WrongIcon
                    className='wrong' />}
                <Typography variant='body2'>
                    {o.label}
                </Typography>
            </div>)}
            <div className='buttons'>
                <Button variant='outlined'
                    onClick={() => onEdit(item)}
                    size='small'>Edit Question</Button>
                <div style={{ flex: 1 }} />
                <Button
                    onClick={() => onDelete(item)}
                    variant='contained'
                    color='primary'
                    size='small'>Delete</Button>
            </div>
        </div>)}
        {loading ? <LoadingDots /> : null}
    </Section>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        padding: theme.spacing(1, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        },
        '& .buttons': {
            paddingTop: theme.spacing(1),
            display: 'flex',
        }
    },
    option: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(.5),
        },
        '& .right': {
            color: theme.palette.success.main,
        },
        '& .wrong': {
            color: theme.palette.primary.main,
        },
    }
}))