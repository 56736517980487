import { FormLabel } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import React from 'react';

type Props = {
    error?: boolean;
    id: string;
    label?: string;
    rating?: number;
    setRating?: (rating: number | null) => void;
}


export const StarRating = ({
    error,
    id,
    label,
    rating,
    setRating,
}: Props) => {

    return <>
        {label ? <FormLabel error={error} style={{
            display: 'block',
            marginTop: 8,
            marginBottom: 8,
        }}>{label}</FormLabel> : null}
        <Rating
            name={id}
            value={rating || 0}
            readOnly={!setRating}
            onChange={(_, newValue) => {
                if (setRating) {
                    setRating(newValue);
                }
            }}
        />
    </>
}
