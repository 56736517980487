import { createStyles, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { ImageSize, LazyImage } from "../../../image-upload";
import { VideoEvaluationPublicModel } from "../../../models/video-evaluation-public.model";
import { StarRating } from "../../../ui/rating";
import { textWithLineBreaks } from "../../../utils/text-with-line-breaks";

type Props = {
    item: VideoEvaluationPublicModel;
}

export const ReviewItem = ({
    item,
}: Props) => {
    const classes = useStyles({});

    const entity = {
        data: {
            overall_course: 5,
            comments: 'This course was awesome!',
        }
    }

    return <div className={classes.item}>
        <div className={classes.header}>
            <LazyImage
                image_key={item.user_profile_image_key}
                placeholder='/images/avatar.jpg'
                className='avatar'
                image_opt={{
                    size: ImageSize.small,
                }}
            />
            <div className='user'>
                <Typography variant='body1'>{item.user_name}</Typography>
                <Typography variant='body2' color='textSecondary'>{
                    moment(item.created_at).fromNow()
                }</Typography>
            </div>
        </div>
        <StarRating
            rating={item.overall_course || 1}
            id='overall_course'
        />
        {item.comments ? <Typography variant='body1'>
            {textWithLineBreaks(item.comments)}
        </Typography> : null}

    </div>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        background: theme.palette.background.paper,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(2, 0),
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
        '& .avatar': {
            width: 40,
            height: 40,
            borderRadius: '50%',
        },
        '& .user': {
            flex: 1,
            paddingLeft: theme.spacing(1),
            '& p': {
                margin: 0,
            }
        }
    }
}))