import { useLocation } from "@reach/router";
import qs from 'query-string';
import React from "react";
import { VideoDetailModel } from "../../models/video-detail.model";
import { VideoUserModel } from "../../models/video-user.model";
import { EdContainer } from "../../ui/ed-container";
import { sortItemsByIdList } from "../../utils/sort-items-by-id-list";
import { VideoBar } from "./video-bar";
import { VideoCOI } from "./video-detail/video-coi";
import { VideoRequirements } from "./video-detail/video-requirements";
import { VideoLicensure } from "./video-licensure";
import { VideoModules } from "./video-modules";
import { VideoPlayTabs } from "./video-play-tabs";
import { VideoModulePlayer } from "./video-player/video-module-player";

type Props = {
    video_user: VideoUserModel;
    video: VideoDetailModel;
}

export const VideoPlay = ({
    video_user,
    video,
}: Props) => {
    const location = useLocation();
    const query_ck_id = qs.parse(location.search || '')?.ck;
    const [current_module_idx, setCurrentModuleIdx] = React.useState<number | null>(null);

    const sorted_modules = React.useMemo(() => {
        return sortItemsByIdList(
            video?.module_order || [], video?.video_modules || [])
    }, [video?.video_modules, video?.module_order])

    React.useEffect(() => {
        if (sorted_modules.length === 0 || !video_user) {
            return;
        }
        if (query_ck_id) {
            const video_module_id = parseInt(query_ck_id as string, 10);
            if (!!current_module_idx && sorted_modules[current_module_idx] && sorted_modules[current_module_idx].id === video_module_id) {
                return;
            }
            const selected_module = sorted_modules
                .findIndex(m => m.id === video_module_id);
            if (selected_module > -1) {
                setCurrentModuleIdx(selected_module)
                return;
            }
        }
        const next_to_complete_idx = sorted_modules.findIndex(c =>
            !c.video_module_streams || c.video_module_streams.length === 0 || !c.video_module_streams[0].completed_at);
        setCurrentModuleIdx(next_to_complete_idx > -1 ? next_to_complete_idx : 0)
    }, [sorted_modules, query_ck_id])

    const [current_module, next_checkpoint] = React.useMemo(() => {
        if (current_module_idx === null) {
            return [];
        }
        return [sorted_modules[current_module_idx], current_module_idx !== null && sorted_modules.length - 1 > current_module_idx
            ? sorted_modules[current_module_idx + 1] : undefined];
    }, [sorted_modules, current_module_idx])

    if (!current_module) {
        return null;
    }

    return <VideoModulePlayer
        video={video}
        next_checkpoint={next_checkpoint}
        video_module={current_module}>
        <EdContainer>
            <VideoBar
                video={video}
                video_module={current_module}
                current_module_idx={current_module_idx}
            />
            <VideoModules
                id={video.id}
                video_modules={sorted_modules}
                selected_id={current_module?.id}
                current_module_idx={current_module_idx}
            />
            <VideoLicensure
                video_id={video.id}
                module_order={video.module_order}
            />
            <VideoPlayTabs
                video={video}
                video_module={current_module}
            />
            <VideoCOI />
            <VideoRequirements />
        </EdContainer>
    </VideoModulePlayer>
}
