import { Box, createStyles, makeStyles } from '@material-ui/core';
import PostIcon from '@material-ui/icons/Send';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { useAppDialog } from '../app-dialog';
import { LoadingButton } from '../ui/loading-button';
import { MessageForm } from './message-form';

type Props = {
	insertMessage: (values: any) => Promise<boolean>;
	inserting?: boolean;
}

const validation = yup.object().shape({
	text: yup.string().required('Please type in a message'),
})

export const MessageInsert = ({
	insertMessage,
	inserting,
}: Props) => {
	const app_dialog = useAppDialog();
	const classes = useStyles({});

	const onSubmit = async (values: any, actions: FormikHelpers<any>) => {
		if (await insertMessage(values)) {
			actions.resetForm();
		} else {
			actions.setSubmitting(false);
		}
	}

	return <Formik
		onSubmit={onSubmit}
		validationSchema={validation}
		initialValues={{
			text: '',
			images: [],
		}}>{(form_props) => <Form className={classes.wrapper}>
			<MessageForm {...form_props} />
			{form_props.values.text || (form_props.values.images || []).length > 0
				? <Box p={2} pt={1} textAlign='right'>
					<LoadingButton
						loading={inserting}
						variant='contained'
						type='submit'
						color='primary'
						endIcon={<PostIcon />}
					>Post</LoadingButton>
				</Box> : null}
		</Form>}
	</Formik>
}


const useStyles = makeStyles(theme => createStyles({
	wrapper: {
		// background: theme.palette.divider,
		// borderRadius: theme.shape.borderRadius,
	}
}))
