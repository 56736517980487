import React from 'react';
import { ProfileModel } from '../../../models/profile.model';
import { BasicDialog, BasicDialogTitle } from '../../../ui/dialog';
import { ProfileAddContainer } from './profile-add.container';
import { ProfileEditContainer } from './profile-edit.container';

type Props = {
	action: 'add' | 'clone' | 'edit';
	profile?: ProfileModel;
	onClose: () => void;
	onFinish?: (profile: ProfileModel, is_new?: boolean) => void;
}

export const ProfileEditorDialog = ({
	action,
	profile,
	onClose,
	onFinish,
	...props
}: Props) => {
	return <BasicDialog
		open={true}
		id='dropin-editor'
		onClose={onClose}>
		<BasicDialogTitle
			title={action === 'edit' ? 'Edit profile' : 'Create profile'}
			onClose={onClose} />
		{action === 'edit' && !!profile ? <ProfileEditContainer
			{...props}
			profile={profile}
			onFinish={onFinish}
		/> : <ProfileAddContainer
				{...props}
				profile={profile}
				onFinish={onFinish}
			/>}
	</BasicDialog>
}