export type VideoLicensureModel = {
    id: number;
    created_at: string;
    state_id: number;
    video_id: number;
    name: string;
    deleted_at?: string;
    total_ce?: number;
    state: {
        id: number;
        name: string;
        country_id: number;
    }
}

export const VideoLicensureFragment = `
    id
    created_at
    state_id
    video_id
    name
    total_ce
    deleted_at
    state {
        id
        name
        country_id
    }
`;

