import { Button, createStyles, Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useUser } from '../../auth/use-user';
import { ImageSize, LazyImage } from '../../image-upload';
import { ProfileModel } from '../../models/profile.model';
import { Markdown } from '../../ui/markdown';
import { ProfileSocial } from './profile-social';

type Props = {
	item: ProfileModel;
	onEdit: () => void;
}

export const ProfileItem = ({
	item,
	onEdit,
}: Props) => {
	const classes = useStyles({});
	const { user_id } = useUser();

	return <div className={classes.item}>
		<LazyImage
			image_key={item.profile_image_key}
			image_opt={{ size: ImageSize.small }}
			className={classes.image}
			placeholder={'/images/avatar.jpg'}
		/>
		<div className={classes.content}>
			<Typography variant='h6' gutterBottom>{item.name}</Typography>
			{item.website ? <Link href={`http://${item.website}`} target='_blank'>{item.website}</Link> : null}
			<Markdown text={item.bio} />
			<ProfileSocial {...item} />
		</div>

		<div className={classes.buttons}>
			{user_id === item.user_id ?
				<Button onClick={onEdit}>
					Edit
				</Button> : null}
		</div>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	item: {
		display: 'flex',
	},
	image: {
		marginTop: theme.spacing(1),
		width: 60,
		height: 60,
		borderRadius: '50%',
	},
	category: {
		margin: theme.spacing(.5),
	},
	content: {
		padding: theme.spacing(0, 1),
		flex: 1,
	},
	buttons: {
		marginTop: theme.spacing(1),
	}
}));