import { Button, createStyles, Hidden, IconButton, makeStyles, Slider, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import SkipNextIcon from '@material-ui/icons/Forward10';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayArrow';
import SkipPrevIcon from '@material-ui/icons/Replay10';
import VolumeMutedIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import React from 'react';
import { getDuration } from '../../../utils/duration';
import { padNumber } from '../../../utils/pad-number';
import { ModuleProgressbar } from './module-progressbar';
import { usePlayer } from './player-context';
import { VolumeSlider } from './volume-slider';


type Props = {
	togglePlay: () => void;
	onMouseMove: () => void;
	module_name: string;
}

export const ControlBar = ({
	togglePlay,
	onMouseMove,
	module_name,
}: Props) => {
	const classes = useStyles({});
	const {
		playback_position,
		seekTo,
		is_playing,
		onPlay,
		volume,
		setVolume,
		is_fullscreen,
		setPlaybackRate,
		playback_rate,
		setFullscreen,
		duration,
		finish_threshold,
		progress_tracker,
		percent_complete,
	} = usePlayer();
	const theme = useTheme();
	const is_large = useMediaQuery(theme.breakpoints.up('sm'));
	const fontSize = is_large ? 'large' : 'default';

	const handleChange = (_: any, v: number | number[]) => {
		if (typeof v === 'number') {
			const position = v / 100 * duration;
			seekTo(position);
		}
	}

	const handleVolumeChange = (_: any, v: number | number[]) => {
		if (typeof v === 'number') {
			setVolume(v / 100);
		}
	}

	const toggleSpeed = () => {
		if (playback_rate >= 2) {
			setPlaybackRate(1);
			return;
		}
		setPlaybackRate(
			playback_rate + 0.25,
		)
	}

	const [played_h, played_m, played_s] = getDuration(playback_position);
	const [remaining_h, remaining_m, remaining_s] = getDuration(duration - playback_position);

	return <div className={classes.root}
		onMouseMove={onMouseMove}>
		<div className={classes.playIndicator} onClick={togglePlay} >
			{!is_playing ? <PlayIcon onClick={() => onPlay(true)} /> : null}
		</div>
		<div className={classes.controlBar}>
			<ModuleProgressbar
				progress_tracker={progress_tracker}
				seekTo={seekTo}
				percent_complete={percent_complete || 0}
				finish_threshold={finish_threshold}
			/>
			<div className={classes.progressBar}>
				<Typography className='spaceRight' variant='body1'>
					{played_h > 0 ? `${padNumber(played_h)}:` : ''}{padNumber(played_m)}:{padNumber(played_s)}
				</Typography>
				<div className='bar'>
					<Slider color='primary'
						value={playback_position / duration * 100}
						onChange={handleChange}
						aria-labelledby="playback-seekbar" />
				</div>
				<Typography className='spaceLeft' variant='body1'>
					{remaining_h > 0 ? `${padNumber(remaining_h)}:` : ''}{padNumber(remaining_m)}:{padNumber(remaining_s)}
				</Typography>
			</div>
			<div className={classes.controls}>
				<IconButton onClick={() => onPlay(!is_playing)}>
					{!is_playing
						? <PlayIcon fontSize={fontSize} />
						: <PauseIcon fontSize={fontSize} />}
				</IconButton>
				<IconButton onClick={() => setVolume(volume === 0 ? 100 : 0)}>
					{volume === 0 ? <VolumeMutedIcon fontSize={fontSize} /> : <VolumeUpIcon fontSize={fontSize} />}
				</IconButton>
				<div className={classes.volumeSlider}>
					<VolumeSlider
						value={volume * 100}
						onChange={handleVolumeChange} />
				</div>
				<Hidden smDown>
					<IconButton onClick={() => seekTo(playback_position - 10)}>
						<SkipPrevIcon fontSize={fontSize} />
					</IconButton>
				</Hidden>
				<IconButton onClick={() => seekTo(playback_position + 10)}>
					<SkipNextIcon fontSize={fontSize} />
				</IconButton>
				<Typography style={{
					flex: 1, overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis'
				}} variant='body1'>{module_name}</Typography>
				<Button
					className={classes.speed}
					onClick={toggleSpeed}
					variant='contained'
					size='small'
					style={{
						fontWeight: 'bold',
						fontSize: '1.2em',
						marginRight: 8,
					}}>
					{playback_rate.toFixed(2)} x
				</Button>
				<IconButton onClick={() => setFullscreen(!is_fullscreen)}>
					{is_fullscreen
						? <FullscreenExitIcon fontSize={fontSize} />
						: <FullscreenIcon fontSize={fontSize} />
					}
				</IconButton>
			</div>
		</div>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	controlBar: {
		padding: theme.spacing(1),
		background: 'rgba(0, 0, 0, 0.9)',
	},
	progressBar: {
		display: 'flex',
		alignItems: 'center',
		color: 'white',
		'& .spaceRight': {
			paddingRight: theme.spacing(2),
		},
		'& .spaceLeft': {
			paddingLeft: theme.spacing(2),
		},
		'& .bar': {
			flex: 1,
			marginTop: 5,
		}
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		color: 'white',
		'& svg': {
			color: 'white',
		}
	},
	playIndicator: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& svg': {
			width: 30,
			height: 30,
			transition: 'all 0.3s ease',
			[theme.breakpoints.up('sm')]: {
				width: 40,
				height: 40,
			},
			background: 'rgba(0, 0, 0, 0.6)',
			borderRadius: '50%',
			color: 'white',
			'&:hover': {
				transform: 'scale(1.2)',
			},
		},
	},
	volumeSlider: {
		width: 50,
		[theme.breakpoints.up('md')]: {
			width: 100,
		}
	},
	speed: {
		[theme.breakpoints.down('md')]: {
			position: 'absolute',
			left: theme.spacing(1),
			top: theme.spacing(1),
		}
	}
}));

