import { useMutation } from "@apollo/client";
import { Button, createStyles, IconButton, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import gql from "graphql-tag";
import React from "react";
import ReactPlayer from 'react-player';
import { useUpdateByPk } from "../../apollo/networking/use-update-by-pk";
import { useAppDialog } from "../../app-dialog";
import { ImageSize, LazyImage } from "../../image-upload";
import { VideoModuleModel } from "../../models/video-module.model";
import { getDurationText } from "../../utils/duration";


type Props = {
    item: VideoModuleModel;
    move?: (direction: 'up' | 'down') => void;
    is_up_enabled?: boolean;
    is_down_enabled?: boolean;
    onEdit: () => void;
}

const DeleteMutation = gql`
    mutation delete_module($module_id: bigint!) {
        delete_video_module_by_pk(id: $module_id) {
            id
        }
    }
`;

export const VideoModuleItem = ({
    item,
    move,
    is_down_enabled,
    is_up_enabled,
    onEdit,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const [deleteModule, delete_status] = useMutation(DeleteMutation);

    const onDelete = () => {
        app_dialog.showDialog({
            title: 'Delete Module',
            message: 'Are you sure you want to delete this video module? This cannot be undone and will everyone who has completed this module in losing their CE.',
            buttons: [{
                label: 'Cancel',
            }, {
                label: 'Delete',
                color: 'primary',
                onClick: async () => {
                    try {
                        const { data } = await deleteModule({
                            variables: {
                                module_id: item.id
                            }
                        });
                        if (data?.delete_video_module_by_pk?.id) {
                            app_dialog.showSnackbar('Deleted module');
                        }
                    } catch (e) {
                        app_dialog.showError(e);
                    }
                }
            }]
        })
    }

    return <div className={classes.item}>
        {!move ? null : <div className={classes.reorder}>
            <IconButton disabled={!is_up_enabled} onClick={() => move('up')}>
                <UpIcon />
            </IconButton>
            <IconButton disabled={!is_down_enabled} onClick={() => move('down')}>
                <DownIcon />
            </IconButton>
        </div>}
        <div className={classes.player}>
            <ReactPlayer
                url={item.youtube_url}
                width={'100%'}
                height={'100%'}
                controls
            />
        </div>
        <div className={classes.content}>
            <Typography variant='body1'>
                <strong>{item.name}</strong> <IconButton
                    onClick={onEdit} size='small'
                ></IconButton>
            </Typography>

            <Typography>{getDurationText(item.duration, true)}</Typography>
            <div style={{ flex: 1 }} />
            <div className={classes.buttons}>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={onEdit}
                    startIcon={<EditIcon />}
                    size='small'>Edit</Button>
                <Button
                    onClick={onDelete}
                    variant='contained'
                    color='primary'
                    size='small'>Delete</Button>
            </div>
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        padding: theme.spacing(2, 0),
        display: 'flex',
    },
    player: {
        width: 300,
        height: 200,
        backgroundColor: 'black',
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
    },
    content: {
        paddingLeft: theme.spacing(2),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    reorder: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 60,
        alignSelf: 'flex-start',
    },
    buttons: {
        '& button': {
            marginRight: theme.spacing(.5),
        }
    }
}))