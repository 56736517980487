import { environment } from "../environment"
import React from "react"

type Props = {
	errors?: any;
	values?: any;
	hidden?: boolean
}

export const DevFormValues = ({ hidden, errors, values }: Props) => {

	return environment.production || hidden ? null : <pre>
		<h2>Errors</h2>
		{JSON.stringify(errors, null, 2)}
		<h2>Values</h2>
		{JSON.stringify(values, null, 2)}
	</pre>
}