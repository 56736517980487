import { Button, Chip, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { environment } from "../../environment";
import { ImageSize, LazyImage } from "../../image-upload";
import { VideoProfileModel } from "../../models/video-profile.model";
import { Markdown } from "../../ui/markdown";


type Props = {
    description?: string;
    onEdit: () => void;
    video_profiles: VideoProfileModel[];
}

export const VideoAbout = ({
    description,
    onEdit,
    video_profiles,
}: Props) => {
    const classes = useStyles({});
    return <div className={classes.about}>
        <div className={classes.tags}>
            {(video_profiles || []).map(({ profile }) => {
                return <Chip
                    component='a'
                    label={profile.name}
                    href={`${environment.app_frontend_url}/p/${profile.id}`}
                    target='_blank'
                    clickable
                    icon={<LazyImage
                        image_opt={{
                            size: ImageSize.small,
                        }}
                        className='profile'
                        image_key={profile.profile_image_key}
                        placeholder={'/images/avatar.jpg'}
                    />}
                    variant={'default'}
                    className='profileTag'
                />
            })}
        </div>
        <Typography variant='overline' style={{display: 'block'}}>Video Description</Typography>
        {description ?
            <Markdown
                text={description}
            /> : <Button
            variant='contained'
            onClick={onEdit} size='small'>Add a description to help viewers learn more about your video</Button>}
        
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    about: {
        paddingBottom: theme.spacing(4),
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    tags: {
        marginLeft: theme.spacing(-.5),
        marginRight: theme.spacing(-.5),
        marginTop: theme.spacing(1),
        '& .tag': {
            margin: theme.spacing(.5),
            background: theme.palette.primary.main,
            color: 'white',
        },
        '& .profileTag': {
            margin: theme.spacing(.5),
            background: theme.palette.grey[900],
            color: 'white',
            '& .profile': {
                width: 30,
                height: 30,
                borderRadius: '50%',
            },
            '&:hover': {
                background: theme.palette.grey[800],
            }
        },
    },
}))