import { Box, Button, createStyles, IconButton, makeStyles } from "@material-ui/core";
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { Field, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";


interface Props {
    name: string;
    values: string[];
}

export const Objectives = ({
    name,
    values,
}: Props) => {
    const classes = useStyles({});
    return <>
        <FieldArray
            name={name}
            render={arrayHelpers => (
                <div>
                    {(values || []).map((_, index) => (
                        <div key={index}>
                            <Box display='flex' alignItems='center'>
                                <Field
                                    name={`${name}.${index}`}
                                    component={TextField}
                                    fullWidth
                                    label='Objective'
                                    placeholder='e.g. Learn about XYZ'
                                    margin='dense'
                                />
                                <IconButton
                                    type="button"
                                    color='secondary'
                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </Box>

                        </div>
                    ))}
                    <Button fullWidth type="button" onClick={() => arrayHelpers.push('')}>
                        + Add another
                                    </Button>

                </div>
            )} />
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))