import { Button, createStyles, DialogContent, IconButton, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/CameraAlt';
import SocialIcon from '@material-ui/icons/Wifi';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { Image, ImageSize, ImageUpload, LazyImage } from '../../../image-upload';
import { DevFormValues } from '../../../ui/dev-form-values';
import { MarkdownEditorField } from '../../../ui/mardown-editor.field';
import { Section } from '../../../ui/section';
import RemoveIcon from '@material-ui/icons/RemoveCircle';



interface Props extends FormikProps<any> {
}

const PLACEHOLDER_FEATURE_EVENT = '';

export const ProfileEditorFormComponent = ({
	values,
	setFieldValue,
}: Props) => {
	const [image_upload, setImageUpload] = React.useState<'profile_image_key' | 'logo_image_key' | undefined>();
	const classes = useStyles({});

	const onChangeImage = (images: Image[]) => {
		if (images.length === 0 || !image_upload) {
			return;
		}
		setFieldValue(image_upload, images[0].key);
		setImageUpload(undefined);
	}

	return <DialogContent>
		{image_upload ? <ImageUpload
			onSelect={onChangeImage}
			handleClose={() => setImageUpload(undefined)}
		/> : null}
		<div className={classes.imageContainer}>
			<LazyImage
				image_key={values.profile_image_key}
				placeholder={PLACEHOLDER_FEATURE_EVENT}
				image_opt={{ size: ImageSize.xlarge, aspect_ratio: 'none' }}
				className={classes.image}
				onClick={() => setImageUpload('profile_image_key')}
			/>
		</div>
		<div className={classes.imageButton}>
			<Button
				startIcon={<CameraIcon />}
				onClick={() => setImageUpload('profile_image_key')}
				variant='contained'
				size='large'
				color='secondary'
			>{values.profile_image_key ? 'Change' : 'Upload'}</Button>
		</div>

		<Field
			component={TextField}
			name="name"
			type="text"
			label="Title"
			placeholder='e.g. Dr. Saj Arora'
			variant='outlined'
			fullWidth
			margin='normal'
		/>
		<Field
			component={MarkdownEditorField}
			name='bio'
		/>

		<Section
			title='Social'
			icon={<SocialIcon />}>
			<Field
				component={TextField}
				name="website"
				type="text"
				label="Website"
				placeholder='e.g. edropin.com'
				variant='outlined'
				fullWidth
				margin='normal'
				InputProps={{
					startAdornment: <InputAdornment position='start'>http://</InputAdornment>
				}}
			/>
			<Field
				component={TextField}
				name="email"
				type="text"
				label="Email"
				placeholder='e.g. example@edropin.com'
				variant='outlined'
				fullWidth
				margin='normal'
			/>
			<Field
				component={TextField}
				name="phone"
				type="text"
				label="Phone number"
				placeholder='e.g. (555) 555-5545'
				variant='outlined'
				fullWidth
				margin='normal'
			/>
			<Field
				component={TextField}
				name="facebook"
				type="text"
				label="Facebook"
				placeholder='e.g. http://facebook.com/edropin.inc'
				variant='outlined'
				fullWidth
				margin='normal'
			/>
			<Field
				component={TextField}
				name="twitter"
				type="text"
				label="Twitter handle"
				placeholder='e.g. edropin.inc'
				variant='outlined'
				fullWidth
				margin='normal'
				InputProps={{
					startAdornment: <InputAdornment position='start'>@</InputAdornment>
				}}
			/>
			<Field
				component={TextField}
				name="linkedin"
				type="text"
				label="Linked In"
				placeholder='e.g. http://linkedin.com/in/dr.saj'
				variant='outlined'
				fullWidth
				margin='normal'
			/>
			<Field
				component={TextField}
				name="instagram"
				type="text"
				label="Instagram"
				placeholder='e.g. http://instagram.com/edropin.inc'
				variant='outlined'
				fullWidth
				margin='normal'
			/>
			<Typography variant='h6'>Logo (Optional)</Typography>
			<div className={classes.logoContainer}>
				<LazyImage
					image_key={values.logo_image_key}
					placeholder={PLACEHOLDER_FEATURE_EVENT}
					image_opt={{ size: ImageSize.xlarge, aspect_ratio: 'none' }}
					className={classes.logo}
					onClick={() => setImageUpload('logo_image_key')}
				/>

				<div className={classes.float}>
					<IconButton onClick={() => setFieldValue('logo_image_key', null)}>
						<RemoveIcon color='primary' />
					</IconButton>
					<Button
						startIcon={<CameraIcon />}
						onClick={() => setImageUpload('logo_image_key')}
						variant='contained'
						size='small'
						color='default'
					>{values.profile_image_key ? 'Change' : 'Upload'}</Button>
				</div>
			</div>
		</Section>
		<DevFormValues values={values} hidden />
	</DialogContent>
}

const useStyles = makeStyles((theme) => createStyles({
	imageContainer: {
		marginTop: theme.spacing(2),
		position: 'relative',
		background: 'grey',
		overflow: 'hidden',
		width: 300,
		height: 300,
		borderRadius: '50%',
		margin: '0 auto',
		marginBottom: theme.spacing(1),
	},
	float: {
		position: 'absolute',
		top: theme.spacing(.5),
		right: theme.spacing(.5),
		background: 'rgba(0, 0, 0, .7)',
		zIndex: 2,
		padding: theme.spacing(0, .5),
		borderRadius: theme.shape.borderRadius,
	},
	image: {
		display: 'block',
		position: 'absolute',
		objectFit: 'cover',
		width: '100%',
		height: '100%',
		left: 0,
		top: 0,
		cursor: 'pointer',
	},
	logoContainer: {
		marginTop: theme.spacing(2),
		position: 'relative',
		background: 'grey',
		overflow: 'hidden',
		width: 300,
		height: 150,
		borderRadius: theme.shape.borderRadius,
		marginBottom: theme.spacing(1),
	},
	logo: {
		display: 'block',
		position: 'absolute',
		objectFit: 'contain',
		width: '100%',
		height: '100%',
		left: 0,
		top: 0,
		cursor: 'pointer',
	},
	imageButton: {
		textAlign: 'center',
		paddingBottom: theme.spacing(2),
	}
}));