import { Slider, withStyles } from "@material-ui/core";

export const VolumeSlider = withStyles({
    root: {
        color: '#fff',
        height: 4,
        marginTop: 4,
      },
      thumb: {
        height: 12,
        width: 12,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -4,
        marginLeft: -6,
        '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
        },
      },
      active: {},
      valueLabel: {
        left: 'calc(-50% + 4px)',
      },
      track: {
        height: 4,
        borderRadius: 2,
      },
      rail: {
        height: 4,
        borderRadius: 2,
      },
})(Slider);