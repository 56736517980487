import { createStyles, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import MDEditor from '@uiw/react-md-editor';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import ReactPlayer from 'react-player';
import { Section } from '../../../ui/section';
import { YoutubeVideoInputField } from '../../../ui/youtube-video-input-field';
import { getDurationText } from '../../../utils/duration';

interface Props extends FormikProps<any> {
}

export const VideoModuleFormComponent = ({
	values,
	setFieldValue,
}: Props) => {
	const classes = useStyles({});

	const handleChange = (value: any) => {
		setFieldValue('description', value);
	};

	return <div className={classes.root}>
		<Field
			component={TextField}
			name="name"
			type="text"
			label="Module name"
			placeholder='Guests appreciate a descriptive name'
			fullWidth
			margin='normal'
		/>
		<Section title='Video'>
			<Field
				name='youtube_url'
				component={YoutubeVideoInputField}
				type='text'
				fullWidth
				placeholder='e.g. https://youtu.be/kjncxpog'
				label='Paste your YouTube Url'
				margin='normal'
				helperText='Please make sure the video is marked unlisted'
				renderPlayer={({ url }: { url: string }) => url ? <>
					<Typography variant='h6' align='center' gutterBottom>
						<span role='img'>🔥</span> Here is the preview of your module!</Typography>
					<div className={classes.player}>
						<ReactPlayer
							className='frame'
							controls={true}
							width={'100%'}
							height={'100%'}
							url={url}
							onDuration={d => setFieldValue('duration', d)}
						/></div>
				</> : null}
			/>
			{values.duration ? <Typography variant='h6'>Duration: {getDurationText(values.duration, true)}</Typography> : null}
		</Section>
		<Section
			title='Completion criteria'>
			<Field
				component={TextField}
				name="finish_threshold"
				type="number"
				label="Finish percentage"
				margin='normal'
				fullWidth
				InputProps={{
					endAdornment: <InputAdornment position="start">%</InputAdornment>,
				}}
				helperText="e.g. 90% of watch time"
			/>
		</Section>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		paddingTop: theme.spacing(2),
	},
	source: {
		'& .header': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		}
	},
	player: {
		margin: theme.spacing(2, 'auto'),
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		paddingTop: '50%',
		height: 0,
		position: 'relative',
		'& .frame': {
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
			borderRadius: theme.shape.borderRadius,
			overflow: 'hidden',
		},
	}
}));