import * as yup from 'yup';
import { VideoModel } from '../../../models/video.model';

export const VideoEditorInitialValues = (
	video?: VideoModel,
) => ({
	name: video?.name || '',
	objectives: video?.objectives || [''],
	cover_image_key: video?.cover_image_key || null,
	video_profiles: (video?.video_profiles || []).map(({ profile }) => profile),
	description: video?.description || '',
	premieres_at: video?.premieres_at || new Date().toISOString(),
	track: video?.track || null,
});


export const VideoEditorValidationSchema = yup.object().shape({
	name: yup.string().required('Video name is required'),
	track: yup.object().required('Please select a track').typeError('Please select a track'),
	description: yup.string()
		.max(15000, 'Description must be less than 15000 characters'),
});

export const getSubmissionData = ({
	track,
	...values
}: any) => {
	return {
		...values,
		track_id: track?.id,
	};
}