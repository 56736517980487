import { createStyles, Dialog, makeStyles } from "@material-ui/core";
import React from "react";
import { isMobile } from "react-device-detect";
import Fullscreen from 'react-full-screen';
import clsx from 'clsx';
import { useHideCrisp } from "../../utils/use-hide-crisp";

type Props = {
    is_fullscreen: boolean;
    setFullscreen: (v: boolean) => void;
}

export const FullScreenVideo: React.FC<Props> = ({
    children,
    is_fullscreen,
    setFullscreen,
}) => {
    const classes = useStyles({});
    const {
        hide,
        show,
    } = useHideCrisp();

    React.useEffect(() => {
        if (!isMobile) {
            return;
        }
        if (is_fullscreen) {
            hide();
            return;
        };
        show();
    }, [is_fullscreen])

    if (!isMobile) {
        return <Fullscreen
            enabled={is_fullscreen}
            onChange={setFullscreen}>
            {children}
        </Fullscreen>
    }

    if (is_fullscreen) {
        return <Dialog fullScreen open={true} onClose={() => setFullscreen(false)}>
            {children}
        </Dialog>
    }

    return <>
        {children}
    </>;
}

const useStyles = makeStyles(theme => createStyles({
    // fullscreenActive: {
    //     position: 'fixed',
    //     left: 0,
    //     width: '100vw',
    //     height: '100vh',
    //     top: 0,
    //     zIndex: 9999,
    //     // paddingBottom: 80,
    // },
}))