import { gql, useQuery } from "@apollo/client";
import { Box, Button, Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { LoadingDots } from "../../../ui/loading-dots";
import { UnstyledLink } from "../../../ui/unstyled-link";


const Query = gql`
    query videos($module_1: bigint!, $module_2: bigint!) {
        video_module_1: video_module_by_pk(id: $module_1) {
            id
            name
            video {
                id
                name
            }
        }
        video_module_2: video_module_by_pk(id: $module_2) {
            id
            name
            video {
                id
                name
            }
        }
    }
`;

type Props = {
    video_module_id: number;
    current_module_id: number;
    reload: () => void;
}

export const MultiStreamLimiter = ({
    video_module_id,
    current_module_id,
    reload,
}: Props) => {
    const classes = useStyles({});
    const { data, loading } = useQuery(Query, {
        variables: {
            module_1: video_module_id,
            module_2: current_module_id,
        },
    });
    const video_module_1 = data?.video_module_1 as {
        id: number;
        name: string;
        video: {
            id: number;
            name: string;
        }
    };
    const current_module = data?.video_module_2 as {
        id: number;
        name: string;
        video: {
            id: number;
            name: string;
        }
    };

    return <div className={classes.root}>
        <Container>
            <Typography
                variant='h2'
                gutterBottom
                align='center'>Already Streaming</Typography>
            {loading ? <LoadingDots /> : null}
            <Typography variant='body1' align='center'>There are two videos trying to play at the same time. Please pick the video you would like to watch</Typography>
            {current_module && video_module_1 ? <div className={classes.buttons}>
                <Box display='flex' alignItems='center'>
                    <Typography variant='h6' style={{ flex: 1 }}>
                        {current_module.video.name}: {current_module.name}
                    </Typography>
                    <Button variant='contained'
                        size='large'
                        onClick={reload}
                        color='primary'>Watch Now</Button>
                </Box>
                <div className='or'>- OR -</div>
                <Box display='flex' alignItems='center'>
                    <Typography variant='h6' style={{ flex: 1 }}>
                        {video_module_1.video.name}: {video_module_1.name}
                    </Typography>
                    <UnstyledLink to={`/v/${video_module_1.video.id}?ck=${video_module_1.id}`}>
                        <Button
                            size='large'
                            variant='contained'
                            color='secondary'>Watch Now</Button>
                    </UnstyledLink>
                </Box>
            </div> : null}
            <Typography variant='body2'>Not streaming already, make sure you don't have
            another edropin tab open in background or you are
            not logged into another device.</Typography>
        </Container>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        background: theme.palette.divider,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    buttons: {
        padding: theme.spacing(4, 0),
        '& h6': {
            paddingRight: theme.spacing(2),
        },
        '& .or': {
            fontSize: '2em',
            fontWeight: 'bold',
            padding: theme.spacing(2, 0),
            textAlign: 'center',
        },
    }
}))