import { Button } from "@material-ui/core"
import { RouteComponentProps, useNavigate } from "@reach/router"
import moment from "moment"
import React from "react"
import { useKeysetPagination } from "../../apollo/networking/use-keyset-pagination"
import { AppLayout } from "../../layout/app-layout"
import { VideoFragment, VideoModel } from "../../models/video.model"
import { EmptyListComponent } from "../../ui/empty-list.component"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { UnstyledLink } from "../../ui/unstyled-link"
import { VideoEditorDialog } from "./video-editor"
import { VideoItem } from "./video-item"


type Props = {

}


export const VideoListPage = (props: RouteComponentProps<Props>) => {
    const navigate = useNavigate();
    const cursor = React.useMemo(() => moment().endOf('hour').toISOString(), []);
    const [editor, setEditor] = React.useState<{
        action: 'edit' | 'add';
        model?: VideoModel;
    } | undefined>();
    const {
        items,
        loading,
        refetch,
        has_more,
        fetchMore,
    } = useKeysetPagination<VideoModel>({
        entity_name: 'video',
        fragment: VideoFragment,
        query_inner: `where: {
            _and: [
                {created_at: {_lt: $cursor}}
            ]
        }, order_by: {created_at: desc}`,
        clause_outer: `$cursor: timestamptz!`,
        variables: {
            cursor,
        }
    });
    const onAdd = () => {
        setEditor({
            action: 'add',
        })
    }
    const onFinish = (v: VideoModel, is_new?: boolean) => {
        setEditor(undefined);
        if (is_new) {
            refetch();
            navigate(`/v/${v.id}`);
        }
    }

    return <AppLayout>
        {!!editor ? <VideoEditorDialog
            onClose={() => setEditor(undefined)}
            onFinish={onFinish}
            action={editor.action}
            video={editor.model}
        /> : null}
        <PageHeader
            no_back
            title='Edropin Studio'
            subtitle='Your content grows dentists everyday!'
        />
        <UnstyledLink to='/curate'>
            <Button variant='contained'
                color='primary'>Curate Courses</Button>
        </UnstyledLink>
        {loading ? <LoadingDots /> : null}
        {!loading && items.length === 0 ? <EmptyListComponent
            message='No videos added yet' /> : null}
        {items.map(item => <VideoItem
            key={item.id}
            item={item}
        />)}
    </AppLayout>
}
