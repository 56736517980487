import { Box, Button, createStyles, makeStyles } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as yup from 'yup';
import { useAppDialog } from '../app-dialog';
import { ImageSize, LazyImage } from '../image-upload';
import PostIcon from '@material-ui/icons/Send'
import { LoadingButton } from '../ui/loading-button';
import { useUser } from '../auth/use-user';

type Props = {
	parent_name: string;
	insertMessage: (values: any) => Promise<boolean>;
	inserting?: boolean;
	onCancel?: () => void;
}

const validation = yup.object().shape({
	text: yup.string().required('Please type in a message'),
})

export const InsertReply = ({
	parent_name,
	insertMessage,
	inserting,
	onCancel,
}: Props) => {
	const classes = useStyles({});
	const { user } = useUser();
	const app_dialog = useAppDialog();

	const onSubmit = async (values: any, actions: FormikHelpers<any>) => {
		if (await insertMessage(values)) {
			actions.resetForm();
		} else {
			actions.setSubmitting(false);
		}
	}

	return <Formik
		onSubmit={onSubmit}
		validationSchema={validation}
		initialValues={{
			text: '',
		}}>{({ values }) => <Form className={classes.form}>
			<div className='message'>
				<LazyImage
					image_key={user.profile_image_key}
					image_opt={{ size: ImageSize.medium }}
					placeholder='/images/avatar.jpg'
				/>
				<Field
					component={TextField}
					name='text'
					label={`Reply to ${parent_name}`}
					multiline
					fullWidth
					margin='dense'
				/>
			</div>
			<Box pt={1} pb={1} textAlign='right'>
				{!onCancel ? null : <Button onClick={onCancel}>
					Cancel
				</Button>}
				<LoadingButton
					loading={inserting}
					variant='contained'
					type='submit'
					color='secondary'
					endIcon={<PostIcon />}
				>Send Reply</LoadingButton>
			</Box>
		</Form>}
	</Formik>
}

const useStyles = makeStyles((theme) => createStyles({
	form: {
		padding: theme.spacing(1, 2),
		'& .message': {
			display: 'flex',
			alignItems: 'center',
			'& img': {
				width: 30,
				height: 30,
				marginRight: theme.spacing(1),
				borderRadius: '50%',
			}
		}
	},
}));