import React from 'react';
import { VideoModuleModel } from '../../../models/video-module.model';
import { BasicDialog, BasicDialogTitle } from '../../../ui/dialog';
import { AddVideoModuleContainer } from './video-module-add.container';
import { EditVideoModuleContainer } from './video-module-edit.container';

type Props = {
	onClose: () => void;
	video_id: number;
	action: 'add' | 'edit' | 'clone';
	video_module?: VideoModuleModel;
	onFinish: (model: VideoModuleModel, is_new?: boolean) => void;
}

export const VideoModuleEditorDialog = ({
	onClose,
	video_id,
	action,
	video_module,
	onFinish,
}: Props) => {
	return <BasicDialog
		open={true}
		onClose={onClose}
		id='edit-ticket'>
		<BasicDialogTitle title={action === 'edit'
			? 'Edit module'
			: 'Create module'}
			onClose={onClose} />
		{action === 'edit' && video_module
			? <EditVideoModuleContainer
				module={video_module}
				onFinish={onFinish}
			/>
			: <AddVideoModuleContainer
				video_id={video_id}
				onFinish={onFinish}
			/>}
	</BasicDialog>
}