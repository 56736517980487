import { Box, Button, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { useList } from "../../../apollo/networking";
import { VideoResourceModel, VideoResourceFragment } from "../../../models/video-resource.model";
import { VideoModel } from "../../../models/video.model";
import { LoadingDots } from "../../../ui/loading-dots";
import { Section } from "../../../ui/section";
import { VideoResourceEditorDialog } from "./video-resource-editor";
import { VideoResourceItem } from "./video-resource-item";


type Props = {
    video: VideoModel;
}

export const VideoResource = ({
    video,
}: Props) => {
    const classes = useStyles({});
    const [edit, setEdit] = React.useState<{
        action: 'edit' | 'add' | 'clone';
        model?: VideoResourceModel;
    } | undefined>();
    const {
        items,
        loading,
        refetch,
    } = useList<VideoResourceModel>({
        entity_name: 'video_resource',
        fragment: VideoResourceFragment,
        query_inner: `where: {
            _and: [
                {video_id: {_eq: $video_id}},
                {deleted_at: {_is_null: true}},
            ]
        }`,
        clause_outer: `$video_id: bigint!`,
        variables: {
            video_id: video.id,
        }
    });

    const onAdd = () => {
        setEdit({
            action: 'add',
        })
    }

    const onEdit = React.useCallback((item: VideoResourceModel) => () => {
        setEdit({
            action: 'edit',
            model: item,
        })
    }, [setEdit]);

    const onFinish = (model: VideoResourceModel, is_new?: boolean) => {
        if (is_new) {
            refetch();
        }
        setEdit(undefined);
    }

    return <Section
        title='Scientific Resources'
        subtitle='Add links, references, handouts and more supporting the content of the course'>
        {edit ? <VideoResourceEditorDialog
            action={edit.action}
            video_id={video.id}
            resource={edit.model}
            onClose={() => setEdit(undefined)}
            onFinish={onFinish}
        /> : null}
        <Button variant='contained'
            onClick={onAdd}
            color='secondary'>
            + Add Resource
        </Button>
        <Box>
            {loading ? <LoadingDots /> : null}
            {items.filter(i => !i.deleted_at)
                .map(item => <VideoResourceItem
                    key={item.id}
                    item={item}
                    onEdit={onEdit(item)} />)}
        </Box>
    </Section>
}

const useStyles = makeStyles(theme => createStyles({

}))