import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { VideoFragment, VideoModel } from '../../../models/video.model';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { getSubmissionData, VideoEditorInitialValues, VideoEditorValidationSchema } from './video-creator.validation';
import { VideoEditorFormComponent } from './video-editor-form.component';


const VIDEO_EDITOR_INSERT_MUTATION = gql`
	mutation insert_video($object: video_insert_input!) {
		insert_video_one(object: $object) {
			${VideoFragment}
		}
	}
`;

type Props = {
	video?: VideoModel;
	onFinish?: (video: VideoModel, is_new?: boolean) => void;
}

export const VideoAddContainer = ({
	video,
	onFinish,
	...props
}: Props) => {
	const app_dialog = useAppDialog();
	const [insertVideo, insert_result] = useMutation(
		VIDEO_EDITOR_INSERT_MUTATION
	);

	const onSubmit = async ({ video_profiles, ...v }: any) => {
		try {
			const { data } = await insertVideo({
				variables: {
					object: {
						...getSubmissionData(v),
						video_profiles: video_profiles && video_profiles.length > 0 ? {
							data: (video_profiles as { id: number; }[])
								.map(({ id }) => ({
									profile_id: id,
								})),
						} : undefined,
					}
				},
			});
			if (data?.insert_video_one) {
				app_dialog.showSnackbar('Video created');
				if (onFinish) {
					onFinish(data.insert_video_one, true);
				}
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}

	return <Formik
		initialValues={VideoEditorInitialValues(
			video,
		)}
		validationSchema={VideoEditorValidationSchema}
		onSubmit={onSubmit}>{form_props => <Form noValidate>
			<VideoEditorFormComponent
				{...props}
				{...form_props}
			/>
			<BasicDialogActions {...form_props}>
				<LoadingButton
					type='submit'
					variant='contained'
					color='primary'
					loading={insert_result.loading}
					size='large'>Create Video</LoadingButton>
			</BasicDialogActions>
		</Form>}</Formik>
}