import { gql, useMutation } from "@apollo/client";
import { useUser } from "../../../auth/use-user";

const MutationVideoModuleStream = gql`
    mutation track_progress(
        $object: track_progress_input!
    ) {
        track_progress(object: $object) {
            success
            total_credit
            percent_complete
            progress_tracker
        }
    }
`;

type Props = {
    video_module_id: number;
    setProgressTracker: (v: string) => void;
	setPercentComplete: (v: number) => void;
	setTotalCredit: (v: number) => void;
}

export const useUpdateVideoModuleStream = ({
    video_module_id,
    setPercentComplete,
    setProgressTracker,
    setTotalCredit,
}: Props) => {
    const [update, update_status] = useMutation(MutationVideoModuleStream);

    const onTrackProgress = async (
        playback_position: number,
    ) => {
        if (update_status.loading) {
            return;
        }

        try {
            const { data } =  await update({
                variables: {
                    object: {
                        video_module_id,
                        playback_position,
                    },
                }
            })
            const track_progress = data?.track_progress as {
                success?: boolean;
                total_credit: number;
                percent_complete: number;
                progress_tracker: string;
            } | undefined;
            if (!track_progress || !track_progress.success) {
                return;
            }
            setPercentComplete(track_progress.percent_complete);
            setProgressTracker(track_progress.progress_tracker);
            setTotalCredit(track_progress.total_credit);
        } catch (e) {
            console.error(e);
        }
    }
    return {
        onTrackProgress
    }
}