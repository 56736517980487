import { Router } from "@reach/router";
import React from "react";
import { NotFoundPage } from "../pages/404";
import ProfilesPage from "../pages/profiles";
import SignoutPage from "../pages/signout";
import VideoPage from "../pages/video";
import VideoAnalyticsPage from "../pages/video-analytics";
import HomePage from "../pages/video-list";
import { LoadingDots } from "../ui/loading-dots";
import { ManageScroll } from "./manage-scroll";
import VideoPlayPage from '../pages/video-play';
import CurateYtPage from "../pages/curate-yt";
import CuratePage from "../pages/curate";

export const AppRouter = () =>
	<React.Suspense fallback={<LoadingDots />}>
		<Router>
			<SignoutPage path='/signout' />
			<HomePage path='/' />
			<CurateYtPage path='/curate' />
			<CuratePage path='/curate/:video_id' />
			<VideoPage path='/v/:video_id' />
			<VideoPlayPage path='/v/:video_id/play' />
			<VideoAnalyticsPage path='/v/:video_id/analytics' />
			<ProfilesPage path='/profiles' />
			<NotFoundPage default />
		</Router>
		<ManageScroll />
	</React.Suspense>
	