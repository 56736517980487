import React from "react";
import { useSubscribeByPk } from "../../apollo/networking/use-subscribe-by-pk";
import { useUser } from "../../auth/use-user";
import { VideoDetailModel } from "../../models/video-detail.model";
import { VideoUserFragment, VideoUserModel } from "../../models/video-user.model";
import { ErrorComponent } from "../../ui/errors";
import { LoadingDots } from "../../ui/loading-dots";
import { VideoPlay } from "./video-play";


type Props = {
    video: VideoDetailModel
}

export const VideoUser = ({
    video,
}: Props) => {
    const { user_id } = useUser();
    const {
        entity: video_user,
        error,
        loading,
    } = useSubscribeByPk<VideoUserModel>({
        entity_name: 'video_user',
        fragment: VideoUserFragment,
        pk_columns: [{
            value: video.id,
            name: 'video_id',
            type: 'bigint',
        }, {
            value: user_id,
            name: 'user_id',
            type: 'String',
        }]
    });

    if (loading) {
        return <LoadingDots />
    }

    if (!!error || !video_user) {
        return <ErrorComponent
            error={error}
            title='Failed to load user' />
    }

    return <VideoPlay
        video_user={video_user}
        video={video}
    />
}
