import * as yup from 'yup';
import { VideoResourceModel } from '../../../../models/video-resource.model';

export const VideoResourceInitialValues = (
	resource?: VideoResourceModel,
) => ({
	name: resource?.name || '',
	description: resource?.description || '',
	link: resource?.link || '',
});

export const EditVideoResourceValidationSchema = yup.object().shape({
	name: yup.string().required('Please name this resource'),
})

export const sanitizeVideoResourceData = ({
	...values
}: any) => {
	return {
		...values,
	};
}

