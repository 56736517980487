import { useMutation } from '@apollo/client';
import { DialogContent } from '@material-ui/core';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../../app-dialog';
import { VideoQuestionFragment, VideoQuestionModel } from '../../../../models/video-question.model';
import { BasicDialogActions } from '../../../../ui/dialog';
import { LoadingButton } from '../../../../ui/loading-button';
import { QuestionForm } from './question-form';
import { QuestionInitialValues, EditQuestionValidationSchema } from './question.validation';

type Props = {
	video_id: number;
	onFinish: (model: VideoQuestionModel, is_new?: boolean) => void;
}

const CREATE_VIDEO_MODULE_MUTATION = gql`
	mutation create_question($object: video_question_insert_input!) {
		insert_video_question_one(
			object: $object
		) {
			${VideoQuestionFragment}
		}
	}
`;

export const QuestionAdd = ({
	video_id,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [createModule, create_result] = useMutation(CREATE_VIDEO_MODULE_MUTATION)
	const onSubmit = async (values: any) => {
		const has_right_answer = (values.options || []).findIndex((o: any) => o.is_correct) > -1;
		if (!has_right_answer) {
			app_dialog.showDialog({
				title: 'Select a right answer',
				message: 'Looks like you have not selected a correct answer. Checkbox at least one correct option.',
				buttons: [],
			});
			return;
		}
		try {
			const { data } = await createModule({
				variables: {
					object: {
						...values,
						video_id,
					},
				},
			});
			if (data && !!data.insert_video_question_one) {
				app_dialog.showSnackbar('Question added');
				onFinish(data.insert_video_question_one, true);
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}
	return <Formik
		initialValues={QuestionInitialValues(undefined)}
		validationSchema={EditQuestionValidationSchema}
		onSubmit={onSubmit}>{props => <Form noValidate>
			<DialogContent>
				<QuestionForm {...props} />
			</DialogContent>
			<BasicDialogActions>
				<LoadingButton
					loading={create_result.loading}
					type='submit'
					variant='contained'
					color='secondary'
				>Create Question</LoadingButton>
			</BasicDialogActions>
		</Form >}
	</Formik >
}