import { createStyles, makeStyles, Typography } from '@material-ui/core';
import ComputerIcon from '@material-ui/icons/Gavel';
import React from 'react';
import { Section } from '../../../ui/section';

type Props = {
}

export const VideoCOI = ({ }: Props) => {
	const classes = useStyles({});
	return <Section
		id='conflict-of-interest'
		title={<>Conflict of Interest</>}
		icon={<ComputerIcon />}
	>
		<Typography gutterBottom variant='body1'>All programs offered by edropin will be for scientific and educational purposes only and will not promote
		the products of the company directly.</Typography><Typography gutterBottom variant='body1'>
			edropin is responsible for the control of content and the selection of presenters and moderators.
			edropin may work with or accept financial or in-kind support from other organizations but those
			organizations must first agree in writing that they will not direct the content of the program.
		</Typography>
		<Typography gutterBottom variant='body1'>

			Outside organizations or their agents will respond only to requests initiated by edropin for suggestions of presenters or sources of possible presenters.
			edropin will record the role of the company or its agents and ensure that all names/roles are disclosed to participants prior to attending a program.
		</Typography>
		<Typography variant='body1'> edropin is responsible for the control of content and the selection of presenters and moderators.
			The sponsors agrees not to direct the content of the program. The sponsors will respond only to edropin initiated requests for suggestions of presenters
			or sources of possible presenters. The sponsors will suggest more than one name (if possible);
			will provide speaker qualifications; will disclose financial or other relationships between the
			sponsors and speaker; and will provide this information in writing. edropin will record the role of the COMPANY OR ITS AGENTS.
		</Typography>

	</Section >
}

const useStyles = makeStyles((theme) => createStyles({

}));