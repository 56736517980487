import { Button, createStyles, IconButton, makeStyles, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import MoreIcon from '@material-ui/icons/MoreVert';
import { RouteComponentProps } from '@reach/router';
import React from 'react';
import { useSubscribeByPk } from '../../apollo/networking/use-subscribe-by-pk';
import { useUpdateByPk } from '../../apollo/networking/use-update-by-pk';
import { ImageSize, LazyImage } from '../../image-upload';
import { AppLayout } from '../../layout/app-layout';
import { VideoFragment, VideoModel } from '../../models/video.model';
import { EdContainer } from '../../ui/ed-container';
import { ErrorComponent } from '../../ui/errors';
import { LoadingDots } from '../../ui/loading-dots';
import { VideoEditorDialog } from '../video-list/video-editor';
import { VideoAbout } from './video-about';
import { VideoBar } from './video-bar';
import { VideoCertificates } from './video-certificates';
import { VideoModules } from './video-modules';
import VideoQuestions from './video-questions';
import { VideoResource } from './video-resource';


type Props = {
    video_id: string;
}


export const VideoPage = ({
    video_id,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const [show_edit, setShowEdit] = React.useState(false);
    const {
        entity,
        loading,
        error,
    } = useSubscribeByPk<VideoModel>({
        entity_name: 'video',
        pk_columns: [{
            name: 'id',
            value: video_id,
            type: 'bigint'
        }],
        fragment: VideoFragment,
    });
    const {
        onUpdate,
        loading: updating,
    } = useUpdateByPk({
        entity_name: 'video',
        fragment: VideoFragment,
        pk_columns: [{
            name: 'id',
            value: video_id,
            type: 'bigint',
        }],
    })

    const onEdit = () => {
        setShowEdit(true);
    }
    return <AppLayout no_container>
        {entity?.cover_image_key ? <LazyImage
            // placeholder={'https://via.placeholder.com/1200x600'}
            image_opt={{
                size: ImageSize.large,
                aspect_ratio: 2,
            }}
            image_key={entity.cover_image_key}
            className={classes.image}
        /> : null}
        <EdContainer>
            <Button size='small' onClick={() => window.history.back()}
                startIcon={<BackIcon />}>
                Back
        </Button>
            {loading ? <LoadingDots /> : null}
            {error ? <ErrorComponent error={error}
                title='Failed to load video' /> : null}
            {entity ? <>
                <div className={classes.header}>
                    <div style={{ flex: 1 }}>
                        <Typography variant='h3'>
                            <strong>{entity.name}</strong>
                        </Typography>
                        <Typography variant='body1' color='textSecondary'>
                            {entity.track.name}
                        </Typography>
                    </div>
                    <IconButton>
                        <MoreIcon />
                    </IconButton>
                </div>

                {show_edit ? <VideoEditorDialog
                    onClose={() => setShowEdit(false)}
                    onFinish={() => setShowEdit(false)}
                    video={entity}
                    action='edit'
                /> : null}
                <VideoBar
                    video={entity}
                    onEdit={onEdit}
                    onUpdate={onUpdate}
                    updating={updating}
                />
                <VideoAbout
                    description={entity.description}
                    onEdit={onEdit}
                    video_profiles={entity.video_profiles}
                />
                <VideoModules
                    onUpdate={onUpdate}
                    in_review_at={entity.in_review_at}
                    video_id={entity.id}
                    module_order={entity.module_order}
                    modules={entity.video_modules}
                />
                <VideoResource
                    video={entity} />
                <VideoQuestions 
                    video_id={entity.id}
                />
                <VideoCertificates video={entity} />
            </> : null}
        </EdContainer>
    </AppLayout >
}

const useStyles = makeStyles(theme => createStyles({
    image: {
        width: '100%',
        height: 200,
        objectFit: 'cover',
        objectPosition: 'center',
        display: 'block',
    },
    header: {
        // paddingBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-start'
    }
}));