import { Typography } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import React from "react"
import { AppLayout } from "../../layout/app-layout"
import { PageHeader } from "../../ui/page-header"

type Props = {

}

export const VideoAnalyticsPage = (props: RouteComponentProps<Props>) => {
    return <AppLayout>
        <PageHeader title='Analytics' />
        <Typography variant='h6' align='center'>👷 Coming soon</Typography>
    </AppLayout>
}
