import { Button, createStyles, Link, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useUpdateByPk } from "../../../apollo/networking/use-update-by-pk";
import { VideoResourceFragment, VideoResourceModel } from "../../../models/video-resource.model";
import { Markdown } from "../../../ui/markdown";


type Props = {
    item: VideoResourceModel;
    onEdit: () => void;
}

export const VideoResourceItem = ({
    item,
    onEdit
}: Props) => {
    const classes = useStyles({});
    const {
        onUpdate,
        loading,
    } = useUpdateByPk({
        entity_name: 'video_resource',
        fragment: VideoResourceFragment,
        pk_columns: [{
            name: 'id',
            type: 'bigint',
            value: item.id,
        }],
    })

    const onDelete = () => {
        onUpdate({
            deleted_at: 'now()',
        })
    }

    return <div className={classes.item}>
        <div className={classes.content}>
            <Typography variant='overline'>{moment(item.created_at).calendar()}</Typography>
            {item.link ? <Link href={item.link} target='_blank'>
                <Typography variant='h6'>{item.name} <span role='img'>🔗</span></Typography>
            </Link> : <Typography variant='h6'>{item.name}</Typography>}
            <Markdown text={item.description} />
        </div>
        <div>
            <Button onClick={onEdit}>Edit</Button>
            <Button onClick={onDelete}
                variant='contained' color='primary'>Delete</Button>
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }
    },
    content: {
        flex: 1,
    }
}))