import { Button, createStyles, makeStyles, Tooltip } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Help';
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { useGetByPk } from "../../../apollo/networking/use-get-by-pk";
import { useUser } from "../../../auth/use-user";
import { environment } from "../../../environment";
import { VideoLicensureFragment, VideoLicensureModel } from "../../../models/video-licensure.model";
import { LoadingDots } from "../../../ui/loading-dots";

type Props = {
    video_id: number;
    module_order?: number[];
}

export const VideoLicensure = ({
    video_id,
    module_order,
}: Props) => {
    const classes = useStyles({});
    const [show_qualification, setShowQualification] = React.useState(false);
    const {
        user: {
            state_id,
        }
    } = useUser();

    const {
        loading,
        entity,
    } = useGetByPk<VideoLicensureModel>({
        entity_name: 'video_licensure',
        fragment: VideoLicensureFragment,
        pk_columns: [{
            name: 'state_id',
            type: 'bigint',
            value: state_id,
        }, {
            name: 'video_id',
            type: 'bigint',
            value: video_id,
        }]
    });


    if (loading) {
        return <LoadingDots />
    }

    if (!entity) {
        return <Alert severity='info' className={classes.container}>
            <AlertTitle>Ready to export a certificate?</AlertTitle>
        All Edropin videos earn your fractional CE (MicroCredits). You can export a certificate for this video anytime for free <Tooltip title='Click export button to get your certificate.'><InfoIcon
                fontSize='small' /></Tooltip>
            <div className={classes.button}>
                <Button color='primary'
                    target='_blank'
                    href={`${environment.app_frontend_url}/credits`}
                    variant='contained'>Export Certificate</Button>
            </div>
        </Alert>
    }

    return <>
        <Alert severity='info' className={classes.container}>
            <AlertTitle>Licensure Information</AlertTitle>
            This video qualifies for <strong>{entity.name}</strong> ({entity.total_ce} CE) in <strong>{entity.state.name}</strong>.
            You can export a certificate once you have completed 95% of all modules <Tooltip title='Click export button to get your certificate.'><InfoIcon
                fontSize='small' /></Tooltip>
            <div className={classes.button}>
                <Button color='primary'
                    href={`${environment.app_frontend_url}/credits`}
                    variant='contained'>Export Certificate</Button>
            </div>
        </Alert>
    </>
}

const useStyles = makeStyles(theme => createStyles({
    container: {
        marginTop: theme.spacing(2),
    },
    button: {
        paddingTop: theme.spacing(2),
    }
}))