import { VideoProfileModel } from "../../../models/video-profile.model";

export const processVideoProfiles = (
    video_id: number,
    new_profiles: {id: number}[],
    old_profiles: VideoProfileModel[],
) => {
    const profiles: {
        video_id: number;
        profile_id: number;
        deleted_at: null | string;
    }[] = (new_profiles || []).map((profile: any) => ({
        video_id: video_id,
        profile_id: profile.id,
        deleted_at: null,
    }));

    const new_tags_map = profiles.reduce((obj, profile) => ({
        ...obj,
        [profile.profile_id]: true,
    }), {} as { [id: number]: boolean })

    const deleted_tags = (old_profiles || [])
        .filter(profile => !new_tags_map[profile.profile_id])
        .map(t => ({
            video_id: video_id,
            profile_id: t.profile_id,
            deleted_at: 'now()',
        }))

    return [...profiles, ...deleted_tags];
}