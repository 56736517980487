export enum VideoPublishStatus {
	draft=0,
	live=1,
	pending_approval=2,
}

export const VideoPublishStatusOptions = [
	{
		id: VideoPublishStatus.draft,
		label: 'In Draft',
	},
	{
		id: VideoPublishStatus.live,
		label: 'Live',
	},
	{
		id: VideoPublishStatus.pending_approval,
		label: 'Pending Approval',
	},
];

export const getEventStatusString = (status: VideoPublishStatus) => {
	return VideoPublishStatusOptions.find(o => o.id === status)?.label;
}