import { createStyles, DialogContent, makeStyles } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import { useAppDialog } from "../../../app-dialog";
import { VideoEvaluationModel } from "../../../models/video-evaluation.model";
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { StarRatingField } from "../../../ui/rating";
import { Section } from "../../../ui/section";
import { VideoEvaluationValidation, VideoEvaluationValues } from "./video-evaluation.validation";


type Props = {
    video_evaluation?: VideoEvaluationModel;
    loading?: boolean;
    onClose: () => void;
    onSave: (v: any) => Promise<boolean>;
}

export const VideoEvaluation = ({
    video_evaluation,
    loading,
    onClose,
    onSave,
}: Props) => {
    const classes = useStyles({}); 
    const app_dialog = useAppDialog();

    const onSubmit = async (v: any) => {
        if (await onSave({
            ...v,
            reviewed_at: new Date().toISOString(),
        })) {
            onClose();
            app_dialog.showSnackbar('Thank you, review submitted');
        }
    }

    return <Formik
        initialValues={VideoEvaluationValues(video_evaluation)}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={VideoEvaluationValidation}
    >{(form_props) => <BasicDialog
        open={true}
        onClose={onClose}
        id='video-review'>
        <Form className={classes.fullWidth}>
            <BasicDialogTitle title='Rate & Review' onClose={onClose} />
            <DialogContent>
                <Section title='Overall'>
                    <Field
                        component={StarRatingField}
                        name='overall_course'
                        label='Overall, I would rate this course:'
                    />
                    <Field
                        component={StarRatingField}
                        name='overall_instructor'
                        label='Overall, I would rate this instructor[s]:'
                    />
                    <Field
                        component={StarRatingField}
                        name='overall_expectations'
                        label='Overall, this course met my expectations:'
                    />
                    <Field
                        name='comments'
                        component={TextField}
                        label='Comments (positive or negative):'
                        multiline
                        margin='normal'
                        fullWidth
                    />
                </Section>
                <Section title='Experience (Optional)'>
                    <Field
                        component={StarRatingField}
                        name='data.audio_visual'
                        label='Audio/Visual content was relevant and of high quality:'
                    />
                    <Field
                        component={StarRatingField}
                        name='data.user_interface'
                        label='The user interface and navigation was easy:'
                    />
                </Section>
                <Section title='Course/Instructor (Optional)'>
                    <Field
                        component={StarRatingField}
                        name='data.administration'
                        label='Course administration was efficient and friendly:'
                    />
                    <Field
                        component={StarRatingField}
                        name='data.objective'
                        label='Course objectives were consisted with the course as advertised:'
                    />
                    <Field
                        component={StarRatingField}
                        name='data.materials'
                        label='Course material was up-to-date, well organized, and presented in sufficient depth:'
                    />
                    <Field
                        component={StarRatingField}
                        name='data.instructor_knowledge'
                        label='Instructor demonstrated a comprehensive knowledge of the subject:'
                    />
                    <Field
                        component={StarRatingField}
                        name='data.instructor_interest'
                        label='Instructor appeared to be interested and enthusiastic about the subject:'
                    />
                    <Field
                        component={StarRatingField}
                        name='data.handout_materials'
                        label='Handout/Quiz materials enhanced course content:'
                    />
                    <Field
                        name='data.other_topics'
                        component={TextField}
                        label='Other topics and/or speakers you would like to have offered:'
                        multiline
                        margin='normal'
                        fullWidth
                    />
                </Section>
            </DialogContent>
            <BasicDialogActions {...form_props}>
                <LoadingButton
                    variant='contained'
                    color='primary'
                    size='large'
                    loading={loading}
                    type='submit'>Submit Review</LoadingButton>
            </BasicDialogActions>
        </Form>
    </BasicDialog>}
    </Formik>
}


const useStyles = makeStyles(theme => createStyles({
    fullWidth: {
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    }
}))