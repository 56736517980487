import { useMutation } from '@apollo/client';
import { DialogContent } from '@material-ui/core';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { VideoModuleModel, VideoModuleFragment } from '../../../models/video-module.model';
import { DevFormValues } from '../../../ui/dev-form-values';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { EditVideoModuleValidationSchema, sanitizeVideoModuleData, VideoModuleInitialValues } from './video-module-edit-validation';
import { VideoModuleFormComponent } from './video-module-form.component';

type Props = {
	module: VideoModuleModel;
	onFinish: (model: VideoModuleModel, is_new?: boolean) => void;
}

const EDIT_VIDEO_MODULE_MUTATION = gql`
	mutation update_ticket(
		$module_id: bigint!, 
		$set: video_module_set_input!) {
		update_video_module_by_pk(
			pk_columns: {id: $module_id},
			_set: $set,
		) {
			${VideoModuleFragment}
		}
	}
`;

export const EditVideoModuleContainer = ({
	module,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [updateModule, update_result] = useMutation(EDIT_VIDEO_MODULE_MUTATION);
	const onSubmit = async (values: any) => {
		try {
			const { data } = await updateModule({
				variables: {
					module_id: module.id,
					set: sanitizeVideoModuleData(values),
				},
			});
			if (data && data.update_video_module_by_pk) {
				app_dialog.showSnackbar('Module saved');
				onFinish(data.update_video_module_by_pk, false);
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}
	return <Formik
		initialValues={VideoModuleInitialValues(module)}
		validationSchema={EditVideoModuleValidationSchema}
		onSubmit={onSubmit}>{props => <Form noValidate>
			<DialogContent>
				<VideoModuleFormComponent {...props} />
				<DevFormValues {...props} />
			</DialogContent>
			<BasicDialogActions>
				<LoadingButton
					loading={update_result.loading}
					type='submit'
					variant='contained'
					color='secondary'
				>Save</LoadingButton>
			</BasicDialogActions>
		</Form >}
	</Formik >
}