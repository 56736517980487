import { Button, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { usePlayer } from "./player-context";


type Props = {

}

export const VideoModuleCredit = (props: Props) => {
    const classes = useStyles({});
    const {
        total_credit,
    } = usePlayer();

    const _total_credit = Math.round((total_credit || 0) * 100) / 100;

    return <div className={classes.credit}>
        <Button size='small' href='/credits' target='_blank' variant='contained' color='primary'>
            {_total_credit || '--'}
        </Button>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    credit: {
        '& a': {
            opacity: .8,
            color: 'white',
            fontWeight: theme.typography.fontWeightBold,
            background: theme.palette.success.main,
            '&:hover': {
                background: theme.palette.success.dark,
                opacity: 1,
            }
        }
    }
}))