
export type TrackModel = {
	id: string;
	name: string;
	track_video_tally?: {
		total_videos: number;
	}
}

export const TrackFragment = `
	id
	name
	track_video_tally {
		total_videos
	}
`;