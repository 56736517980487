import { ChatMessageReaction } from "../chat-message-reaction.model";

export type PostModel = {
	id: number;
	created_at: string;
	text: string;
	user_id: string;
	deleted_at?: string;
	chat_id: number;
	parent_message_id?: number;
	user_profile: {
		id: string;
		profile_image_key?: string;
		name: string;
	}
	chat: {
		id: number;
	};
	chat_message_reactions: {
		id: number;
		status: ChatMessageReaction;
	}[];
	chat_message_reactions_tally?: {
		id: number;
		total_reactions?: number;
	};
	reply_tally?: {
		total_replies?: number;
	}
}

export const PostFragment = `
	id
	created_at
	text
	user_id
	deleted_at
	chat_id
	parent_message_id
	chat {
		id
	}
	user_profile {
		id
		profile_image_key
		name
	}
	chat_message_reactions {
		id
		status
	}
	chat_message_reactions_tally {
		id
		total_reactions
	}
`;

export const PostFragmentWithTotalReplies = `
	${PostFragment}
	reply_tally {
		id
		total_replies
	}
`;