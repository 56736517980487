import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { Link } from "@reach/router";
import moment from "moment";
import React from "react";
import { ImageSize, LazyImage } from "../../image-upload";
import { VideoModel } from "../../models/video.model";
import { TruncateText } from "../../ui/truncate-text";
import { UnstyledLink } from "../../ui/unstyled-link";
import { getDurationText } from "../../utils/duration";


type Props = {
    item: VideoModel;
}

export const VideoItem = ({
    item,
}: Props) => {
    const classes = useStyles({});

    return <div className={classes.item}>
        <LazyImage
            image_key={item.cover_image_key}
            image_opt={{ size: ImageSize.large, aspect_ratio: 'none' }}
            className={'image'}
        />
        <div className='content'>
            <Typography variant='overline'>{moment(item.premieres_at).format('LLL')}</Typography>
            <UnstyledLink enable_text_decoration to={`/v/${item.id}`}>
                <Typography variant='body1' color='primary'>{item.name}</Typography>
            </UnstyledLink>
            <TruncateText
                text={item.description}
                max_lines={2} />
            <Typography
                variant='body2' color='textSecondary'>{getDurationText(item.video_module_summary?.total_duration || 0, true)}</Typography>
        </div>
        <div className='stats'>
            <Typography>{item.video_user_tally?.total_users || 0} Views</Typography>
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        padding: theme.spacing(2, 0),
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        '&:last-child': {
            borderBottom: 0,
        },
        '& .image': {
            width: 150,
            objectFit: 'contain',
        },
        '& .content': {
            padding: theme.spacing(2),
            flex: 1,
            '& .LinesEllipsis': {
                color: theme.palette.text.secondary,
            }
        },
        '& .stats': {
            padding: theme.spacing(2),
        }
    }
}))