import { useMutation } from '@apollo/client';
import { useNavigate } from '@reach/router';
import { Form, Formik, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { ProfileFragment, ProfileModel } from '../../../models/profile.model';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { getSubmissionData, ProfileEditorInitialValues, ProfileEditorValidationSchema } from './profile-creator.validation';
import { ProfileEditorFormComponent } from './profile-editor-form.component';


const PROFILE_EDITOR_UPDATE_MUTATION = gql`
	mutation update_profile_by_pk($profile_id: bigint!, $set: profile_set_input!) {
		update_profile_by_pk(
			pk_columns: {id: $profile_id},
			_set: $set,
		) {
			${ProfileFragment}
		}
	}
`;

type Props = {
	profile: ProfileModel;
	onFinish?: (profile: ProfileModel, is_new?: boolean) => void;
}

export const ProfileEditContainer = ({
	profile,
	onFinish,
}: Props) => {
	const navigate = useNavigate();
	const app_dialog = useAppDialog();
	const [updateProfile, update_result] = useMutation(PROFILE_EDITOR_UPDATE_MUTATION);

	const onSubmit = async ({...v }: any, action: FormikHelpers<any>) => {
		action.setSubmitting(false);
		try {
			
			const { data } = await updateProfile({
				variables: {
					profile_id: profile.id,
					set: getSubmissionData(v),
				},
			});
			if (data?.update_profile_by_pk) {
				app_dialog.showSnackbar('Profile saved');
				if (onFinish) {
					onFinish(data?.update_profile_by_pk);
				}
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}

	return <>
		<Formik
			initialValues={ProfileEditorInitialValues(profile)}
			validationSchema={ProfileEditorValidationSchema}
			onSubmit={onSubmit}>{props => <Form noValidate>
				{/* <FormikAutoSave /> */}
				<ProfileEditorFormComponent
					{...props}
				/>
				<BasicDialogActions {...props}>
					<LoadingButton
						type='submit'
						variant='contained'
						color='primary'
						loading={update_result.loading}
						size='large'>Save Profile</LoadingButton>
				</BasicDialogActions>
			</Form>}</Formik>
	</>
}