import { createStyles, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { EmptyListComponent } from "../../../ui/empty-list.component";
import EmptyIcon from '@material-ui/icons/Notes';
import { getDurationText } from "../../../utils/duration";
import { usePlayer } from "../video-player/player-context";

type Props = {
    transcript?: string;
}

export const VideoTranscript = ({
    transcript
}: Props) => {
    const classes = useStyles({});
    const {
        seekTo,
    } = usePlayer();

    const _transcript = React.useMemo(() => {
        if (!transcript) {
            return '';
        }
        return transcript
            .split('\n')
            .map((line, index) => {
                const timestamp = parseFloat(line.substr(0, 10));
                return <div key={index}
                    onClick={() => seekTo(timestamp - 5)} className={classes.clickable}>
                    <Typography variant='body2'>{getDurationText(timestamp, true)}</Typography>
                    <Typography className='link' variant='body1'>
                        {line.substr(11)}
                    </Typography>
                </div>
            })

    }, [transcript]);

    if (!transcript) {
        return <EmptyListComponent
            icon={<EmptyIcon />}
            message='No transcript available for this video'
        />
    }

    return <>
        {_transcript}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    clickable: {
        cursor: 'pointer',
        padding: theme.spacing(1),
        '& .link': {
            color: theme.palette.primary.main,
        },
        '&:hover': {
            background: theme.palette.grey[300],
        }
    }
}))