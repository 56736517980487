import React from 'react';
import { useDropzone } from 'react-dropzone';
import { LoadingDots } from '../ui/loading-dots';

const baseStyle = {
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: 'grey',
  borderStyle: 'dashed',
  // backgroundColor: '#fafafa',
  // color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const roundedStyle = {
  borderRadius: '50%',
}

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

interface Props {
  rounded?: boolean;
  width?: number;
  height?: number;
  loading?: boolean;
  children?: React.ReactNode;
  multiple?: boolean;
  onChange: (files: File[]) => void;
  accept?: string;
}

export const FormImageDropzone = ({
  children,
  accept,
  ...props }: Props) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (files: File[]) => props.onChange(files),
    accept: accept || '*/*',
    multiple: props.multiple,
  });

  const style: any = React.useMemo(() => ({
    ...baseStyle,
    ...(props.width || props.height ? {
      width: props.width,
      height: props.height,
    } : {}),
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
    ...(props.rounded ? roundedStyle : {}),
  }),
    // eslint-disable-next-line
    [isDragActive, isDragReject,]);

  function renderContent() {
    if (props.loading) {
      return <LoadingDots width={40} />
    }
    return children;
  }
  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {renderContent()}
    </div>
  );
}
