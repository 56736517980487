import { createStyles, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ImageSize, LazyImage } from "../image-upload";
import { ProfileModel } from "../models/profile.model";
import { Markdown } from "../ui/markdown";
import { ProfileSocial } from "./profile-social";

type Props = {
    profile: ProfileModel;
}

export const ProfileCard = ({
    profile: {
        profile_image_key,
        logo_image_key,
        name,
        bio,
        website,
        ...p
    }
}: Props) => {
    const classes = useStyles({});
    return <div className={classes.profile}>
        <LazyImage
            image_key={profile_image_key}
            className='avatar'
            placeholder={'/avatar.jpg'}
            image_opt={{ size: ImageSize.large }}
        />
        <Typography variant='h6'>{name}</Typography>
        {logo_image_key ? <LazyImage
            image_key={logo_image_key}
            className='logo'
            image_opt={{ size: ImageSize.large, aspect_ratio: 'none' }}
        /> : null}
        {website ? <Link target='_blank' href={`http://${website}`}><Typography variant='h6'>{website}</Typography></Link> : null}
        <Markdown text={bio} />
        <div className={classes.social}>
            <ProfileSocial {...p} />
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    profile: {
        padding: theme.spacing(2),
        textAlign: 'center',
        '& .avatar': {
            width: '50%',
            borderRadius: '50%',
            maxWidth: 180,
            margin: '0 auto',
            marginBottom: theme.spacing(3),
            border: `2px solid ${theme.palette.divider}`,
            boxShadow: theme.shadows[4],
        },
        '& .logo': {
            width: '100%',
            maxWidth: 200,
            margin: '0 auto',
            border: `5px solid white`,
            borderRadius: theme.shape.borderRadius,
        }
    },
    social: {
        paddingTop: theme.spacing(2),
        width: 'fit-content',
        margin: '0 auto',
    }
}))