import { OutlinedTextFieldProps, TextField } from "@material-ui/core";
import { ErrorMessage, FieldProps } from "formik";
import { setuid } from "process";
import React from "react";
import { getValueAtPath } from "../utils/get-value-at-path";
import { useDebounceValue } from "../utils/use-debounce-value";


interface Props extends FieldProps, OutlinedTextFieldProps {
    renderPlayer?: (props: {
        url: string;
    }) => React.ReactNode;
}

export const YoutubeVideoInputField = ({
    renderPlayer,
    field: {
        name,
        value,
    },
    form: {
        setFieldValue,
        errors,
        touched,
        setFieldError
    },
    ...props
}: Props) => {
    const [url, setUrl] = React.useState(value || '');
    const debounce_url = useDebounceValue(url);

    React.useEffect(() => {
        if (debounce_url !== undefined || debounce_url !== '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = debounce_url.match(regExp);
            if (match && match[2].length == 11) {
                setFieldValue(name, `https://www.youtube.com/embed/${match[2]}`);
            } else {
                setFieldValue(name, '');
                setFieldError(name, 'Invalid youtube url');
            }
        }
    }, [debounce_url]);

    const hasError = Boolean(getValueAtPath(errors, name))
        && Boolean(getValueAtPath(touched, name));
    return <>
        <TextField
            {...props}
            onChange={e => setUrl(e.target.value)}
            value={url}
            error={hasError}
            helperText={hasError ? <ErrorMessage name={name} /> : props.helperText}
        />
        {value && renderPlayer ? renderPlayer({ url: value }) : null}
    </>
}
