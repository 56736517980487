import { useMutation } from '@apollo/client';
import { DialogContent } from '@material-ui/core';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../../app-dialog';
import { VideoResourceFragment, VideoResourceModel } from '../../../../models/video-resource.model';
import { BasicDialogActions } from '../../../../ui/dialog';
import { LoadingButton } from '../../../../ui/loading-button';
import { EditVideoResourceValidationSchema, sanitizeVideoResourceData, VideoResourceInitialValues } from './video-resource-edit-validation';
import { VideoResourceFormComponent } from './video-resource-form.component';

type Props = {
	video_id: number;
	onFinish: (model: VideoResourceModel, is_new?: boolean) => void;
}

const CREATE_RESOURCE_MUTATION = gql`
	mutation create_resource($object: video_resource_insert_input!) {
		insert_video_resource_one(
			object: $object,
		) {
			${VideoResourceFragment}
		}
	}
`;

export const AddVideoResourceContainer = ({
	video_id,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [createModule, create_result] = useMutation(CREATE_RESOURCE_MUTATION)
	const onSubmit = async (values: any) => {
		try {
			const { data } = await createModule({
				variables: {
					object: {
						...sanitizeVideoResourceData(values),
						video_id,
						deleted_at: null,
					},
				},
			});
			if (data && !!data.insert_video_resource_one) {
				app_dialog.showSnackbar('Resource added');
				onFinish(data.insert_video_resource_one, true);
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}
	return <Formik
		initialValues={VideoResourceInitialValues(undefined)}
		validationSchema={EditVideoResourceValidationSchema}
		onSubmit={onSubmit}>{props => <Form noValidate>
			<DialogContent>
				<VideoResourceFormComponent {...props} />
			</DialogContent>
			<BasicDialogActions>
				<LoadingButton
					loading={create_result.loading}
					type='submit'
					variant='contained'
					color='secondary'
				>Create Resource</LoadingButton>
			</BasicDialogActions>
		</Form >}
	</Formik >
}