import { Box, Typography } from "@material-ui/core";
import React from "react";
import { VideoModuleModel } from "../../../models/video-module.model";
import { EdSlider } from "../../../ui/ed-slider";
import { VideoModuleItem } from "./video-module-item";

type Props = {
    id: number;
    video_modules: VideoModuleModel[];
    selected_id?: number;
    current_module_idx: number|null;
}

export const VideoModules = ({
    id: video_id,
    video_modules,
    selected_id,
    current_module_idx,
}: Props) => {
    if (video_modules.length === 1) {
        return null;
    }

    return <Box my={2}>
        <Typography
            gutterBottom
        >This videos has <strong>{video_modules.length} modules</strong> <span role='img'>→</span></Typography>
        <EdSlider
            items={video_modules}
            no_infinite
            initial_item={current_module_idx || undefined}
            renderItem={(item) => <VideoModuleItem
                key={item.id}
                video_id={video_id}
                video_module={item}
                is_selected={item.id === selected_id}
            />}
        />
    </Box>
}
