import { createStyles, DialogContent, makeStyles } from "@material-ui/core";
import React from "react";
import { useGetByPk } from "../../apollo/networking/use-get-by-pk";
import { BasicDialog, BasicDialogTitle } from "../../ui/dialog";
import { ErrorComponent } from "../../ui/errors";
import { LoadingDots } from "../../ui/loading-dots";
import { Post } from "./post";
import { PostFragmentWithTotalReplies, PostModel } from "./post.model";


type Props = {
    post_id: number;
    onClose: () => void;
}

export const PostDialog = ({
    post_id,
    onClose,
}: Props) => {
    const {
        entity,
        loading,
        error,
        refetch,
    } = useGetByPk<PostModel>({
        entity_name: 'chat_message',
        fragment: PostFragmentWithTotalReplies,
        pk_columns: [{
            name: 'id',
            value: post_id,
            type: 'bigint',
        }]
    });

    return <BasicDialog
        open={true}
        id='post-dialog'
        onClose={onClose}>
        <BasicDialogTitle title='Post' onClose={onClose} />
        <DialogContent>
            {loading ? <LoadingDots /> : null}
            {JSON.stringify(error)}
            {!entity && !loading
                ? <ErrorComponent message='Post not found' />
                : null}
            {entity ? <Post post={entity}
                refetchPost={refetch} /> : null}
        </DialogContent>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({

}))