import * as yup from 'yup';
import { ProfileModel } from '../../../models/profile.model';

export const ProfileEditorInitialValues = (
	profile?: ProfileModel,
) => ({
	name: profile?.name || '',
	bio: profile?.bio || '',
	profile_image_key: profile?.profile_image_key || null,
	logo_image_key: profile?.logo_image_key || null,
	website: profile?.website || '',
	email: profile?.email || '',
	facebook: profile?.facebook || '',
	linkedin: profile?.linkedin || '',
	instagram: profile?.instagram || '',
	twitter: profile?.twitter || '',
	phone: profile?.phone || '',
});


export const ProfileEditorValidationSchema = yup.object().shape({
	name: yup.string().required('Mame is required'),
	bio: yup.string()
		.max(15000, 'Bio must be less than 15000 characters')
		.required('Bio is required'),
});

export const getSubmissionData = ({
	...values
}: any) => {
	return {
		...values,
	};
}