import { gql } from '@apollo/client';
import { Button, Chip, createStyles, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked';
import { FieldProps } from 'formik';
import React from 'react';
import { ImageSize, LazyImage } from '../../../image-upload';
import { SearchBar } from '../../../ui/search-bar.component';
import { useLazySearch } from '../../../utils/use-lazy-search';

interface Props extends FieldProps {
	no_add?: boolean;
}


const QUERY_SEARCH = gql`
    query profile($query: String) {
        profile(
            where: {name: {
                _ilike: $query
            }},
        ) {
            id
			name
			profile_image_key
        }
    }
`;


export const VideoRoleFieldComponent = ({
	no_add,
	field: {
		value,
		name,
	},
	form: {
		setFieldValue,
	}
}: Props) => {
	const classes = useStyles({});
	const selected = (value || []) as {
		id: number;
		name: string;
		profile_image_key: string;
	}[];
	const {
		query,
		onSearch,
		onClearSearch,
		is_searching,
		data,
		loading,
	} = useLazySearch({
		fragment: QUERY_SEARCH,
		variables: {},
		ilike: true,
	});

	const tags = is_searching ? (data?.profile || []) as {
		id: number;
		name: string;
		profile_image_key: string;
	}[] : selected;

	const isSelected = (tag: { id: number }) => selected
		.findIndex(t => t.id === tag.id) > -1;

	const onSelect = (tag: any) => {
		if (isSelected(tag)) {
			setFieldValue(name, selected.filter(s => s.id !== tag.id));
			return;
		}
		setFieldValue(name, [...selected, tag]);
	}

	return <>
		<SearchBar
			query={query}
			onClearSearch={onClearSearch}
			onSearch={onSearch}
			placeholder='Search for profiles...'
			ilike
			loading={loading} />
		<div className={classes.tags}>
			{tags.map(tag => {
				const is_selected = isSelected(tag);
				return <Chip
					label={<div className={classes.tag}>
						<LazyImage
							image_key={tag.profile_image_key}
							image_opt={{
								size: ImageSize.small,
							}} />
						{tag.name}
					</div>}
					key={tag.id}
					clickable
					onClick={() => onSelect(tag)}
					variant={is_selected ? 'default' : 'outlined'}
					icon={is_selected ? <CheckCircleIcon style={{
						color: 'yellow',
					}} /> : <CircleIcon />}
					className={classes.category}
				/>
			})}
			{no_add ? null : <div className={classes.add}>
				<Button href='/profiles' target='_blank' size='small' variant='outlined'
					fullWidth>Can't find someone? Create a profile +</Button>
			</div>}
		</div>
	</>
}

const useStyles = makeStyles((theme) => createStyles({
	tags: {
		padding: theme.spacing(2, 0),
	},
	tag: {
		display: 'flex',
		'& img': {
			width: 25,
			height: 25,
			borderRadius: '50%',
			marginRight: theme.spacing(1),
		}
	},
	category: {
		margin: theme.spacing(.5),
	},
	add: {
		paddingTop: theme.spacing(1),
	}
}));