import { Box, Button, createStyles, IconButton, makeStyles, Typography } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { Field, FieldArray, FormikProps } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MarkdownEditorField } from '../../../../ui/mardown-editor.field';

interface Props extends FormikProps<any> {
}

export const QuestionForm = ({
	values,
}: Props) => {
	const classes = useStyles({});
	return <div className={classes.root}>
		<Field
			component={MarkdownEditorField}
			label='Question prompt'
			name='prompt' />
		<Box pt={3}>
			<FieldArray name='options'>{(option_props) => <>
				<Typography variant='overline'>Options (Checkbox the right option)</Typography>
				{((values.options || []) as { label: string, is_correct?: boolean }[])
					.map((option, ydx) => <div key={ydx} className={classes.option}>
						<Field
							name={`options.${ydx}.is_correct`}
							type='checkbox'
							component={Checkbox}
						/>
						<Field
							component={TextField}
							name={`options.${ydx}.label`}
							type="text"
							label={`Option ${ydx + 1}`}
							multiline
							fullWidth
							margin='normal'
						/>
						<IconButton onClick={() => option_props.remove(ydx)}>
							<RemoveIcon color='primary' />
						</IconButton>
					</div>)}
				<Button onClick={() => option_props.push({
					id: uuidv4(),
					label: '',
					is_correct: false,
				})} fullWidth>+ Add Option</Button>
			</>}</FieldArray>
		</Box>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		paddingTop: theme.spacing(2),
	},
	question: {
		marginBottom: theme.spacing(2),
		background: theme.palette.background.default,
		padding: theme.spacing(4, 2, 2, 2),
		borderRadius: theme.shape.borderRadius,
		position: 'relative',
		'& .remove': {
			position: 'absolute',
			right: 4,
			top: 4,
		}
	},
	option: {
		display: 'flex',

	}
}));