import DateFnsUtils from '@date-io/moment';
import { Button, createStyles, DialogContent, Link, makeStyles, Typography } from '@material-ui/core';
import CameraIcon from '@material-ui/icons/CameraAlt';
import PeopleIcon from '@material-ui/icons/People';
import { Alert, AlertTitle } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { Image, ImageSize, ImageUpload, LazyImage } from '../../image-upload';
import { DevFormValues } from '../../ui/dev-form-values';
import { Section } from '../../ui/section';
import { TrackSelectorField } from '../video-list/track-selector';
import { Objectives } from '../video-list/video-editor/objectives-field';
import { VideoRoleFieldComponent } from '../video-list/video-editor/video-role-field.component';


interface Props extends FormikProps<any> {
	has_profile?: boolean;
	channel_title: string;
}


export const CurateForm = ({
	values,
	setFieldValue,
	has_profile,
	channel_title,
}: Props) => {
	const [image_upload, setImageUpload] = React.useState<'cover_image_key' | undefined>();
	const classes = useStyles({});


	const onChangeImage = (images: Image[]) => {
		if (images.length === 0 || !image_upload) {
			return;
		}
		setFieldValue(image_upload, images[0].key);
		setImageUpload(undefined);
	}

	return <>
		{image_upload ? <ImageUpload
			onSelect={onChangeImage}
			handleClose={() => setImageUpload(undefined)}
		/> : null}
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Field
				component={TextField}
				name="name"
				type="text"
				label="Course Title (Required)"
				placeholder='e.g. Tips & Tricks of Implant restorations'
				variant='outlined'
				fullWidth
				margin='normal'
			/>
			<Field
				component={TrackSelectorField}
				name="track"
				type="text"
				label="Select Track (Required)"
			/>
			<Field
				component={TextField}
				name="description"
				multiline
				type="text"
				label="About this course (Strongly recommended)"
				placeholder='This is a great place to describe your course to the viewers'
				fullWidth
				margin='normal'
			/>
			<Typography variant='overline'>Video objectives (Add 2-3 to summarize your course)</Typography>
			<Objectives
				name='objectives'
				values={values.objectives} />
			<Section
				title='Who is involved?'
				subtitle='Tag all the speakers, organizations and sponsors involved in this course'
				icon={<PeopleIcon />}>
				{!has_profile ? <Alert severity='error'>
					<AlertTitle>Profile created automatically</AlertTitle>
					<Typography>Please note we will automatically create a 
					profile for this YouTube Channel: <strong>{channel_title}</strong> when you 
					create this video
				</Typography>
				</Alert> : null}
				<Field
					name='video_profiles'
					component={VideoRoleFieldComponent}
					no_add
				/>
			</Section>
			<Typography align='center' variant='h6'>Add a video thumbnail</Typography>
			<Alert severity='info'>
				<AlertTitle>Custom Thumbnail (1200 x 600)</AlertTitle>
				This is optional but highly recommended, we can always add one from the video itself but creating a custom thumbnail may help you get more views.
				You can create one using <Link href='http://canva.com'>Canva, a free online graphics tools</Link>.
			</Alert>
			<div className={classes.imageContainer}>
				<LazyImage
					image_key={values.cover_image_key}
					image_opt={{ size: ImageSize.xlarge, aspect_ratio: 'none' }}
					className={classes.image}
					onClick={() => setImageUpload('cover_image_key')}
				/>
				<Button
					startIcon={<CameraIcon />}
					onClick={() => setImageUpload('cover_image_key')}
					variant='contained'
					size='large'
					color='secondary'
				>{values.feature_image_key ? 'Change' : 'Upload'}</Button>
			</div>

			{/* <Section
				title='Publish'
				icon={<PublishIcon />}>

				<Grid container spacing={1} alignItems='center'>
					<Grid item xs={12} sm={8}>
						<Field
							component={DateTimePicker}
							name="premieres_at"
							fullWidth
							text_field_props={{
								variant: 'outlined',
								label: "When do you want to launch this video?",
								fullWidth: true,
								margin: 'normal',
							}}
							minutesStep={5} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<Button
							variant='contained'
							size='large'
							fullWidth
							onClick={() => setFieldValue('premieres_at', new Date().toISOString())}>Release Once Approved</Button>
					</Grid>
				</Grid>
				<Box pt={1} pb={1} display='flex' alignItems='center'>
					<div style={{ flex: 1 }}>
						<Typography variant='h6'>
							Set video status
			</Typography>
						<FormHelperText>Keep your videos in draft and only you can see them.</FormHelperText>
					</div>
					<Field
						component={TextField}
						style={{ minWidth: 150 }}
						label='Pick Status'
						name='publish_status'
						value={values.publish_status}
						select
					>{VideoPublishStatusOptions.map(status => <MenuItem
						key={status.id}
						value={status.id}
					>{status.label}</MenuItem>)}
					</Field>
				</Box>
			</Section> */}
			<DevFormValues values={values} hidden />
		</MuiPickersUtilsProvider >
	</ >
}

const useStyles = makeStyles((theme) => createStyles({
	titleImageContainer: {
		marginTop: theme.spacing(2),
		position: 'relative',
		background: 'grey',
		borderRadius: theme.shape.borderRadius,
		overflow: 'hidden',
		width: '100%',
		height: 0,
		paddingTop: '25%',
		marginBottom: theme.spacing(1),
		'& button': {
			position: 'absolute',
			top: theme.spacing(1),
			right: theme.spacing(1),
		}
	},
	imageContainer: {
		marginTop: theme.spacing(2),
		position: 'relative',
		background: 'grey',
		borderRadius: theme.shape.borderRadius,
		overflow: 'hidden',
		width: '100%',
		height: 0,
		paddingTop: '50%',
		marginBottom: theme.spacing(1),
		'& button': {
			position: 'absolute',
			top: theme.spacing(1),
			right: theme.spacing(1),
		}
	},
	image: {
		display: 'block',
		position: 'absolute',
		objectFit: 'contain',
		width: '100%',
		height: '100%',
		left: 0,
		top: 0,
		cursor: 'pointer',
	},
	player: {
		margin: theme.spacing(2, 'auto'),
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		paddingTop: '45%',
		height: 0,
		position: 'relative',
		'& .frame': {
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
			borderRadius: theme.shape.borderRadius,
			overflow: 'hidden',
		},
	}
}));