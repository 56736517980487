import { createStyles, makeStyles, Typography } from "@material-ui/core";
import CertificateIcon from '@material-ui/icons/Star';
import React from "react";
import { VideoModel } from "../../models/video.model";
import { PaceBadge } from "../../ui/pace-badge";
import { Section } from "../../ui/section";

type Props = {
    video: VideoModel;
}

export const VideoCertificates = ({
    video,
}: Props) => {
    const classes = useStyles({});
    
    const total_ce = React.useMemo(() => {
        return Math.round(((video.video_module_summary?.total_duration || 0) / 3600) * 100) / 100
    }, [video.video_module_summary])

    return <Section
        icon={<CertificateIcon />}
        title='CE & Licensure'>
        <div className={classes.info}>
            <Typography variant='body1'><strong>Total CE:</strong> {total_ce}</Typography>
            <Typography variant='body1'><strong>PACE:</strong> {video.track.name} ({video.track.pace_code})</Typography>
            <Typography variant='body1'><strong>RCDSO (Ontario):</strong> Category-{video.track.rcdso_category}</Typography>
        </div>
        <PaceBadge />
    </Section>
}

const useStyles = makeStyles(theme => createStyles({
    info: {
        padding: theme.spacing(2, 0),
    }
}))