import { gql, useMutation, useQuery } from "@apollo/client"
import { createStyles, makeStyles } from "@material-ui/core"
import { RouteComponentProps, useNavigate } from "@reach/router"
import { Form, Formik } from "formik"
import React from "react"
import { useAppDialog } from "../../app-dialog"
import { parseServerError } from "../../app-dialog/parse-server-error"
import { AppLayout } from "../../layout/app-layout"
import { BasicDialogActions } from "../../ui/dialog"
import { LoadingButton } from "../../ui/loading-button"
import { LoadingDots } from "../../ui/loading-dots"
import { PageHeader } from "../../ui/page-header"
import { VideoEditorValidationSchema } from "../video-list/video-editor/video-creator.validation"
import { CurateForm } from './curate-form'
import { YtVideoDetailModel } from './yt-video-detail.model'


type Props = {
    video_id: string;
}


const QUERY = gql`
    query yt_info($video_id: String!) {
        youtube_info(video_id: $video_id) {
            data
        }   
    }
`;

const VIDEO_EDITOR_INSERT_MUTATION = gql`
	mutation import_youtube_user($object: import_youtube_user_input!) {
		import_youtube_user(object: $object) {
			video_id
		}
	}
`;

export const CuratePage = ({
    video_id,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const navigate = useNavigate();
    const app_dialog = useAppDialog();
    const [insertVideo, insert_result] = useMutation(
        VIDEO_EDITOR_INSERT_MUTATION
    );
    const {
        data,
        error,
        loading,
    } = useQuery(QUERY, {
        variables: {
            video_id
        }
    })

    const onSubmit = async (v: any) => {
        try {
            const { data } = await insertVideo({
                variables: {
                    object: {
                        youtube_id: video_id,
                        track_id: v.track?.id,
                        name: v.name,
                        description: v.description,
                        objectives: v.objectives || [],
                        cover_image_key: v.cover_image_key,
                    }
                },
            });
            if (data?.import_youtube_user) {
                app_dialog.showSnackbar('Video created');
                navigate(`/v/${data.import_youtube_user?.video_id}`)
            }
        } catch (e) {
            const { message } = parseServerError(e);
            if (typeof message === 'string' && message.indexOf('Uniqueness') > -1) {
                app_dialog.showDialog({
                    title: 'Duplicate',
                    message: 'Sorry this video is already in the Edropin library',
                    buttons: [],
                });
                return;
            }
            app_dialog.showError(e);
        }
    }

    const video = data?.youtube_info?.data as YtVideoDetailModel;

    const thumbnail = React.useMemo(() => {
        if (!video) {
            return null;
        }
        const { thumbnails } = video.snippet;
        const sizes = Object.keys(thumbnails);
        if (sizes.length === 0) {
            return null;
        }
        if (sizes.indexOf('maxres') > -1) {
            return thumbnails.maxres.url;
        }
        if (sizes.indexOf('high') > -1) {
            return thumbnails.high.url;
        }
        if (sizes.indexOf('medium') > -1) {
            return thumbnails.medium.url;
        }
        if (sizes.indexOf('small') > -1) {
            return thumbnails.small.url;
        }
    }, [video?.snippet?.thumbnails])

    if (loading || !video) {
        return <LoadingDots />
    }

    return <AppLayout>
        {/* {JSON.stringify(video)} */}
        <PageHeader 
            title='Curate video'
            no_back
            subtitle={video.snippet.title}
        />
        <Formik
            initialValues={{
                name: video.snippet.title,
                objectives: [],
                cover_image_key: thumbnail || null,
                video_profiles: video.profile ? [video.profile] : [],
                description: video.snippet.description || '',
                track: null,
            }}
            validationSchema={VideoEditorValidationSchema}
            onSubmit={onSubmit}>{form_props => <Form noValidate>
                <CurateForm {
                    ...form_props
                }
                    has_profile={!!video.profile}
                    channel_title={video.snippet.channelTitle}
                />
                <BasicDialogActions {...form_props}>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        color='primary'
                        loading={insert_result.loading}
                        size='large'>Create Video</LoadingButton>
                </BasicDialogActions>
            </Form>}</Formik>
    </AppLayout >
}


const useStyles = makeStyles(theme => createStyles({

}))