import { gql, useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useAppDialog } from "../../app-dialog";
import { environment } from "../../environment";
import { PostFragment, PostModel } from "./post.model";
import { useSubscribeNewReply } from "./use-subscribe-new-reply";

const LIST_MESSAGES_QUERY = gql`
	query ($parent_message_id: bigint!, $cursor: bigint, $limit: Int!){
		chat_message (
			limit: $limit,
			order_by: {
				id: desc
			}
			where: {
				_and: {
					parent_message_id: {
						_eq: $parent_message_id,
					},
					id: {
						_lt: $cursor
					}
				}
			}
		) {
			${PostFragment}
		}
	}
`


const MUTATION_UPDATE = gql`
	mutation update_message($message_id: bigint!, $set: chat_message_set_input!) {
		update_chat_message_by_pk(
			pk_columns: {id: $message_id},
			_set: $set,
		) {
			${PostFragment}
		}
	}
`;


const MUTATION_INSERT = gql`
	mutation insert_message($object: chat_message_insert_input!) {
		insert_chat_message_one(object: $object) {
			id
			parent_message_id
		}
	}
`;


type Props = {
	chat_id: number;
	parent_message_id: number;
}

export const useMessage = ({
	chat_id,
	parent_message_id,
}: Props) => {
	const limit = environment.default_page_size;
	const [has_more, setHasMore] = React.useState(true);
	const [has_new_messages, setHasNewComments] = React.useState(false);
	const app_dialog = useAppDialog();
	const { latest_reply } = useSubscribeNewReply({
		parent_message_id,
	});
	const [_updateMessage, update_status] = useMutation(MUTATION_UPDATE);
	const [_insertMessage, insert_status] = useMutation(MUTATION_INSERT);

	const { data, loading, refetch, fetchMore } = useQuery(
		LIST_MESSAGES_QUERY, {
		variables: {
			parent_message_id,
			limit,
			cursor: Number.MAX_SAFE_INTEGER,
		},
		onCompleted: (data) => {
			setHasMore((data?.chat_message || []).length === limit);
		}
	});

	const insertMessage = async (props: {
		text: string;
	}) => {
		try {
			const { data } = await _insertMessage({
				variables: {
					object: {
						chat_id,
						parent_message_id,
						text: props.text,
					},
				}
			});
			const message = data?.insert_chat_message_one;
			if (!message) {
				return false;
			}
			refetch();
			return true;
		} catch (e) {
			app_dialog.showError(e);
			return false;
		}
	}

	const updateMessage = async (message_id: number, values: any) => {
		try {
			const { data } = await _updateMessage({
				variables: {
					message_id,
					set: values,
				}
			});
			return true;
		} catch (e) {
			app_dialog.showError(e);
			return false;
		}
	}


	function loadMore() {
		if (!has_more || loading || chat_messages.length === 0) {
			return;
		}
		fetchMore({
			variables: {
				limit,
				parent_message_id,
				cursor: chat_messages[chat_messages.length - 1].id,
			},
			updateQuery: (prev_result: any, { fetchMoreResult }) => {
				const prev_items = prev_result?.video_comment || [];
				const new_items = (fetchMoreResult?.video_comment || []);
				setHasMore(new_items.length === limit);
				return {
					video_comment: [...prev_items, ...new_items],
					__typename: prev_result.__typename
				};
			}
		})
	}


	const chat_messages = ((data?.chat_message || []) as PostModel[])
		.filter(m => !m.deleted_at);

	React.useEffect(() => {
		if (!latest_reply || chat_messages.length === 0 || latest_reply.id === chat_messages[0].id) {
			setHasNewComments(false);
			return;
		}
		setHasNewComments(true);
	}, [latest_reply, chat_messages])

	const loadNewer = () => {
		refetch();
	}

	return {
		chat_messages,
		loading,
		has_new_comments: has_new_messages,
		loadNewer,
		has_more,
		loadMore,
		updateMessage,
		updating: update_status.loading,
		insertMessage,
		inserting: insert_status.loading,
	}
}