import { Button, createStyles, makeStyles } from "@material-ui/core";
import NoVideoIcon from '@material-ui/icons/Videocam';
import React from "react";
import { useAppDialog } from "../../app-dialog";
import { VideoModuleModel } from "../../models/video-module.model";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { Section } from "../../ui/section";
import { arrayMove } from "../../utils/array-move";
import { sortItemsByIdList } from "../../utils/sort-items-by-id-list";
import { VideoModuleItem } from "./video-module-item";
import { VideoModuleEditorDialog } from './video-module-editor';

type Props = {
    video_id: number;
    in_review_at?: string;
    module_order?: number[];
    modules?: VideoModuleModel[];
    onUpdate: (v: any) => void;
}

export const VideoModules = ({
    video_id,
    in_review_at,
    module_order,
    modules,
    onUpdate,
}: Props) => {
    const classes = useStyles({});
    const [editor, setEditor] = React.useState<{
        action: 'edit' | 'add',
        model?: VideoModuleModel;
    } | undefined>();


    const _modules = React.useMemo(() => sortItemsByIdList(
        module_order || [], modules || [],
    ), [
        module_order, modules,
    ]);

    const reorder = async (idx: number, direction: 'up' | 'down') => {
        const order = _modules.map(t => t.id);
        const new_idx = idx + (direction === 'up' ? -1 : 1);
        if (new_idx < 0 || new_idx > order.length - 1) {
            return;
        }
        const new_order = arrayMove(order, idx, new_idx);
        return onUpdate({
            module_order: new_order,
        });
    }

    return <Section
        title='Modules'
        subtitle='We recommend making your modules 15-20 minutes in length'
        action={<Button
            variant='contained'
            color='secondary'
            onClick={() => setEditor({
                action: 'add',
            })}>+ Add a video</Button>}
    >
        {editor ? <VideoModuleEditorDialog
            onClose={() => setEditor(undefined)}
            video_id={video_id}
            video_module={editor.model}
            action={editor.action}
            onFinish={() => setEditor(undefined)}
        /> : null}
        {_modules.length === 0 ? <EmptyListComponent
            icon={<NoVideoIcon />}
            message='No video modules :( Upload a video today'
        /> : null}

        {_modules.map((m, idx) => <VideoModuleItem
            key={m.id}
            item={m}
            is_up_enabled={idx > 0}
            is_down_enabled={idx < _modules.length - 1}
            move={dir => reorder(idx, dir)}
            onEdit={() => setEditor({
                action: 'edit',
                model: m,
            })}
        />)}
    </Section>
}

const useStyles = makeStyles(theme => createStyles({

}))