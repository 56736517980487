import { Box, Button } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { LoadingButton } from '../ui/loading-button';
import { ChatMessageModel } from './chat-message.model';
import { MessageForm } from './message-form';

type Props = {
	item: ChatMessageModel;
	onUpdate: (values: any) => void;
	updating?: boolean;
	discard: () => void;
}

const validation = yup.object().shape({
	text: yup.string().required('Please type in a message'),
})

export const MessageUpdate = ({
	item,
	onUpdate,
	updating,
	discard,
}: Props) => {
	const onSubmit = async (values: any, actions: FormikHelpers<any>) => {
		onUpdate(values);
	}

	return <Formik
		onSubmit={onSubmit}
		validationSchema={validation}
		initialValues={{
			text: item.text || '',
			images: item.images || [],
		}}>{(form_props) => <Form>
			<MessageForm {...form_props} edit />
			<Box p={2} pt={1}  textAlign='right'>
				<Button
					style={{
						marginRight: 8,
					}}
					onClick={discard}>Discard Edit</Button>
				<LoadingButton
					loading={updating}
					variant='contained'
					type='submit'
					color='secondary'
				>Save Post</LoadingButton>
			</Box>
		</Form>}
	</Formik>
}
