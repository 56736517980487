import { VideoModuleTagFragment, VideoModuleTagModel } from "./tag.model";


export type VideoModuleModel = {
	id: number;
	name: string;
	description?: string;
	duration: number;
	video_id: number;
	finish_threshold: number;
	youtube_url: string;
	deleted_at?: string;
	transcript?: string;
	video_module_tags?: VideoModuleTagModel[];
	video_module_streams: {
		id: number;
		progress_tracker: string;
		percent_complete: number;
		completed_at?: string;
	}[];
}

export const VideoModuleFragment = `
	id
	name
	description
	duration
	video_id
	finish_threshold
	youtube_url
	deleted_at
	transcript
	video_module_tags(where: {deleted_at: {_is_null: true}}) {
		${VideoModuleTagFragment}
	}
	video_module_streams {
		id
		progress_tracker
		percent_complete
		completed_at
	}
`;