export type TagModel = {
    id: number;
    name: string;
}

export const TagFragment = `
    id
    name
`;

export type VideoModuleTagModel = {
    id: number;
    tag_id: number;
    tag: TagModel;
    deleted_at?: string;
}

export const VideoModuleTagFragment = `
    id
    tag_id
    deleted_at
    tag {
        ${TagFragment}
    }
`;