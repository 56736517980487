import { gql, useMutation } from "@apollo/client";
import { Button, createStyles, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import BookmarkedIcon from '@material-ui/icons/Bookmark';
import BookmarkIcon from '@material-ui/icons/BookmarkBorder';
import OpenIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/KeyboardArrowUp';
import ShareIcon from '@material-ui/icons/Share';
import DislikedIcon from '@material-ui/icons/ThumbDown';
import DislikeIcon from '@material-ui/icons/ThumbDownOutlined';
import LikedIcon from '@material-ui/icons/ThumbUp';
import LikeIcon from '@material-ui/icons/ThumbUpOutlined';
import { Link } from "@reach/router";
import clsx from 'clsx';
import moment from "moment";
import React from "react";
import { useGetByPk } from "../../apollo/networking/use-get-by-pk";
import { useAppDialog } from "../../app-dialog";
import { useUser } from "../../auth/use-user";
import { Profiles } from "../../components/profiles";
import { LikeStatus } from "../../models/like.model";
import { VideoDetailModel } from "../../models/video-detail.model";
import { VideoEvaluationFragment, VideoEvaluationModel } from "../../models/video-evaluation.model";
import { VideoModuleModel } from "../../models/video-module.model";
import { VideoPublishStatus } from "../../models/video-publish-status";
import { VideoAbout } from "./video-detail/video-about";
import { VideoCertificates } from "./video-detail/video-certificates";
import { VideoEvaluation } from "./video-evaluation";
import { VideoEvaluationValues } from "./video-evaluation/video-evaluation.validation";
import { VideoShare } from "./video-share";

type Props = {
    video: VideoDetailModel;
    current_module_idx: number | null;
    video_module: VideoModuleModel;
}

const MutationUpsertReview = gql`
    mutation upsert_video_evaluation(
        $object: video_evaluation_insert_input!,
    ) {
        insert_video_evaluation_one(object: $object, on_conflict: {
            constraint: video_evaluation_pkey,
            update_columns: [
                overall_course,
                overall_expectations,
                overall_instructor,
                comments,
                liked_at,
                like_status
                reviewed_at,
                bookmarked_at
                data
            ]
        }){
            ${VideoEvaluationFragment}
        }
    }
`;

export const VideoBar = ({
    video,
    current_module_idx,
    video_module,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const { user_id } = useUser();
    const [show_share, setShowShare] = React.useState(false);
    const [show_review, setShowReview] = React.useState(false);
    const [upsertReview, upsert_status] = useMutation(MutationUpsertReview);
    const [is_liked, setIsLiked] = React.useState<LikeStatus>(LikeStatus.none);
    const [is_bookmarked, setIsBookmarked] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

    const {
        entity: video_evaluation,
        loading,
        refetch,
    } = useGetByPk<VideoEvaluationModel>({
        entity_name: 'video_evaluation',
        fragment: VideoEvaluationFragment,
        pk_columns: [{
            name: 'video_id',
            type: 'bigint',
            value: video.id,
        }, {
            name: 'user_id',
            type: 'String',
            value: user_id,
        }]
    });

    React.useEffect(() => {
        if (!video_evaluation) {
            return;
        }
        setIsLiked(video_evaluation.like_status);
        setIsBookmarked(!!video_evaluation.bookmarked_at)
    }, [video_evaluation])

    const saveReview = async (v: any) => {
        const values = {
            ...VideoEvaluationValues(video_evaluation),
            liked_at: video_evaluation?.liked_at,
            like_status: video_evaluation?.like_status,
            reviewed_at: video_evaluation?.reviewed_at,
            bookmarked_at: video_evaluation?.bookmarked_at,
        }
        try {
            const { data } = await upsertReview({
                variables: {
                    object: {
                        ...values,
                        ...v,
                        video_id: video.id,
                    }
                }
            });
            if (!video_evaluation) {
                refetch();
            }
            setShowReview(false);
            return true;
        } catch (e) {
            app_dialog.showError(e);
            return false;
        }
    }

    const onLike = (liked: boolean) => async () => {
        const like_status = liked ? LikeStatus.liked : LikeStatus.disliked;
        const _old_status = like_status;
        setIsLiked(like_status);
        app_dialog.showSnackbar(
            `${like_status === LikeStatus.disliked ? 'Disliked' : 'Liked'} video`
        )
        if (! await saveReview({
            like_status,
            liked_at: liked ? new Date().toISOString() : null,
        })) {
            setIsLiked(_old_status)
        };
        app_dialog.showDialog({
            title: 'Provide Feedback?',
            message: 'Would you like to quickly provide feedback for this course?',
            buttons: [
                { label: 'Dismiss' },
                {
                    label: 'Review Course',
                    color: 'primary',
                    onClick: () => setShowReview(true)
                },
            ]
        })
    }

    const toggleBookmark = async () => {
        const bookmark = !video_evaluation || !video_evaluation.bookmarked_at;
        setIsBookmarked(bookmark);
        if (bookmark) {
            app_dialog.showSnackbar(`Bookmarked this video`);
        }

        if (!await saveReview({
            bookmarked_at: bookmark ? new Date().toISOString() : null,
        })) {
            setIsBookmarked(!!video_evaluation?.bookmarked_at)
        };
    }

    return <div className={classes.wrapper}>
        <div className={classes.bar}>
            {show_share ? <VideoShare
                onClose={() => setShowShare(false)}
                name={video.name}
                video_id={video.id}
                is_live={video.publish_status === VideoPublishStatus.live}
            /> : null}
            {show_review ? <VideoEvaluation
                onClose={() => setShowReview(false)}
                loading={upsert_status.loading}
                video_evaluation={video_evaluation}
                onSave={saveReview}
            /> : null}
            <div style={{ flex: 1 }}>
                <Typography variant='body1'>{video.name} {(video.video_module_summary?.total_modules || 0) > 1 ? `| Pt. ${(current_module_idx || 0) + 1} of ${video.video_module_summary?.total_modules}` : ''}</Typography>
                <Typography variant='body2'>
                    <Link to={`/t/${video.track.id}`}>
                        {video.track.name}
                    </Link></Typography>
                <Typography variant='body2' color='textSecondary'>
                    <strong>{video?.video_user_tally?.total_users || 0}</strong> watched &middot; {moment(video.created_at).calendar()}</Typography>
            </div>
            <div className={classes.buttons}>
                <Tooltip title='Like this video'>
                    <IconButton
                        onClick={onLike(true)}>
                        {is_liked === LikeStatus.liked ? <LikedIcon /> : <LikeIcon />}</IconButton>
                </Tooltip>
                <Tooltip title='Dislike this video'>
                    <IconButton
                        onClick={onLike(false)}>
                        {is_liked === LikeStatus.disliked ? <DislikedIcon /> : <DislikeIcon />}
                    </IconButton>
                </Tooltip>
                <Tooltip title='Bookmark this video'>
                    <IconButton
                        onClick={toggleBookmark}
                    >
                        {is_bookmarked ? <BookmarkedIcon /> : <BookmarkIcon />}
                    </IconButton>
                </Tooltip>
                <Tooltip title='Share this video'>
                    <IconButton
                        onClick={() => setShowShare(true)}>
                        <ShareIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </div >
        <div className={clsx(classes.root, {
            [classes.expanded]: expanded,
        })}>
            <VideoAbout
                name={video_module.name}
                description={video.description}
                objectives={video.objectives}
            />
            <VideoCertificates
                video={video}
            />
            <Profiles
                title='Your hosts'
                subtitle='Speakers, sponsors and your experience leaders'
                profiles={(video.video_profiles || [])}
            />
        </div>
        <Button fullWidth size='small' onClick={() => setExpanded(e => !e)}>
            {!expanded ? <OpenIcon /> : <CloseIcon />}
        </Button>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    wrapper: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        marginTop: theme.spacing(0),
    },
    bar: {
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(0, -.5),
        '& button': {
            margin: theme.spacing(.5),
        },
    },
    root: {
        maxHeight: 0,
        overflow: 'hidden',
        transition: 'max-height 0.15s ease-out',
    },
    expanded: {
        marginTop: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(2),
        maxHeight: '100%',
    }
}))