import { gql, useMutation } from "@apollo/client";
import { useAppDialog } from "../../app-dialog";

const MutationStartVideo = gql`
mutation start_video($object: start_video_input!) {
    start_video(object: $object) {
        success
        failure
        video_id
    }
}
`;

type Props = {
    video_id: number;
}

export const useStartVideo = ({
    video_id,
}: Props) => {
    const app_dialog = useAppDialog();
    const [_startVideo, start_result] = useMutation(MutationStartVideo);

    const startVideo = async () => {
        try {
            await _startVideo({
                variables: {
                    object: {
                        video_id,
                    },
                }
            });
        } catch (e) {
            app_dialog.showError(e);
        }
    }
    const data = start_result.data?.start_video;

    return {
        startVideo,
        data,
        loading: start_result.loading,
        is_called: start_result.called,
    }
}