import { Container, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { animated, useSpring } from 'react-spring';
import { VideoDetailModel } from "../../../models/video-detail.model";
import { VideoModuleModel } from "../../../models/video-module.model";
import { FullScreenVideo } from "../fullscreen-video";
import { ControlBar } from "./control-bar";
import { EndScreen } from "./end-screen";
import { usePlayer } from "./player-context";
import { VideoModuleCredit } from "./video-module-credit";
import { YouTubeSourcePlayer } from "./youtube-source-player";


type Props = {
    video: VideoDetailModel;
    video_module: VideoModuleModel;
    next_checkpoint?: VideoModuleModel;
}

const TIMEOUT = 3000;

export const Player = ({
    video,
    video_module,
    next_checkpoint,
}: Props) => {
    const classes = useStyles({});
    const [mouse_moved, setMouseMoved] = React.useState<NodeJS.Timeout | undefined>();
    const {
        is_fullscreen,
        setFullscreen,
        is_playing,
        onPlay,
        is_ended,
    } = usePlayer();
    const is_control_visible = !!mouse_moved || !is_playing;

    const control_bar_style = useSpring({
        opacity: is_control_visible ? 1 : 0,
    });

    const viewControls = () => {
        if (is_playing) {
            if (mouse_moved) {
                clearTimeout(mouse_moved);
            }
            setMouseMoved(setTimeout(() => {
                setMouseMoved(undefined)
            }, TIMEOUT));
        }
    }

    const onRootClick = () => {
        onPlay(!is_playing);
    }

    return <FullScreenVideo
        is_fullscreen={is_fullscreen}
        setFullscreen={setFullscreen}>
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Container maxWidth='lg'>
                    <div className={classes.topRight}>
                        <VideoModuleCredit />
                    </div>
                    <YouTubeSourcePlayer
                        cover_image_key={video.cover_image_key} />
                    <animated.div
                        className='controls'
                        style={control_bar_style}
                    >
                        <ControlBar
                            module_name={video_module.name}
                            onMouseMove={viewControls}
                            togglePlay={onRootClick}
                        />
                    </animated.div>
                </Container>
                {is_ended ? <div className={classes.overlay}>
                    <Container style={{ height: '100%' }}>
                        <EndScreen next_checkpoint={next_checkpoint} />
                    </Container>
                </div> : null}
            </div>
        </div>
    </FullScreenVideo>
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        display: 'flex',
        alignItems: 'stretch',
        width: '100%',
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        background: 'black',
        [theme.breakpoints.down('sm')]: {
            minHeight: '70vh',
        },
        '& .controls': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 99,
        },
    },
    overlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 99,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        background: 'rgba(0, 0, 0, .9)',
    },
    topRight: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
    },
}))