export type VideoQuestionModel = {
    id: number;
    created_at: string;
    prompt: string;
    deleted_at?: string;
    video: {
        id: number;
        name: string;
        track: {
            id: number;
            name: string;
        }
    }
    options: {
        id: string;
        label: string;
        is_correct?: boolean;
    }[];
    explanation?: string;
    playback_info?: {
        video_module_id: number;
        playback_position?: number;
    }
}

export const VideoQuestionFragment = `
    id
    created_at
    deleted_at
    prompt
    options
    explanation
    playback_info
    video {
        id
        name
        track {
            id
            name
        }
    }
`;
