import { gql, useQuery } from "@apollo/client"
import { Button, createStyles, Grid, makeStyles, Typography } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import React from "react"
import ReactPlayer from "react-player"
import { AppLayout } from "../../layout/app-layout"
import { EmptyListComponent } from "../../ui/empty-list.component"
import { LoadingDots } from "../../ui/loading-dots"
import { useStorage } from "../../utils/use-storage"
import { YtVideoModel } from "./yt-video.model"
import { CurationSidebar } from './curation-sidebar';
import { CurationTopics } from './curation-topics';


type Props = {
}


const QUERY = gql`
    query video_list($object: yt_list_videos_input!) {
        yt_list_videos(object: $object) {
            data
            next_page_token
        }   
    }
`;

const APPROVAL_KEY = 'approval';
const CURRENT_IDX_KEY = 'current_video';
const KEYWORD_KEY = 'keyword';

export const CurateYtPage = ({
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const {
        getKey,
        setKey,
    } = useStorage();
    const [page_token, setPageToken] = React.useState('');
    const keyword = getKey(KEYWORD_KEY, 'dentistry');
    const setKeyword = (v: string) => setKey(KEYWORD_KEY, v);
    const current_video_idx = getKey(CURRENT_IDX_KEY, 0);
    const approval = getKey(APPROVAL_KEY, {}) as { [id: string]: boolean };
    const setCurrentVideoIdx = (idx: number) => setKey(CURRENT_IDX_KEY, idx)
    const setApproval = (idx: string, approved?: boolean) => setKey(APPROVAL_KEY, {
        ...approval,
        [idx]: approved,
    });
    const {
        data,
        error,
        loading,
    } = useQuery(QUERY, {
        variables: {
            object: {
                keyword,
                page_token,
            }
        }
    });
    const videos = (data?.yt_list_videos?.data || []) as YtVideoModel[];

    const next = () => {
        if (videos.length > current_video_idx) {
            setCurrentVideoIdx(current_video_idx + 1);
        }
    }

    const prev = () => {
        if (current_video_idx > 0) {
            setCurrentVideoIdx(current_video_idx - 1);
        }
    }

    const current_video = React.useMemo(() => {
        return videos[current_video_idx];
    }, [current_video_idx, videos])

    const approve = () => {
        setApproval(current_video.id.videoId, true);
        window.open(`/curate/${current_video.id.videoId}`, '_blank');
        // next();
    }

    const reject = () => {
        setApproval(current_video.id.videoId, false);
        next();
    }

    const nextPage = () => {
        setPageToken(data?.yt_list_videos?.next_page_token);
    }


    return <AppLayout>
        <CurationTopics
            keyword={keyword}
            setKeyword={v => {
                setPageToken('');
                setKeyword(v);
            }} />
        {loading ? <LoadingDots /> : videos.length === 0 ? <EmptyListComponent
            message='No videos found' /> : null}
        {videos.length > 0 ? <div className={classes.wrapper}>

            <div className={classes.sidebar} >
                <CurationSidebar
                    videos={videos}
                    current_idx={current_video_idx}
                    approval={approval}
                    setCurrentVideoIdx={setCurrentVideoIdx}
                />
                <Button
                    variant='contained'
                    color='secondary'
                    fullWidth
                    onClick={nextPage}>Next Page</Button>
            </div>
            <div className={classes.preview}>
                <div className={classes.player}>
                    <ReactPlayer
                        url={`http://youtube.com/watch?v=${current_video.id.videoId}`}
                        width='100%'
                        height='100%'
                        playing
                        playbackRate={2}
                        controls={true}
                    />
                </div>
                <div className={classes.content}>
                    <Typography variant='h6'><strong>{current_video.snippet.title}</strong></Typography>
                    <Typography variant='body1'
                        color='textSecondary' paragraph>{current_video.snippet.description}</Typography>
                </div>
                <div className={classes.buttons}>
                    <Button variant='contained'
                        onClick={prev} size='large'>Previous</Button>
                    <div style={{ flex: 1 }} />
                    <Button variant='contained' color='primary'
                        className='success'
                        onClick={approve}
                        size='large'>Approve</Button>
                    <Button variant='contained' color='primary'
                        onClick={reject}
                        size='large'>Reject</Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        onClick={next} variant='contained' size='large'>Next</Button>
                </div>
            </div>
        </div> : null}
    </AppLayout>
}


const useStyles = makeStyles(theme => createStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        position: 'relative',
    },
    sidebar: {
        width: 250,
        marginRight: theme.spacing(2),
    },
    preview: {
        flex: 1,
        position: 'sticky',
        top: 100,
    },
    player: {
        width: '100%',
        height: 0,
        paddingTop: '56.25%',
        background: 'black',
        position: 'relative',
        '& iframe': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
        }
    },
    buttons: {
        textAlign: 'center',
        padding: theme.spacing(2, 0),
        display: 'flex',
        alignItems: 'center',
        '& button': {
            margin: theme.spacing(.5)
        },
        '& .success': {
            background: theme.palette.success.main,
        }
    },
    content: {
        padding: theme.spacing(2, 0),
    }
}))