import { useMutation } from '@apollo/client';
import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { useGetByPk } from '../apollo/networking/use-get-by-pk';
import { useAppDialog } from '../app-dialog';
import { ErrorComponent } from '../ui/errors';
import { LoadingDots } from '../ui/loading-dots';
import { ChatMessages } from './chat-messages';
import { ChatFragment, ChatModel } from './chat.model';



type Props = {
	chat_id: number;
}

export const Chat = ({
	chat_id,
}: Props) => {
	const classes = useStyles({});
	const app_dialog = useAppDialog();
	const {
		entity,
		loading,
		error,
		refetch,
	} = useGetByPk<ChatModel>({
		entity_name: 'chat',
		fragment: ChatFragment,
		pk_columns: [{
			name: 'id',
			type: 'bigint',
			value: chat_id,
		}]
	});

	if (loading) {
		return <LoadingDots />;
	}

	if (error || !entity) {
		return <ErrorComponent error={error} title='Failed to load chat' />;
	}
	return <div className={classes.chat}>
		<ChatMessages chat_id={chat_id} />
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	chat: {
		background: theme.palette.background.paper,
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(2, 0),
		'& .joinButton': {
			padding: theme.spacing(0, 2),
		}
	}
}));