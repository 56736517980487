import { Chip, createStyles, makeStyles } from "@material-ui/core"
import React from "react"


type Props = {
    keyword: string;
    setKeyword: (v: string) => void;
}

const keywords = [
    'dentistry',
    'dental ce',
    'dental restoration ce',
    'operative dentistry',
    'pediatric dentistry ce',
    'implant restoration',
    'implant surgery ce',
    'oral pathology ce',
    'clear aligner ce',
    'dental assisting tips',
];

export const CurationTopics = ({
    keyword,
    setKeyword,
}: Props) => {
    const classes = useStyles({});
    return <div className={classes.wrapper}>
        {keywords.map(k => <Chip 
            key={k}
            label={k}
            clickable
            className={`chip ${keyword === k ? 'selected' : ''}`}
            onClick={() => setKeyword(k)}
        />)}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2, 0),
        '& .chip': {
            margin: theme.spacing(.5),
        },
        '& .selected': {
            backgroundColor: theme.palette.success.main,
            color: 'white',
        }
    }
}))