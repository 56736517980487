import { useMutation } from '@apollo/client';
import { DialogContent } from '@material-ui/core';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../../app-dialog';
import { VideoQuestionFragment, VideoQuestionModel } from '../../../../models/video-question.model';
import { DevFormValues } from '../../../../ui/dev-form-values';
import { BasicDialogActions } from '../../../../ui/dialog';
import { LoadingButton } from '../../../../ui/loading-button';
import { QuestionForm } from './question-form';
import { EditQuestionValidationSchema, QuestionInitialValues } from './question.validation';

type Props = {
	question: VideoQuestionModel;
	onFinish: (model: VideoQuestionModel, is_new?: boolean) => void;
}

const EDIT_VIDEO_MODULE_MUTATION = gql`
	mutation update_question(
		$question_id: bigint!, 
		$set: video_question_set_input!) {
		update_video_question_by_pk(
			pk_columns: {id: $question_id},
			_set: $set,
		) {
			${VideoQuestionFragment}
		}
	}
`;

export const QuestionEdit = ({
	question,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [updateModule, update_result] = useMutation(EDIT_VIDEO_MODULE_MUTATION);
	const onSubmit = async (values: any) => {
		const has_right_answer = (values.options || []).findIndex((o: any) => o.is_correct) > -1;
		if (!has_right_answer) {
			app_dialog.showDialog({
				title: 'Select a right answer',
				message: 'Looks like you have not selected a correct answer. Checkbox at least one correct option.',
				buttons: [],
			});
			return;
		}
		try {
			const { data } = await updateModule({
				variables: {
					question_id: question.id,
					set: values,
				},
			});
			if (data && data.update_video_question_by_pk) {
				app_dialog.showSnackbar('Question saved');
				onFinish(data.update_video_question_by_pk, false);
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}
	return <Formik
		initialValues={QuestionInitialValues(question)}
		validationSchema={EditQuestionValidationSchema}
		onSubmit={onSubmit}>{props => <Form noValidate>
			<DialogContent>
				<QuestionForm {...props} />
				<DevFormValues {...props} />
			</DialogContent>
			<BasicDialogActions>
				<LoadingButton
					loading={update_result.loading}
					type='submit'
					variant='contained'
					color='secondary'
				>Save</LoadingButton>
			</BasicDialogActions>
		</Form >}
	</Formik >
}