import React from "react";
import { useGetByPk } from "../../../apollo/networking/use-get-by-pk";
import { useUser } from "../../../auth/use-user";
import { VideoModuleStreamFragment, VideoModuleStreamModel } from "../../../models/video-module-stream.model";
import { LoadingDots } from "../../../ui/loading-dots";

type Props = {
    video_module_id: number;
    children: (stream: VideoModuleStreamModel) => React.ReactChild;
}

export const VideoModuleStream = ({
    video_module_id,
    children,
}: Props) => {
    const { user_id } = useUser();
    const {
        entity: video_module_stream,
        loading,
    } = useGetByPk<VideoModuleStreamModel>({
        entity_name: 'video_module_stream',
        fragment: VideoModuleStreamFragment,
        pk_columns: [{
            name: 'video_module_id',
            type: 'bigint',
            value: video_module_id,
        }, {
            name: 'user_id',
            type: 'String',
            value: user_id,
        }],
        fetch_policy: 'network-only',
    });

    if (!video_module_stream || loading) {
        return <LoadingDots />;
    }

    return <>
        {children(video_module_stream)}
    </>
}
