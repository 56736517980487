import { gql, useSubscription } from "@apollo/client";

const LATEST_COMMENTS_SUBSCRIPTION = gql`
	subscription($parent_message_id: bigint!) {
		chat_message (where: {_and: [
			{parent_message_id: {_eq: $parent_message_id}},
		]}, order_by: { id: desc }, limit: 1) {
			id
			created_at
		}
	}
`;

type Props = {
	parent_message_id: number;
}

export const useSubscribeNewReply = ({
	parent_message_id,
}: Props) => {
	const { data: latest_data } = useSubscription(
		LATEST_COMMENTS_SUBSCRIPTION, {
		variables: {
			parent_message_id,
		},
	});
	
	const latest_reply = latest_data?.chat_message?.length > 0
		? latest_data.chat_message[0] as {
			id: number;
			created_at: string;
		} : undefined;

	return {
		latest_reply,
	}
}