import { ChatMessageReaction } from "./chat-message-reaction.model";


export type ChatMessageModel = {
	id: number;
	created_at: string;
	text: string;
	user_id: string;
	deleted_at?: string;
	chat_id: number;
	parent_message_id?: number;
	user_profile: {
		id: string;
		profile_image_key?: string;
		name: string;
	}
	images?: string[];
	chat_message_reactions: {
		id: number;
		status: ChatMessageReaction;
	}[];
	chat_message_reactions_tally?: {
		id: number;
		total_reactions?: number;
	};
	reply_tally?: {
		total_replies?: number;
	}
	replies?: ChatMessageModel[]
}

export const ChatMessageFragment = `
	id
	created_at
	text
	user_id
	deleted_at
	chat_id
	parent_message_id
	images
	user_profile {
		id
		profile_image_key
		name
	}
	chat_message_reactions {
		id
		status
	}
	chat_message_reactions_tally {
		id
		total_reactions
	}
`;
