import { useLazyQuery } from "@apollo/client";
import { DocumentNode } from "graphql";
import React from "react";

export type LazySearchProps = {
	fragment: DocumentNode;
	variables?: any;
	ilike?: boolean;
}

export const useLazySearch = ({
	fragment,
	variables,
	ilike,
}: LazySearchProps) => {
	const [doSearch, search_result] = useLazyQuery(fragment, {
		fetchPolicy: 'network-only'
	});
	const [query, setQuery] = React.useState('');
	
	const onSearch = async (_query: string) => {
		setQuery(_query);
		doSearch({
			variables: {
				...variables,
				query: ilike ? `%${_query}%` : _query,
			}
		})
	}

	const onClearSearch = () => {
		setQuery('');
	}

	return {
		onSearch,
		onClearSearch,
		query,
		is_searching: !!query && (ilike || query.length >= 3),
		data: search_result.data,
		loading: search_result.loading,
	}
}
