import { gql, useMutation } from "@apollo/client";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import pluralize from "pluralize";
import React from "react";
import { useAppDialog } from "../../app-dialog";
import { useUser } from "../../auth/use-user";
import { InsertReply } from "../../chat/insert-reply";
import { Message } from "../../chat/message";
import { ChatMessageReaction } from "../chat-message-reaction.model";
import { PostFragment, PostModel } from "./post.model";
import { useMessage } from "./use-message";

const MUTATION_INSERT_REACTION = gql`
    mutation toggle_reaction($object: chat_message_reaction_insert_input!) {
        insert_chat_message_reaction_one(object: $object, on_conflict: {
            constraint: chat_message_reaction_pkey,
            update_columns: [status]
        }) {
            chat_message {
                ${PostFragment}
            }
        }
    }
`;

type Props = {
    post: PostModel;
    refetchPost: () => void;
}


export const Post = ({
    post,
    refetchPost
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const [highFive] = useMutation(MUTATION_INSERT_REACTION);
    const [add_reply, setAddReply] = React.useState<boolean>(false);
    const { user_id } = useUser();

    const {
        chat_messages,
        has_more,
        has_new_comments,
        loading,
        loadNewer,
        updateMessage,
        updating,
        insertMessage,
        inserting,
    } = useMessage({
        parent_message_id: post.id,
        chat_id: post.chat_id,
    });

    const onHighFive = async (chat_message: PostModel, status: ChatMessageReaction) => {
        try {
            await highFive({
                variables: {
                    object: {
                        chat_message_id: chat_message.id,
                        status,
                    },
                }
            });
        } catch (e) {
            app_dialog.showError(e);
        }
    }

    const insertReply = async (message: any) => {
        const result = await insertMessage({
            ...message,
        })
        if (result) {
            setAddReply(false);
        }
        return result;
    }
    return <>
        <Message
            user_id={user_id}
            onUpdate={updateMessage}
            updating={updating}
            chat_message_reactions={post.chat_message_reactions}
            onHighFive={(item, status) => onHighFive(item as PostModel, status)}
            upvote_tally={post.chat_message_reactions_tally?.total_reactions || 0}
            can_reply
            onReply={() => setAddReply(true)}
            item={post} />
        {add_reply ? <InsertReply
            parent_name={post.user_profile.name}
            insertMessage={message => insertReply(message)}
            onCancel={() => setAddReply(false)}
            inserting={inserting} /> : null}
        {!!post.reply_tally?.total_replies
            ? <Typography variant='overline'>{post.reply_tally.total_replies} {pluralize('reply', post.reply_tally.total_replies)}</Typography>
            : null}
        {chat_messages.map(item => <Message
            key={item.id}
            user_id={user_id}
            onUpdate={updateMessage}
            updating={updating}
            chat_message_reactions={item.chat_message_reactions}
            onHighFive={(item, status) => onHighFive(item as PostModel, status)}
            upvote_tally={item.chat_message_reactions_tally?.total_reactions || 0}
            item={item} />)}
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))