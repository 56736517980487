import { createStyles, lighten, makeStyles, Typography } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WatchingIcon from '@material-ui/icons/PlayCircleFilled';
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked';
import clsx from 'clsx';
import React from "react";
import { VideoModuleModel } from "../../../models/video-module.model";
import { UnstyledLink } from "../../../ui/unstyled-link";
import { getDurationText } from "../../../utils/duration";
import { usePlayer } from "../video-player/player-context";

type Props = {
    video_id: number;
    is_selected?: boolean;
    video_module: VideoModuleModel;
}

export const VideoModuleItem = ({
    video_id,
    is_selected,
    video_module,
}: Props) => {
    const classes = useStyles({});
    const {
        video_module_stream: current_stream
    } = usePlayer();

    const {
        video_module_streams,
    } = video_module;

    const video_module_stream = React.useMemo(() => !!current_stream && current_stream.video_module_id === video_module.id
        ? current_stream : video_module_streams &&
            video_module_streams.length > 0 ? video_module_streams[0] : undefined,
        [video_module_streams, current_stream]);

    const [icon, status] = React.useMemo(() => {
        if (!video_module_stream) {
            return [<CircleIcon />, '']
        }
        if (video_module_stream.completed_at) {
            return [<CheckCircleIcon style={{ color: 'green' }} />, 'Complete | '];
        }
        return [<WatchingIcon style={{ color: 'orange' }} />, 'Watching | '];
    }, [video_module_stream])

    return <UnstyledLink
        className={clsx(classes.wrapper, {
            [classes.active]: is_selected,
        })}
        to={`/v/${video_id}?ck=${video_module.id}`}>
        <div className={classes.progressBar}
            style={{
                width: `${video_module_stream?.percent_complete || 0}%`
            }}
        />
        <div className={classes.item}>
            {icon}
            <div className={classes.text}>
                <Typography variant='body1'>{video_module.name}</Typography>
                <Typography variant='body2' color='textSecondary'>Must watch {video_module.finish_threshold}% to complete</Typography>
                <Typography variant='body2' color='textSecondary'>{`${status}${getDurationText(video_module.duration, true)}`}</Typography>
            </div>
        </div>
    </UnstyledLink>
}

const useStyles = makeStyles(theme => createStyles({
    wrapper: {
        display: 'block',
        textDecoration: 'none',
        color: 'inherit',
        padding: theme.spacing(1),
        background: 'white',
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
    },
    progressBar: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: lighten(theme.palette.success.light, .2),
        zIndex: -1,
    },
    item: {
        display: 'flex',
        alignItems: 'flex-start',
        '& svg': {
            marginTop: 4,
        }
    },
    active: {
        background: theme.palette.divider,
    },
    text: {
        flex: 1,
        marginLeft: theme.spacing(1),
    }
}))