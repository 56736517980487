import { Box, createStyles, makeStyles, Tabs } from "@material-ui/core";
import React from "react";
import { Chat } from "../../chat/chat";
import { VideoDetailModel } from "../../models/video-detail.model";
import { VideoModuleModel } from "../../models/video-module.model";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { StyledTab } from "../../ui/tab-bar/styled-tab.component";
import { VideoRatings } from "./video-ratings";
import { VideoResources } from "./video-resource";
import VideoTranscript from "./video-transcript";

export enum SidebarTab {
    comments = 0,
    ratings = 1,
    transcript = 2,
    resources = 3,
}

export const SidebarTabs = [
    { id: SidebarTab.comments, label: 'Comments', },
    { id: SidebarTab.ratings, label: 'Ratings & Reviews' },
    { id: SidebarTab.transcript, label: 'Transcript' },
    { id: SidebarTab.resources, label: 'Resources' },
];

type Props = {
    video: VideoDetailModel;
    video_module: VideoModuleModel;
}


export const VideoPlayTabs = ({
    video,
    video_module,
}: Props) => {
    const classes = useStyles({});
    const [which_tab, setTab] = React.useState(SidebarTab.comments);

    const renderTab = () => {
        switch (which_tab) {
            case SidebarTab.comments:
                return !video.chat_id ? <EmptyListComponent message='Comments not enabled for this video' />
                    : <Chat
                        chat_id={video.chat_id}
                    />
            case SidebarTab.transcript:
                return <VideoTranscript 
                    transcript={video_module.transcript}
                />
            case SidebarTab.ratings:
                return <VideoRatings video_id={video.id} />
            case SidebarTab.resources:
                return <VideoResources video_id={video.id} />
        }
    }

    return <>
        <Tabs
            value={which_tab}
            onChange={(_, new_value) => setTab(new_value)}
            indicatorColor='primary'
            textColor='primary'
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: classes.root, scroller: classes.scroller }}
        >
            {SidebarTabs.map(tab => <StyledTab
                key={tab.id}
                label={tab.label}
                color='primary'
            />)}
        </Tabs>
        <Box pt={4}>
            {renderTab()}
        </Box>
    </>
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    scroller: {
        flexGrow: 0,
    }
}))