import { useLazyQuery } from '@apollo/client';
import { CircularProgress, createStyles, FormHelperText, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ErrorMessage, FieldProps } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { getValueAtPath } from '../../../utils/get-value-at-path';
import { useDebounceValue } from '../../../utils/use-debounce-value';
import { TrackFragment, TrackModel } from './track.model';

const QueryTracks = gql`
	query tracks($text: String) {
		track(
			where: {
				_and: {
					name: {_ilike: $text},
				},
			},
			order_by: {
				track_video_tally: {
					total_videos: desc_nulls_last
				}
			}
		) {
			${TrackFragment}
		}
	}
`;

interface Props extends FieldProps {
	label: string;
	helper_text?: string;
}

export const TrackSelectorField = ({
	form: {
		setFieldValue,
		errors,
		touched,
	},
	field: {
		name,
		value,
	},
	label,
	helper_text,
}: Props) => {
	const classes = useStyles({});
	const [lookupSeries, lookup_series] = useLazyQuery(
		QueryTracks
	)
	const [inputValue, setInputValue] = React.useState('');
	const debounced_value = useDebounceValue(inputValue);

	const hasError = Boolean(getValueAtPath(errors, name))
		&& Boolean(getValueAtPath(touched, name));

	React.useEffect(() => {
		let active = true;
		lookupSeries({
			variables: {
				text: `%${debounced_value}%`,
			}
		})
		return () => {
			active = false;
		};
	}, [debounced_value]);

	const options = (lookup_series.data?.track || []) as TrackModel[]

	return <div>
		<Autocomplete
			value={value || null}
			onChange={(event: any, newValue: TrackModel | null) => {
				setFieldValue(name, newValue);
			}}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			getOptionSelected={(option, value) => option?.name === value?.name}
			getOptionLabel={(option) => `${option.name} - ${option.track_video_tally?.total_videos || 0}`}
			options={options}
			loading={lookup_series.loading}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					margin='normal'
					error={hasError}
					helperText={hasError ? <ErrorMessage name={name} /> : null}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{lookup_series.loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
		{helper_text ? <FormHelperText>{helper_text}</FormHelperText> : null}
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	deck: {
		display: 'flex',
		alignItems: 'center',
	},
}));