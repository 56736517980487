import { createStyles, List, ListItem, ListItemIcon, ListItemText, ListSubheader, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckBox';
import React from 'react';
import { Markdown } from '../../../ui/markdown';

type Props = {
	name: string;
	description: string;
	objectives?: string[];
}

export const VideoAbout = ({
	name,
	description,
	objectives = [],
}: Props) => {
	const classes = useStyles({});
	return <>
		{name !== 'Presentation' && name !== 'Lecture' ? <Typography variant='h6'>
			<strong>Module: </strong> {name}
		</Typography> : null}
		<Markdown text={description} />
		{(objectives || []).filter(i => !!i).length > 0 ? <List>
			<ListSubheader
				disableGutters>Objectives</ListSubheader>
			{(objectives || []).map((i, idx) => <ListItem disableGutters
				divider={idx !== (objectives || []).length - 1} key={idx}>
				<ListItemIcon>
					<CheckIcon color='primary' />
				</ListItemIcon>
				<ListItemText
					primary={i}
				/>
			</ListItem>)}
		</List> : null}
	</>
}

const useStyles = makeStyles((theme) => createStyles({
	highlight: {
		background: theme.palette.secondary.main,
		color: theme.palette.getContrastText(theme.palette.secondary.main),
		paddingLeft: theme.spacing(.5),
		paddingRight: theme.spacing(.5),
	},
}));