import { Button, createStyles, DialogContent, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
	EmailIcon, EmailShareButton, FacebookIcon,

	FacebookShareButton, RedditIcon,
	RedditShareButton, TwitterIcon, TwitterShareButton
} from 'react-share';
import { ActionType } from '../../action-manager/action-manager';
import { useAppDialog } from '../../app-dialog';
import { useUser } from '../../auth/use-user';
import { environment } from '../../environment';
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from '../../ui/dialog';


type Props = {
	name: string;
	video_id: number;
	icon_size?: number;
	onClose: () => void;
	is_live?: boolean;
}

const ICON_SIZE = 60;

export const VideoShare = ({
	video_id,
	name,
	icon_size = ICON_SIZE,
	onClose,
	is_live,
}: Props) => {
	const app_dialog = useAppDialog();
	const classes = useStyles({});
	const { manager } = useUser();

	const url = `${environment.frontend_url}/v/${video_id}`;

	const onShareWindowClose = (share: string) => {
		manager.insertAction(ActionType.share_video, {
			video_id,
			where: share,
		});
	}

	return <BasicDialog
		id='video-shared'
		onClose={onClose}
		max_width='sm'
		open={true}>
		<BasicDialogTitle title='Share' onClose={onClose} />
		{is_live ? <DialogContent>
			<div className={classes.shareBar}>
				<div className='item'>
					<FacebookShareButton
						quote={`Watch: ${name}`}
						url={url}
						onShareWindowClose={() => onShareWindowClose('facebook')}
					>
						<FacebookIcon size={icon_size} round={true} />
					</FacebookShareButton>
				</div>
				<div className='item'>
					<TwitterShareButton
						url={url}
						onShareWindowClose={() => onShareWindowClose('twitter')}
						title={name}
						hashtags={['eDropin']}>
						<TwitterIcon size={icon_size} round={true} />
					</TwitterShareButton>
				</div>
				<div className='item'>
					<EmailShareButton
						url={url}
						onShareWindowClose={() => onShareWindowClose('email')}
						subject={name}
						body={`Hey watch this video: ${name} on edropin`}
					>
						<EmailIcon size={icon_size} round={true} />
					</EmailShareButton>
				</div>
				<div className='item'>
					<RedditShareButton
						url={url}
						onShareWindowClose={() => onShareWindowClose('reddit')}
						title={`Watch ${name}`}
					>
						<RedditIcon size={icon_size} round={true} />
					</RedditShareButton>
				</div>
			</div>
			<TextField
				fullWidth
				margin='dense'
				value={url}
				InputProps={{
					endAdornment: <InputAdornment position='end'>
						<CopyToClipboard
							text={url}
							onCopy={() => {
								app_dialog.showSnackbar('Link copied')
								onShareWindowClose('link')
							}}>
							<Button size='small'>Copy</Button>
						</CopyToClipboard>
					</InputAdornment>
				}}
			/>
		</DialogContent> : <>
			<DialogContent>
				<Typography variant='body1'>
					This course is still <strong>In Draft</strong> so there are no public share link available yet. 
					However, you can preview this course using the button below. <br/><br/>If you are ready to publish this course, be sure to submit it for review.
				</Typography>
			</DialogContent>
			<BasicDialogActions>
				<Button onClick={onClose}>Dismiss</Button>
				<Button
					variant='contained'
					color='primary'
					href={`/v/${video_id}/play`}
					target='_blank'
				>Preview course</Button>
			</BasicDialogActions>
		</>}
	</BasicDialog>
}

const useStyles = makeStyles((theme) => createStyles({
	shareBar: {
		display: 'flex',
		justifyContent: 'center',
		'& .item': {
			margin: theme.spacing(1),
		}
	},
}));