import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import CertificateIcon from '@material-ui/icons/Star';
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { useGetByPk } from "../../../apollo/networking/use-get-by-pk";
import { useUser } from "../../../auth/use-user";
import { environment } from "../../../environment";
import { VideoDetailModel } from "../../../models/video-detail.model";
import { VideoLicensureFragment, VideoLicensureModel } from "../../../models/video-licensure.model";
import { LoadingDots } from "../../../ui/loading-dots";
import { PaceBadge } from "../../../ui/pace-badge";
import { Section } from "../../../ui/section";

type Props = {
    video: VideoDetailModel;
}

export const VideoCertificates = ({
    video,
}: Props) => {
    const classes = useStyles({});
    const { user: {
        state_id,
    } } = useUser();
    const {
        entity,
        loading,
        error,
    } = useGetByPk<VideoLicensureModel>({
        entity_name: 'video_licensure',
        fragment: VideoLicensureFragment,
        pk_columns: [{
            name: 'video_id',
            value: video.id,
            type: 'bigint',
        }, {
            name: 'state_id',
            value: state_id,
            type: 'bigint',
        }],
    });

    const total_ce = React.useMemo(() => {
        return Math.round(((video.video_module_summary.total_duration || 0) / 3600) * 100) / 100
    }, [video.video_module_summary])


    if (loading) {
        return <LoadingDots />
    }

    return <Section
        icon={<CertificateIcon />}
        title='CE & Licensure'>
        <Alert severity='info' action={<div style={{
            minWidth: 120,
        }}><Button size='small'
            href={`${environment.frontend_url}/microcredits`}
            target='_blank'
            variant='contained'>Learn More</Button></div>}>
            <AlertTitle>#MicroCredits on <strong>edropin</strong></AlertTitle>
                edropin converts your watch time into CE. For example, if you watch only 15 minutes of a video you get 0.25 CE.
            </Alert>
        <div className={classes.info}>
            {entity ? <div className={classes.highlight}>
                <Typography variant='overline'>Special Licensure Information</Typography>
                <Typography variant='body1'>
                    This video qualifies for <strong>{entity.name}</strong> ({entity.total_ce} CE) in <strong>{entity.state.name}</strong>.
            You can export a certificate for this licensure requirement after completing all the modules & quizzes.
            </Typography>
            </div> : null}

            <Typography variant='body1'><strong>Total CE:</strong> {total_ce}</Typography>
            <Typography variant='body1'><strong>PACE:</strong> {video.track.name} ({video.track.pace_code})</Typography>
            <Typography variant='body1'><strong>RCDSO (Ontario):</strong> Category-{video.track.rcdso_category}</Typography>
        </div>
        <PaceBadge />
    </Section>
}

const useStyles = makeStyles(theme => createStyles({
    highlight: {
        background: theme.palette.primary.main,
        color: 'white',
        padding: theme.spacing(1, 2, 2, 2),
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
    },
    info: {
        padding: theme.spacing(2, 0),
    }
}))