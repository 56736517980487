import { Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ReplyIcon from '@material-ui/icons/Reply';
import moment from 'moment';
import pluralize from 'pluralize';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { ImageSize, LazyImage } from '../image-upload';
import PhotoGallery from '../ui/photo-gallery';
import { ChatMessageReaction } from './chat-message-reaction.model';
import { ChatMessageModel } from './chat-message.model';
import { MessageUpdate } from './update-message';

type Props = {
	item: ChatMessageModel;
	user_id: string;
	onUpdate: (item_id: number, values: any) => Promise<boolean>;
	updating?: boolean;
	chat_message_reactions?: {
		id: number;
		status: ChatMessageReaction;
	}[];
	onHighFive: (item: ChatMessageModel, status: ChatMessageReaction) => void;
	upvote_tally?: number;
	onReply?: () => void;
	can_reply?: boolean;
}

export const Message: React.FC<Props> = ({
	item,
	user_id,
	onUpdate,
	updating,
	chat_message_reactions,
	onHighFive,
	upvote_tally,
	onReply,
	can_reply,
	children,
}) => {
	const classes = useStyles({});
	const [gallery_open, setGalleryOpen] = React.useState<number | false>(false);
	const [edit, setEdit] = React.useState(false);
	const [_reaction, setReaction] = React.useState<ChatMessageReaction | null>(null);
	const [_highfive_tally, setHighFiveTally] = React.useState<number>(0);


	React.useEffect(() => {
		setHighFiveTally(upvote_tally || 0);
	}, [upvote_tally])

	React.useEffect(() => {
		if (!chat_message_reactions || chat_message_reactions.length === 0) {
			setReaction(null);
			return;
		}
		setReaction(chat_message_reactions[0].status);
	}, [chat_message_reactions])

	const _onUpdate = async (values: any) => {
		if (await onUpdate(item.id, values)) {
			setEdit(false)
		}
	}

	const _onDelete = async () => {
		onUpdate(item.id, {
			deleted_at: 'now()',
		})
	}


	const _onHighFive = React.useCallback(() => {
		const new_vote = _reaction ? ChatMessageReaction.down : ChatMessageReaction.up;
		setReaction(new_vote);
		setHighFiveTally(v => v + (new_vote ? 1 : -1));
		return onHighFive(item, new_vote ? ChatMessageReaction.up : ChatMessageReaction.down);
	}, [onHighFive, _reaction]);

	const images = item.images || [];


	return <div className={classes.messageWrapper}>
		{gallery_open === false ? null : <PhotoGallery
			initial={gallery_open}
			onClose={() => setGalleryOpen(false)}
			images={item.images || []} />}
		{edit
			? <MessageUpdate
				item={item}
				discard={() => setEdit(false)}
				updating={updating}
				onUpdate={_onUpdate} />
			: <div className={classes.message}>
				<div className='messageHeader'>
					<div className='avatar'>
						<LazyImage
							image_key={item.user_profile.profile_image_key}
							image_opt={{
								size: ImageSize.small,
							}}
							style={{
								borderRadius: '50%',
								width: 30,
								height: 30,
							}}
							placeholder={'/images/avatar.jpg'}
						/>
					</div>
					<div>
						<Typography variant='body1'><strong>{item.user_profile.name}</strong> &middot; {moment(item.created_at).fromNow()}</Typography>
					</div>
				</div>
				<div className='message'>
					<div className='avatar'></div>
					<div className='content'>
						<ReactMarkdown
							plugins={[gfm]}
							linkTarget='_blank'
							children={item.text || ''} />
						{images.length > 0 ?
							<Grid container spacing={1} className={classes.images}>
								{images.map((image: string, idx: number) => <Grid
									item
									key={idx}
									xs={6}
									sm={4}
									lg={3}>
									<div className='item' onClick={() => setGalleryOpen(idx)}>
										<LazyImage
											image_key={image}
											image_opt={{
												size: ImageSize.large,
											}}
											className={classes.image}
										/>
									</div>
								</Grid>)}
							</Grid>
							: null}
						<div className={classes.buttons}>
							{user_id === item.user_profile.id ? <Button
								style={{
									display: !_highfive_tally ? 'none' : 'block',
									marginBottom: 8,
								}}
								className='active'
								variant='contained'
								size='small'
							>
								<span role='img'>👋</span> You were high fived {_highfive_tally || ''} {pluralize('time', _highfive_tally)}
							</Button> : null}

							{user_id === item.user_id ? null : <Button
								variant={_reaction ? 'contained' : 'outlined'}
								className={_reaction ? 'active' : ''}
								onClick={_onHighFive}
								size='small'
							><span role='img'>👋</span> {_reaction ? 'High Fived' : 'High Five'}{_highfive_tally > 0 ? ` · ${_highfive_tally}` : ''}
							</Button>}
							{can_reply ? <Button startIcon={<ReplyIcon />}
								onClick={onReply} size='small'>
								Reply
					</Button> : null}
							{user_id === item.user_id ? <Button
								onClick={() => setEdit(true)} size='small'
								startIcon={<EditIcon />}>Edit</Button> : null}
							{/* <Button size='small'
					startIcon={<ReplyIcon />}>Reply</Button> */}
							{user_id === item.user_id ? <Button size='small'
								color='secondary'
								onClick={_onDelete}
								startIcon={<DeleteIcon />}>Delete</Button> : null}
						</div>
					</div>
				</div>
			</div>}
		{children}
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	messageWrapper: {
		margin: theme.spacing(1.5, 0),
		'&:last-child': {
			borderBottom: 0,
		},
	},
	message: {
		padding: theme.spacing(1, 2),
		'& .messageHeader': {
			display: 'flex',
			alignItems: 'center',
			'& p': {
				margin: 0,
				color: theme.palette.text.secondary,
			},
			'& strong': {
				color: theme.palette.text.primary,
			},
			'& .avatar': {
				width: 45,
			},
		},
		'& .message': {
			display: 'flex',
			'& .avatar': {
				width: 45,
			},
			'& .content': {
				flex: 1,
				'& p': {
					fontSize: theme.typography.body1.fontSize,
					lineHeight: theme.typography.body1.lineHeight,
					wordBreak: 'break-word',
					marginTop: 0,
				},
				'& a': {
					color: theme.palette.info.main,
					textDecoration: 'underline',
					'&:hover': {
						color: theme.palette.info.light,
					}
				},
				'& img': {
					maxWidth: '100%',
				},
			},
		}
	},
	buttons: {
		'& button': {
			marginRight: 8,
		},
		'& .active': {
			background: 'linear-gradient(to right,  #ee0979, #ff6a00)',
			color: 'white',
		}
	},
	images: {
		padding: theme.spacing(2, 0),
		'& .item': {
			position: 'relative',
		}
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	}
}));