export type VideoUserModel = {
	id: string;
	created_at: string;
	updated_at: string;
	early_release_at?: string;
	video_id: number;
}


export const VideoUserFragment = `
	id
	created_at
	updated_at
	early_release_at
	video_id
`