import { createStyles, IconButton, makeStyles } from "@material-ui/core"
import React from "react"
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import PhoneIcon from '@material-ui/icons/Phone';


type Props = {
    email?: string;
    facebook?: string;
    linkedin?: string;
    twitter?: string;
    instagram?: string;
    phone?: string;
}

export const ProfileSocial = (props: Props) => {
    const Components = React.useMemo(() => {
        const comps = [];
        if (props.email) {
            comps.push({
                icon: <EmailIcon />,
                url: `mailto:${props.email}`
            });
        }
        if (props.phone) {
            comps.push({
                icon: <PhoneIcon />,
                url: `tel:${props.phone}`,
            })
        }
        if (props.facebook) {
            comps.push({
                icon: <FacebookIcon />,
                url: props.facebook,
            })
        }
        if (props.twitter) {
            comps.push({
                icon: <TwitterIcon />,
                url: `https://twitter.com/@${props.twitter}`
            })
        }
        if (props.instagram) {
            comps.push({
                icon: <InstagramIcon />,
                url: props.instagram,
            })
        }
        if (props.linkedin) {
            comps.push({
                icon: <LinkedInIcon />,
                url: props.linkedin,
            })
        }
        return comps;
    }, [props])
    const classes = useStyles({});
    return <div className={classes.buttons}>
        {Components.map(c => <IconButton
            component='a'
            href={c.url}
            key={c.url}
            target='_blank'
        >{c.icon}</IconButton>)}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    buttons: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(0, -.5),
        '& a': {
            margin: theme.spacing(0, .5),
        }
    }
}))