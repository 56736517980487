import { createStyles, Grid, IconButton, InputAdornment, makeStyles } from "@material-ui/core"
import PhotoIcon from '@material-ui/icons/AddAPhoto'
import RemoveIcon from '@material-ui/icons/RemoveCircle'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import { Field, FormikProps } from "formik"
import { TextField } from "formik-material-ui"
import React from "react"
import { useUser } from "../auth/use-user"
import { Image, ImageSize, ImageUpload, LazyImage } from "../image-upload"
import PhotoGallery from "../ui/photo-gallery"


interface Props extends FormikProps<any> {
    edit?: boolean;
}

export const MessageForm = ({
    values,
    setFieldValue,
    edit,
}: Props) => {
    const [image_upload, setImageUpload] = React.useState(false);
    const { user } = useUser();
    const classes = useStyles({});
    const images = (values.images || []) as string[];
    const [gallery_open, setGalleryOpen] = React.useState<number | false>(false);

    const onChangeImage = (_images: Image[]) => {
        setFieldValue('images', [...images, ..._images.map(i => i.key)]);
        setImageUpload(false);
    }

    const onRemoveImage = (e: React.MouseEvent<HTMLButtonElement>, idx: number) => {
        e.stopPropagation();
        e.preventDefault();
        setFieldValue('images', images
            .filter((_, i) => i !== idx))
    }

    const onRotate = (e: React.MouseEvent<HTMLButtonElement>, idx: number) => {
        e.stopPropagation();
        e.preventDefault();
        setFieldValue('images', images
            .map((image_key, i) => {
                if (i !== idx) {
                    return image_key;
                }
                const [key, rotation] = image_key.split('||');
                return `${key}||${parseInt(rotation || '0', 10) + 90}`;
            }))
    }

    return <div className={classes.form}>
        {gallery_open === false ? null : <PhotoGallery
            initial={gallery_open}
            onClose={() => setGalleryOpen(false)}
            images={images || []} />}
        {image_upload ? <ImageUpload
            onSelect={onChangeImage}
            multiple
            handleClose={() => setImageUpload(false)}
        /> : null}
        <div className='message'>
            <LazyImage
                image_key={user.profile_image_key}
                image_opt={{ size: ImageSize.medium }}
                placeholder='/images/avatar.jpg'
            />
            <Field
                component={TextField}
                name='text'
                label={edit ? 'Edit Post' : 'Create Post'}
                multiline
                fullWidth
                // margin='normal'
                // InputProps={{
                //     endAdornment: <InputAdornment position='end'>
                //         <IconButton onClick={() => setImageUpload(true)}>
                //             <PhotoIcon />
                //         </IconButton>
                //     </InputAdornment>
                // }}
            />
        </div>
        {images.length > 0 ?
            <Grid container spacing={1} className={classes.images}>
                {images.map((image: string, idx: number) => <Grid
                    item
                    key={idx}
                    xs={6}
                    sm={4}
                    lg={3}>
                    <div className='item' onClick={() => setGalleryOpen(idx)}>
                        <LazyImage
                            image_key={image}
                            image_opt={{
                                size: ImageSize.large,
                            }}
                            className={classes.image}
                        />
                        <div className='buttons'>
                            <IconButton
                                size='small'
                                color='primary'
                                onClick={(e) => onRotate(e, idx)}>
                                <RotateRightIcon />
                            </IconButton>
                            <IconButton
                                size='small'
                                color='secondary'
                                onClick={(e) => onRemoveImage(e, idx)}>
                                <RemoveIcon />
                            </IconButton>
                        </div>
                    </div>
                </Grid>)}
            </Grid>
            : null}
    </div>
}

const useStyles = makeStyles((theme) => createStyles({
    form: {
        padding: theme.spacing(1, 2),
        '& .message': {
            display: 'flex',
            alignItems: 'flex-start',
            '& img': {
                width: 50,
                height: 50,
                marginRight: theme.spacing(1),
                borderRadius: '50%',
            }
        }
    },
    images: {
        padding: theme.spacing(2, 0),
        '& .item': {
            position: 'relative',
            '& .buttons': {
                position: 'absolute',
                top: theme.spacing(.5),
                right: theme.spacing(.5),
                background: 'rgba(0, 0, 0, .6)',
                paddding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
            }
        }
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    }
}));