import { VideoModuleModel, VideoModuleFragment } from "./video-module.model";
import { VideoProfileModel, VideoProfileFragment } from "./video-profile.model";
import { VideoPublishStatus } from "./video-publish-status";

export type VideoModel = {
	id: number;
	updated_at: string;
	name: string;
	objectives?: string[];
	curator_user_id?: string;
	curator_notes?: string;
	description: string;
	premieres_at?: string;
	module_order?: number[];
	video_modules?: VideoModuleModel[];
	created_at: string;
	deleted_at?: string;
	in_review_at?: string;
	cover_image_key?: string;
	enabled_comments?: boolean;
	publish_status: VideoPublishStatus;
	slide_order?: number[];
	video_module_summary: {
		id: number;
		total_modules?: number;
		total_duration?: number;
	};
	video_tally: {
		total_users: number;
		total_started: number;
		total_completed: number;
	}
	chat_id?: number;
	video_profiles: VideoProfileModel[];
	track: {
		id: number;
		name: string;
		pace_code: string;
		rcdso_category: string;
	};
	video_user_tally?: {
		total_users?: number;
	}
}

export const VideoFragment = `
	id
	updated_at
	name
	description
	objectives
	curator_user_id
	curator_notes
	deleted_at
	in_review_at
	premieres_at
	module_order
	created_at
	premieres_at
	cover_image_key
	video_module_summary {
        id
        total_modules
        total_duration
	}
	enabled_comments
	publish_status
	chat_id
	video_modules {
		${VideoModuleFragment}
	}
	video_profiles(where: {deleted_at: {_is_null: true}}) {
		${VideoProfileFragment}
	}
	track {
		id
		name
		pace_code
		rcdso_category
	}
	video_user_tally {
		total_users
	}
`;