import React from "react";
import ProgressiveImage from 'react-progressive-image';
import { getImgUrl, Image, ImageSize, ImageStyle } from "./image.model";

interface LazyImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
	image?: Image;
	image_opt: ImageStyle;
	placeholder?: string;
	image_key?: string;
	no_thumb?: boolean;
}

export const LazyImage = ({ image_key, no_thumb, image, placeholder, image_opt, ...props }: LazyImageProps) => {
	if (!image_key) {
		return placeholder ? <img {...props} src={placeholder} alt={props.alt || 'an image'} /> : null;
	}
	const [thumb_src, high_res_src] = getImgUrl(image_key, [
		{ size: ImageSize.thumb, aspect_ratio: image_opt.aspect_ratio },
		image_opt,
	]);

	if (no_thumb) {
		return <img {...props} src={high_res_src} alt={props.alt || 'an image'} />
	}
	return (
		<ProgressiveImage src={high_res_src} placeholder={thumb_src}>
			{(src: string) => <img {...props} src={src} alt={props.alt || 'an image'} />}
		</ProgressiveImage>
	);
}
