import React from "react";

export const useHideCrisp = () => {
	const hide = () => {
		const $crisp = (window as any).$crisp;
		if (!$crisp) {
			return;
		}
		$crisp.push(['do', 'chat:hide']);
	};

	const show = () => {
		const $crisp = (window as any).$crisp;
		if (!$crisp) {
			return;
		}
		$crisp.push(['do', 'chat:show']);
	};

	return {
		hide,
		show,
	}
}