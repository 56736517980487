export type ChatModel = {
    id: number;
    name: string;
    chat_messages: {
        id: number;
        created_at: string;
    }[];
}

export const ChatFragment = `
    id
    name
    chat_messages(order_by: {created_at: desc}, limit: 1) {
        id
        created_at
    }
`