import React, { createContext } from 'react';
import ReactPlayer from 'react-player';
import { ActionType } from '../../../action-manager/action-manager';
import { useUser } from '../../../auth/use-user';
import { VideoModuleStreamModel } from '../../../models/video-module-stream.model';
import { useStorage } from '../../../utils/use-storage';
import { useUpdateVideoModuleStream } from './use-update-video-module-stream';

export type Player = {
	playback_position: number;
	setPlaybackPosition: (p: number) => void;
	is_fullscreen: boolean;
	setFullscreen: (v: boolean) => void;
	youtube_id: string;
	player_ref: React.RefObject<ReactPlayer>;
	is_ready: boolean;
	setIsReady: (v: boolean) => void;
	onPlay: (v: boolean) => void;
	seekTo: (v: number) => void;
	is_playing: boolean;
	setIsPlaying: (v: boolean) => void;
	volume: number;
	setVolume: (v: number) => void;
	duration: number;
	onProgress: (p: { playedSeconds: number }) => void;
	video_module_stream?: VideoModuleStreamModel;
	finish_threshold: number;
	is_ended: boolean;
	setIsEnded: (e: boolean) => void;
	playback_rate: number;
	setPlaybackRate: (v: number) => void;
	progress_tracker: string;
	percent_complete: number;
	total_credit: number;
}

const PlayerContext = createContext<Player>({} as any);

type Props = {
	video_id: number;
	video_module_stream: VideoModuleStreamModel;
	is_vimeo?: boolean;
	youtube_id: string;
	duration: number;
	finish_threshold: number;
}

const KeyPlaybackRate = 'ed_playback_rate';

export const ProvidePlayer: React.FC<Props> = ({
	video_id,
	video_module_stream,
	is_vimeo,
	youtube_id,
	duration,
	children,
	finish_threshold,
}) => {
	const { manager } = useUser();
	const {
		getKey,
		setKey,
	} = useStorage();
	const [progress_tracker, setProgressTracker] = React.useState(video_module_stream.progress_tracker);
	const [percent_complete, setPercentComplete] = React.useState(video_module_stream.percent_complete || 0);
	const [total_credit, setTotalCredit] = React.useState(video_module_stream.total_credit || 0);
	const [playback_position, setPlaybackPosition] = React.useState(video_module_stream.playback_position);
	const [is_fullscreen, setFullscreen] = React.useState(false);
	const [is_ready, setIsReady] = React.useState(false);
	const [is_playing, setIsPlaying] = React.useState(false);
	const [is_ended, setIsEnded] = React.useState(false);
	const [volume, setVolume] = React.useState(1);
	const player_ref = React.useRef<ReactPlayer>(null);
	const playback_rate = getKey(KeyPlaybackRate, 1);
	const setPlaybackRate = (v: number) => setKey(KeyPlaybackRate, v);

	const seekTo = (v: number) => {
		if (!player_ref.current) {
			return;
		}
		setPlaybackPosition(() => {
			player_ref.current?.seekTo(v);
			return v
		});
		const youtube_player = player_ref.current.getInternalPlayer() as any;
		if (!!youtube_player && youtube_player.playVideo) {
			youtube_player.playVideo();
		}
	}

	const { onTrackProgress } = useUpdateVideoModuleStream({
		video_module_id: video_module_stream.video_module_id,
		setPercentComplete,
		setProgressTracker,
		setTotalCredit,
	});


	const onProgress = ({ playedSeconds }: {
		playedSeconds: number;
	}) => {
		if (!is_ready || !video_module_stream) {
			return;
		}
		setPlaybackPosition(playedSeconds);
		if (Math.round(playedSeconds) % 5 === 0 || Math.abs(duration - playedSeconds) < 1) {
			onTrackProgress(playedSeconds);
		}
	}

	const onPlay = (v: boolean) => {
		if (!player_ref.current || !is_ready) {
			return;
		}
		const player = player_ref.current.getInternalPlayer() as any;
		if (is_vimeo) {
			if (!!player && player.play && player.pause) {
				setIsPlaying(v);
				if (v) {
					player.play();
					manager.insertAction(ActionType.play_video, {
						video_id,
						video_module_id: video_module_stream.video_module_id,
					});
				} else {
					player.pause();
				}
			}
			return;
		}
		if (!!player && player.playVideo && player.pauseVideo) {
			setIsPlaying(v);
			if (v) {
				player.playVideo();
				manager.insertAction(ActionType.play_video, {
					video_id,
					video_module_id: video_module_stream.video_module_id,
				});
			} else {
				player.pauseVideo();
			}
		}
	}

	if (!video_module_stream) {
		return null;
	}

	return <PlayerContext.Provider value={{
		youtube_id,
		playback_position,
		setPlaybackPosition,
		is_fullscreen,
		setFullscreen,
		is_playing,
		setIsPlaying,
		is_ready,
		setIsReady,
		onPlay,
		volume,
		setVolume,
		player_ref,
		duration,
		seekTo,
		onProgress,
		video_module_stream,
		finish_threshold,
		is_ended,
		setIsEnded,
		playback_rate,
		setPlaybackRate,
		progress_tracker,
		percent_complete,
		total_credit,
	}}>{children}</PlayerContext.Provider>
}

// Hook for child components to get the app dialog context
export const usePlayer = (): Player => {
	return React.useContext(PlayerContext);
};