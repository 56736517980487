import { createStyles, Link, makeStyles, Typography } from '@material-ui/core';
import ComputerIcon from '@material-ui/icons/Computer';
import React from 'react';
import { Section } from '../../../ui/section';

type Props = {
}

export const VideoRequirements = ({ }: Props) => {
	const classes = useStyles({});
	return <Section
		id='requirements'
		title={<>Requirements to watch</>}
		icon={<ComputerIcon />}
	>

		<Typography variant='h6'>
			System requirements for HTML5 Player
		</Typography>
		<Typography variant='body1'>You can use the edropin HTML5 player to watch edropin courses on:</Typography>
		<ul>
			<li>PCs running Windows XP or later</li>
			<li>Intel-based Macs running OS X Tiger (v10.4.11) or later</li>
			<li>iPad and iPhones running iPadOS/iOS 13.0 or later</li>
		</ul>

		<Typography variant='h6'>
			Recommended Browsers
		</Typography>
		<Typography variant='body1'>The following browsers are recommended to watch edropin:</Typography>
		<ul>
			<li><Link target='_blank' href='https://www.google.com/intl/en_ca/chrome/'>Chrome (v72.0 or later)</Link></li>
			<li><Link target='_blank' href='https://www.mozilla.org/firefox/download/thanks/'>Firefox (v60.0 or later)</Link></li>
			<li><Link target='_blank' href='https://support.apple.com/en-us/HT204416'>Safari (version after March 2015)</Link></li>
			<li><Link target='_blank' href='https://www.microsoft.com/en-us/edge'>Microsoft Edge</Link></li>
			<li>IE is not recommended</li>
		</ul>

		<Typography variant='h6'>
			Internet Connection Speed Recommendations
		</Typography>
		<Typography variant='body1'>
			Below are the internet download speed recommendations per stream for playing courses through edropin.
		</Typography>
		<ul>
			<li>0.5 Megabits per second - Required broadband connection speed</li>
			<li>1.5 Megabits per second - Recommended broadband connection speed</li>
			<li>3.0 Megabits per second - Recommended for SD quality</li>
			<li>5.0 Megabits per second - Recommended for HD quality</li>
			<li>25 Megabits per second - Recommended for Ultra HD quality</li>
		</ul>
	</Section>
}

const useStyles = makeStyles((theme) => createStyles({

}));