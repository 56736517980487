export type VideoResourceModel = {
    id: number;
    created_at: string;
    video_id: number;
    link?: string;
    description?: string;
    name: string;
    deleted_at?: string;
}

export const VideoResourceFragment = `
    id
    created_at
    video_id
    link
    description
    name
    deleted_at
`;