import React from 'react';
import { VideoModel } from '../../../models/video.model';
import { BasicDialog, BasicDialogTitle } from '../../../ui/dialog';
import { VideoAddContainer } from './video-add.container';
import { VideoEditContainer } from './video-edit.container';

type Props = {
	action: 'add' | 'clone' | 'edit';
	video?: VideoModel;
	onClose: () => void;
	onFinish?: (video: VideoModel, is_new?: boolean) => void;
}

export const VideoEditorDialog = ({
	action,
	video,
	onClose,
	onFinish,
	...props
}: Props) => {
	return <BasicDialog
		open={true}
		id='dropin-editor'
		onClose={onClose}>
		<BasicDialogTitle
			title={action === 'edit' ? 'Edit video' : 'Create video'}
			onClose={onClose} />
		{action === 'edit' && !!video ? <VideoEditContainer
			{...props}
			video={video}
			onFinish={onFinish}
		/> : <VideoAddContainer
				{...props}
				video={video}
				onFinish={onFinish}
			/>}
	</BasicDialog>
}