import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { VideoQuestionModel } from '../../../../models/video-question.model';
import { BasicDialog, BasicDialogTitle } from '../../../../ui/dialog';
import { QuestionAdd } from './question-add';
import { QuestionEdit } from './question-edit';

type Props = {
	onClose: () => void;
	action?: 'edit' | 'create';
	video_id: number;
	question?: VideoQuestionModel;
	onFinish: (question: VideoQuestionModel, is_new?: boolean) => void;
}

export const QuestionEditorDialog = ({
	onClose,
	action,
	video_id,
	question,
	onFinish,
}: Props) => {
	const classes = useStyles({});

	return <BasicDialog
		open={true}
		onClose={onClose}
		id='edit-quiz'>
		<BasicDialogTitle title={action === 'edit' ? 'Edit Question' : 'Create Question'}
			onClose={onClose} />
		{question ? <QuestionEdit
			onFinish={onFinish}
			question={question}
		/> : <QuestionAdd
				onFinish={onFinish}
				video_id={video_id}
			/>}

	</BasicDialog>
}



const useStyles = makeStyles(theme => createStyles({
	player: {
		margin: theme.spacing(2, 'auto'),
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		paddingTop: '50%',
		height: 0,
		position: 'relative',
		'& .frame': {
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
			borderRadius: theme.shape.borderRadius,
			overflow: 'hidden',
		},
	}
}))