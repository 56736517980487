import { useMutation } from '@apollo/client';
import { Form, Formik, FormikHelpers } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { VideoFragment, VideoModel } from '../../../models/video.model';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { processVideoProfiles } from './process-video-profiles';
import { getSubmissionData, VideoEditorInitialValues, VideoEditorValidationSchema } from './video-creator.validation';
import { VideoEditorFormComponent } from './video-editor-form.component';


const VIDEO_EDITOR_UPDATE_MUTATION = gql`
	mutation update_video_by_pk($video_id: bigint!, $set: video_set_input!) {
		update_video_by_pk(
			pk_columns: {id: $video_id},
			_set: $set,
		) {
			${VideoFragment}
		}
	}
`;


const VIDEO_PROFILE_UPSERT_MUTATION = gql`
	mutation upsert_video_profiles($objects: [video_profile_insert_input!]!) {
		insert_video_profile(objects: $objects, on_conflict: {
			constraint: video_profile_pkey,
			update_columns: [deleted_at]
		}) {
			affected_rows
		}
	}
`;



type Props = {
	video: VideoModel;
	onFinish?: (video: VideoModel, is_new?: boolean) => void;
}

export const VideoEditContainer = ({
	video,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [updateVideo, update_result] = useMutation(VIDEO_EDITOR_UPDATE_MUTATION);
	const [upsertProfiles,] = useMutation(VIDEO_PROFILE_UPSERT_MUTATION);

	const onSubmit = async ({ video_profiles, video_tags, ...v }: any, action: FormikHelpers<any>) => {
		action.setSubmitting(false);
		try {
			const profiles = processVideoProfiles(
				video.id,
				video_profiles,
				video.video_profiles || [],
			)
			if (profiles.length > 0) {
				await upsertProfiles({
					variables: {
						objects: profiles
					},
				})
			}

			const { data } = await updateVideo({
				variables: {
					video_id: video.id,
					set: getSubmissionData(v),
				},
			});
			if (data?.update_video_by_pk) {
				// app_dialog.showSnackbar('Video saved');
				if (onFinish) {
					onFinish(data?.update_video_by_pk);
				}
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}

	return <>
		<Formik
			initialValues={VideoEditorInitialValues(video)}
			validationSchema={VideoEditorValidationSchema}
			onSubmit={onSubmit}>{props => <Form noValidate>
				{/* <FormikAutoSave /> */}
				<VideoEditorFormComponent
					{...props}
				/>
				<BasicDialogActions {...props}>
					<LoadingButton
						type='submit'
						variant='contained'
						color='primary'
						loading={update_result.loading}
						size='large'>Save Video</LoadingButton>
				</BasicDialogActions>
			</Form>}</Formik>
	</>
}