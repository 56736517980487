import { createStyles, IconButton, makeStyles } from "@material-ui/core"
import React from "react"
import CloseIcon from '@material-ui/icons/Close';
import { ImageSize, LazyImage } from "../../image-upload";
import { BasicDialog } from "../dialog";


type Props = {
    initial?: number;
    images: string[];
    onClose: () => void;
}

export const PhotoGallery = ({
    images,
    initial,
    onClose,
}: Props) => {
    const classes = useStyles({});
    const [current, setCurrent] = React.useState<string | undefined>();

    React.useEffect(() => {
        if (images.length === 0) {
            return;
        }
        if (!initial || images.length < initial) {
            setCurrent(images[0])
        }
        setCurrent(images[initial as number]);
    }, [initial, images])

    const isSelected = (image: string) => current === image;

    return <BasicDialog
        open={true}
        id='gallery'
        full_screen='xl'
        onClose={onClose}
    ><div className={classes.root}>
            <IconButton onClick={onClose}
                className='closeButton'><CloseIcon /></IconButton>
            <div className={classes.preview}>
                <LazyImage
                    image_key={current}
                    image_opt={{
                        size: ImageSize.large,
                        aspect_ratio: 'none',
                    }} />
            </div>
            {images.length > 1 ? <div className={classes.gallery}>
                {images.map((image, idx) => <div
                    className={`thumb ${isSelected(image) ? 'active' : ''}`}
                    key={idx}
                    onClick={() => setCurrent(image)}
                >
                    <LazyImage
                        image_key={image}
                        image_opt={{
                            size: ImageSize.medium,
                        }}
                    />
                </div>)}
            </div> : null}
        </div>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        position: 'fixed',
        zIndex: 9999,
        background: 'rgba(0, 0, 0, .9)',
        top: 0,
        left: 0,
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        display: 'flex',
        '& .closeButton': {
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(1),
            background: 'white',
            color: 'black',
        }
    },
    preview: {
        flex: 1,
        padding: theme.spacing(8, 0),
        textAlign: 'center',
        height: '100%',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        }
    },
    gallery: {
        width: '25%',
        overflowY: 'auto',
        padding: theme.spacing(.5),
        height: '100vh',
        '& .thumb': {
            padding: theme.spacing(.5),
            borderRadius: theme.shape.borderRadius,
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: theme.shape.borderRadius,
            },
            '&.active': {
                background: theme.palette.success.main,
            }
        }
    }
}))