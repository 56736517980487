import { ProfileFragment, ProfileModel } from "./profile.model";

export type VideoProfileModel = {
	id: number;
	profile_id: number;
	deleted_at?: string;
	profile: ProfileModel
}

export const VideoProfileFragment = `
	id 
	profile_id
	profile {
		${ProfileFragment}
	}
`;