import { Box, Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";
import React from "react";
import * as yup from 'yup';
import { useUpdateByPk } from "../../apollo/networking/use-update-by-pk";
import { useUser } from "../../auth/use-user";
import { EdContainer } from "../../ui/ed-container";
import { LoadingButton } from "../../ui/loading-button";
import { GuestLayout } from "../signin/guest-layout";

type Props = {

}

const validation = yup.object().shape({
    disclosure: yup.boolean()
        .required("Must be accepted")
        .oneOf([true], "Must be accepted"),
    all_therapeutic: yup.boolean()
        .required("Must be accepted")
        .oneOf([true], "Must be accepted"),
    scientific: yup.boolean()
        .required("Must be accepted")
        .oneOf([true], "Must be accepted"),
    disqualify: yup.boolean()
        .required("Must be accepted")
        .oneOf([true], "Must be accepted"),
})

export const CreatorOnboard = (props: Props) => {
    const classes = useStyles({});
    const { user_id } = useUser();
    const { onUpdate, loading } = useUpdateByPk({
        entity_name: 'user',
        fragment: `
            id
            is_creator
        `,
        pk_columns: [{
            name: 'id',
            type: 'String',
            value: user_id,
        }]
    })

    const onSubmit = () => {
        onUpdate({
            is_creator: true,
        })
    }

    return <GuestLayout>
        <EdContainer>
            <Typography variant='h4'>
                <strong>
                    Edropin Teacher Agreement
                </strong>
            </Typography>
            <Typography variant='body1'>
                In order to ensure compliance with AGD PACE and to allow participants to earn CE for watching your
                courses, the content and delivery of the course must adhere to the following guidelines.
            </Typography>

            <Box py={4}>
                <Typography gutterBottom variant='h6'>Guiding policy</Typography>
                <Typography paragraph variant='body1'>
                    All individuals in a position to influence the content of a course to be offered for Edropin must disclose any relevant financial or nonfinancial relationship(s) that might affect independent involvement in the proposed CE course.
                    Any speaker or planning committee member who refuses to disclose financial or nonfinancial relationships will be disqualified from participating in the planning and implementation of the course.
                    The CE disclosure process requires completion and submission of the attached disclosure forms indicating no conflicts of interest or indicating relevant financial or non-financial relationships that could be perceived as a conflict of interest.
                </Typography>
            </Box>
            <Formik initialValues={{
                test: '',
                disclosure: false,
                all_therapeutic: false,
                scientific: false,
                disqualify: false,
            }}
                onSubmit={onSubmit}
                validationSchema={validation}
            >{({
                isValid,
                errors,
            }) => <Form>
                    <Box py={4}>
                        <Typography gutterBottom variant='h6'>Please agree to the following statements</Typography>
                        <Box pb={1}>
                            <Field
                                name='disclosure'
                                type="checkbox"
                                color='inherit'
                                className={classes.checkbox}
                                component={CheckboxWithLabel}
                                Label={{
                                    label: (<>
                                        I will disclose relevant financial and nonfinancial relationship(s) for
                                        any courses that I add to Edropin Studio.
				            </>),
                                }}
                            />
                        </Box>
                        <Box pb={1}>
                            <Field
                                name='all_therapeutic'
                                type="checkbox"
                                color='inherit'
                                className={classes.checkbox}
                                component={CheckboxWithLabel}
                                Label={{
                                    label: (<>
                                        Wherever possible, I will offer a balanced view of all available therapeutic options and
                                        try to use generic names rather than specific products/services. If I do mention a product/service I will disclose
                                        any financial or non-financial relationship(s).
				            </>),
                                }}
                            />
                        </Box>
                        <Box pb={1}>
                            <Field
                                name='scientific'
                                type="checkbox"
                                color='inherit'
                                className={classes.checkbox}
                                component={CheckboxWithLabel}
                                Label={{
                                    label: (<>
                                        I will link to relevant research/scientifc evidence for any courses that I add
                                        to Edropin Studio in order to provide participants with tangible resources that support the
                                        content of the course.
				            </>),
                                }}
                            />
                        </Box>
                        <Box pb={1}>

                            <Field
                                name='disqualify'
                                type="checkbox"
                                color='inherit'
                                className={classes.checkbox}
                                component={CheckboxWithLabel}
                                Label={{
                                    label: (<>
                                        I understand that if any financial or non-financial relationship(s) are discovered that haven't been
                                        disclosed, my course may be removed from Edropin until a peer-review is conducted. This removal may be
                                        appealed but the ultimate decision will be made by the Edropin CE committee.
				            </>),
                                }}
                            />
                        </Box>
                    </Box>
                    {!isValid ? <Typography variant='body2' color='primary'>Please accept all terms to continue</Typography> : null}
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        fullWidth
                        loading={loading}
                        color='primary'
                        size='large'>Start Teaching</LoadingButton>
                </Form>}
            </Formik>
        </EdContainer>
    </GuestLayout>
}

const useStyles = makeStyles(theme => createStyles({
    checkbox: {
        alignSelf: 'center',
    }
}))