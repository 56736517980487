import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import clsx from 'clsx';


const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1.
        partialVisibilityGutter: 40,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2,// optional, default to 1.
        partialVisibilityGutter: 40,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        partialVisibilityGutter: 40,
    }
};


type Props<T extends { id: string | number }> = {
    items: T[];
    center_mode?: boolean;
    partially_visible?: boolean;
    no_infinite?: boolean;
    renderItem: (item: T, idx: number) => React.ReactNode;
    initial_item?: number;
    class_name?: string;
}

export const EdSlider = <T extends { id: string | number }>({
    items,
    center_mode,
    partially_visible,
    renderItem,
    no_infinite,
    initial_item,
    class_name,
}: Props<T>) => {
    const classes = useStyles({});

    const carousel_ref = React.useRef<Carousel>(null);

    React.useEffect(() => {
        if (!carousel_ref.current) {
            return;
        }
        if (!initial_item) {
            return;
        }
        carousel_ref.current.goToSlide(initial_item);
    }, [initial_item, carousel_ref])

    return <Carousel
        ref={carousel_ref}
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        infinite={!no_infinite}
        arrows={isMobile || isTablet ? false : true}
        autoPlay={false}
        keyBoardControl={true}

        transitionDuration={700}
        partialVisible={partially_visible}
        centerMode={center_mode}
        containerClass={clsx(classes.container, class_name)}
    >
        {items.map((video, idx) => <div
            style={{
                textAlign: 'left',
                marginLeft: 4,
                marginRight: 4,
            }}
            key={video.id}>
            {renderItem(video, idx)}
        </div>)}

    </Carousel>;
}


const useStyles = makeStyles(theme => createStyles({
    container: {
        marginLeft: -4,
        marginRight: -4,
        '& .react-multiple-carousel__arrow--left': {
            left: 0,
            top: '20%',
            zIndex: 99,
        },
        '& .react-multiple-carousel__arrow--right': {
            right: 5,
            top: '20%',
            zIndex: 99,
        }
    }
}))