import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { VideoQuestionModel } from '../../../../models/video-question.model';

export const QuestionInitialValues = (
	question?: VideoQuestionModel,
) => ({
	prompt: question?.prompt || '',
	options: question?.options || [{
		id: uuidv4(),
		label: '',
		is_correct: false,
	}],
	explanation: question?.explanation || '',
});

export const EditQuestionValidationSchema = yup.object().shape({
	prompt: yup.string().required('Prompt is required'),
	options: yup.array().of(yup.object({
		label: yup.string().required('Option label is required')
	})).min(1, 'At least one option is required'),
})

