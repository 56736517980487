import { gql, useMutation } from '@apollo/client';
import { Box, Button, createStyles, makeStyles, Typography } from '@material-ui/core';
import pluralize from 'pluralize';
import React from 'react';
import { useAppDialog } from '../app-dialog';
import { useUser } from '../auth/use-user';
import { EmptyListComponent } from '../ui/empty-list.component';
import { LoadingDots } from '../ui/loading-dots';
import { ChatMessageReaction } from './chat-message-reaction.model';
import { ChatMessageFragment, ChatMessageModel } from './chat-message.model';
import { MessageInsert } from './insert-message';
import { InsertReply } from './insert-reply';
import { Message } from './message';
import { useListMessages } from './use-list-messages';
import { PostDialog } from './post/post-dialog';

const MUTATION_INSER_MESSAGE_REACTION = gql`
    mutation toggle_reaction($object: chat_message_reaction_insert_input!) {
        insert_chat_message_reaction_one(object: $object, on_conflict: {
            constraint: chat_message_reaction_pkey,
            update_columns: [status]
        }) {
            chat_message {
                ${ChatMessageFragment}
            }
        }
    }
`;

type Props = {
	chat_id: number;
}

export const ChatMessages = ({
	chat_id,
}: Props) => {
	const classes = useStyles({});
	const { user: { id } } = useUser();
	const app_dialog = useAppDialog();
	const [highFive] = useMutation(MUTATION_INSER_MESSAGE_REACTION);
	const [add_reply, setAddReply] = React.useState<{ [id: number]: boolean }>({});
	const [show_post, setShowPost] = React.useState<number | null>(null);

	const {
		chat_messages,
		has_more,
		has_new_comments,
		loading,
		loadNewer,
		updateMessage,
		updating,
		insertMessage,
		inserting,
	} = useListMessages({
		chat_id,
	})

	const onHighFive = async (chat_message: ChatMessageModel, status: ChatMessageReaction) => {
		try {
			await highFive({
				variables: {
					object: {
						chat_message_id: chat_message.id,
						status,
					},
				}
			});
		} catch (e) {
			app_dialog.showError(e);
		}
	}

	const insertReply = async (parent_message_id: number, message: any) => {
		const result = await insertMessage({
			...message,
			parent_message_id,
		})
		if (result) {
			setAddReply(s => ({
				...s,
				[parent_message_id]: false,
			}));
		}
		return result;
	}

	return <>
		{!!show_post ? <PostDialog
			post_id={show_post}
			onClose={() => setShowPost(null)}
		/> : null}
		<MessageInsert insertMessage={insertMessage} inserting={inserting} />
		{has_new_comments ? <Box p={.5} textAlign='center'>
			<Button variant='contained' onClick={loadNewer}>See New Comments</Button>
		</Box> : null}
		{chat_messages.map(item => <div
			key={item.id}
			className={classes.message}>
			<Message
				user_id={id}
				onUpdate={updateMessage}
				updating={updating}
				onReply={() => setAddReply(s => ({
					...s,
					[item.id]: true,
				}))}
				chat_message_reactions={item.chat_message_reactions}
				onHighFive={(item, status) => onHighFive(item, status)}
				upvote_tally={item.chat_message_reactions_tally?.total_reactions || 0}
				can_reply={true}
				item={item}>
				{add_reply[item.id] ? <InsertReply
					parent_name={item.user_profile.name}
					insertMessage={message => insertReply(item.id, message)}
					onCancel={() => setAddReply(s => ({
						...s,
						[item.id]: false,
					}))}
					inserting={inserting} /> : null}
				{item.replies && item.replies.length > 0 ? <div className={classes.replies}>
					{!!item.reply_tally?.total_replies
						? <Typography variant='overline'>{item.reply_tally.total_replies} {pluralize('reply', item.reply_tally.total_replies)}</Typography>
						: null}
					{item.replies.slice(0, 2).map(reply => <Message
						item={reply}
						user_id={id}
						onUpdate={updateMessage}
						upvote_tally={reply.chat_message_reactions_tally?.total_reactions || 0}
						chat_message_reactions={reply.chat_message_reactions}
						onHighFive={onHighFive}
						key={reply.id}
					/>)}
					{item.replies.length === 3 ? <Button
						className='loadmore'
						onClick={() => setShowPost(item.id)}
						fullWidth>Load older replies...</Button> : null}
				</div> : null}
			</Message>
		</div>)}
		{loading ? <LoadingDots /> : null}
		{chat_messages.length === 0 && !loading ? <EmptyListComponent message='Be the first to post a message' /> : null}
	</>
}

const useStyles = makeStyles((theme) => createStyles({
	message: {
		borderBottom: `2px solid ${theme.palette.divider}`,
		'&:last-child': {
			borderBottom: 0,
		},
	},
	replies: {
		paddingLeft: theme.spacing(2),
		marginLeft: theme.spacing(3.5),
		borderLeft: `2px solid ${theme.palette.divider}`,
		'& .loadmore': {
			margin: theme.spacing(1, 0),
		},
	}
}));