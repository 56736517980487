import { createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import { YtVideoItem } from "../curate-yt/yt-video-item";
import { YtVideoModel } from "../curate-yt/yt-video.model";


type Props = {
    videos: YtVideoModel[];
    current_idx: number;
    approval: {[id: string]: boolean};
    setCurrentVideoIdx: (idx: number) => void;
}

export const CurationSidebar = ({
    videos,
    approval,
    current_idx,
    setCurrentVideoIdx,
}: Props) => {
    const classes = useStyles({});
    return <div className={classes.sidebar}>
        {videos.map((item, idx) => <YtVideoItem 
            item={item}
            key={item.id.videoId}
            approved={approval[item.id.videoId] === undefined ? null : approval[item.id.videoId]}
            current={idx === current_idx}
            onClick={() => setCurrentVideoIdx(idx)}
        />)}
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    sidebar: {

    }
}))