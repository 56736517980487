import { createStyles, Link, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { VideoResourceModel } from "../../../models/video-resource-item";
import { Markdown } from "../../../ui/markdown";

type Props = {
    item: VideoResourceModel;
}

export const ResourceItem = ({
    item,
}: Props) => {
    const classes = useStyles({});

    return <div className={classes.item}>
        <Typography variant='overline'>{moment(item.created_at).fromNow()}</Typography>
        {item.link ? <Link target='_blank' href={item.link}>
            <Typography variant='h6'>{item.name} <span role='img'>🔗</span></Typography>
        </Link> : <Typography variant='h6'>{item.name}</Typography>}
        <Markdown text={item.description} />
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        padding: theme.spacing(2),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }
    }
}))