export type VideoEvaluationPublicModel = {
    id: number;
    created_at: string;
    updated_at: string;
    video_id: number;
    user_id: string;
    overall_course: number;
    comments?: string;
    user_name: string;
    user_profile_image_key: string;
}

export const VideoEvaluationPublicFragment = `
    id
    created_at
    updated_at
    video_id
    user_id
    overall_course
    comments
    user_name
    user_profile_image_key
`;
