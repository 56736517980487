import { useMutation } from '@apollo/client';
import { DialogContent } from '@material-ui/core';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../../app-dialog';
import { VideoResourceFragment, VideoResourceModel } from '../../../../models/video-resource.model';
import { DevFormValues } from '../../../../ui/dev-form-values';
import { BasicDialogActions } from '../../../../ui/dialog';
import { LoadingButton } from '../../../../ui/loading-button';
import { EditVideoResourceValidationSchema, sanitizeVideoResourceData, VideoResourceInitialValues } from './video-resource-edit-validation';
import { VideoResourceFormComponent } from './video-resource-form.component';

type Props = {
	resource: VideoResourceModel;
	onFinish: (model: VideoResourceModel, is_new?: boolean) => void;
}

const EDIT_VIDEO_MODULE_MUTATION = gql`
	mutation update_licensure(
		$vr_id: bigint!,
		$set: video_resource_set_input!) {
		update_video_resource_by_pk(
			pk_columns: {id: $vr_id},
			_set: $set,
		) {
			${VideoResourceFragment}
		}
	}
`;

export const EditVideoResourceContainer = ({
	resource,
	onFinish,
}: Props) => {
	const app_dialog = useAppDialog();
	const [updateModule, update_result] = useMutation(EDIT_VIDEO_MODULE_MUTATION);
	const onSubmit = async (values: any) => {
		try {
			const { data } = await updateModule({
				variables: {
					vr_id: resource.id,
					set: sanitizeVideoResourceData(values),
				},
			});
			if (data && data.update_video_resource_by_pk) {
				app_dialog.showSnackbar('Resource saved');
				onFinish(data.update_video_resource_by_pk, false);
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}
	return <Formik
		initialValues={VideoResourceInitialValues(resource)}
		validationSchema={EditVideoResourceValidationSchema}
		onSubmit={onSubmit}>{props => <Form noValidate>
			<DialogContent>
				<VideoResourceFormComponent {...props} />
				<DevFormValues {...props} />
			</DialogContent>
			<BasicDialogActions>
				<LoadingButton
					loading={update_result.loading}
					type='submit'
					variant='contained'
					color='secondary'
				>Save</LoadingButton>
			</BasicDialogActions>
		</Form >}
	</Formik >
}