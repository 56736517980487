import { LikeStatus } from "./like.model";

export type VideoEvaluationModel = {
    id: number;
    video_id: number;
    user_id: string;
    data: any;
    overall_course: number;
    overall_instructor: number;
    overall_expectations: number;
    comments?: string;
    liked_at?: string;
    reviewed_at?: string;
    bookmarked_at?: string;
    like_status: LikeStatus;
}

export const VideoEvaluationFragment = `
    id
    video_id
    user_id
    data
    overall_course
    overall_instructor
    overall_expectations
    comments
    liked_at
    reviewed_at
    bookmarked_at
    like_status
`;
