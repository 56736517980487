import { CircularProgress, createStyles, FormHelperText, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/CloseOutlined';
import React from 'react';
import { useDebounceValue } from '../utils/use-debounce-value';
import SearchIcon from '@material-ui/icons/Search';

type Props = {
	query: string;
	placeholder?: string;
	helper_text?: string;
	onSearch: (query: string) => void;
	onClearSearch: () => void;
	ilike?: boolean;
	loading?: boolean;
}

export const SearchBar = ({
	query,
	placeholder,
	helper_text = 'Type in at least 3 characters to search...',
	onSearch,
	onClearSearch,
	loading,
	ilike
}: Props) => {
	const classes = useStyles({});
	const [value, setValue] = React.useState(query);
	const debounced_value = useDebounceValue(value);

	React.useEffect(() => {
		onSearch(debounced_value);
	}, [debounced_value]);

	React.useEffect(() => {
		if (query !== value) {
			setValue(query);
		}
	}, [query])

	return <div style={{ flex: 1 }}>
		<div className={classes.search}>
			<TextField
				placeholder={placeholder || 'Type to search...'}
				fullWidth
				className='bar'
				margin='dense'
				variant='outlined'
				onChange={e => setValue(e.target.value)}
				value={value}
				InputProps={{
					startAdornment: <InputAdornment position='start'>
						<SearchIcon />
					</InputAdornment>,
					endAdornment: <InputAdornment position="end">
						{loading ? <CircularProgress /> :
							<>{query.length > 0 ? <IconButton
								aria-label="clear search"
								onClick={onClearSearch}
								onMouseDown={onClearSearch}
								size='small'
							>
								<ClearIcon fontSize='small' />
							</IconButton> : null}</>}
					</InputAdornment>
				}}
			/>
		</div>
		{ilike ? null : <FormHelperText error={query.length > 0 && query.length < 3}>{helper_text}</FormHelperText>}
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	search: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		'& .bar': {
			flex: 1,
			margin: 0,
			marginRight: theme.spacing(1),
		}
	},
}));