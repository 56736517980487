import { Button, createStyles, FormHelperText, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import MDEditor from '@uiw/react-md-editor';
import { ErrorMessage, FieldProps } from "formik";
import React from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAppDialog } from "../app-dialog";
import { getImageUrl, Image, ImageSize, ImageUpload } from "../image-upload";
import ImageIcon from '@material-ui/icons/Photo';

interface Props extends FieldProps {
    label?: string;
}

export const MarkdownEditorField = ({
    label,
    form: {
        setFieldValue,
    },
    field: {
        name,
        value,
    }
}: Props) => {
    const [image_upload, setImageUpload] = React.useState(false);

    const handleChange = (value: any) => {
        setFieldValue(name, value);
    };

    const onChangeImage = (images: Image[]) => {
        if (images.length === 0 || !image_upload) {
            return;
        }
        setFieldValue(name, `${value}\n![image](${getImageUrl(images[0].key, {
            size: ImageSize.large,
            aspect_ratio: 'none',
        })})`)
        setImageUpload(false);
    }


    return <>
        {image_upload ? <ImageUpload
            onSelect={onChangeImage}
            handleClose={() => setImageUpload(false)}
        /> : null}
        {label ? <Typography gutterBottom variant='body2'>{label}</Typography> : null}
        <Alert severity='info'
            style={{
                marginBottom: 2,
            }}>
            <AlertTitle>Add an image</AlertTitle>
            <Button
                variant='contained'
                size='small'
                color='secondary'
                startIcon={<ImageIcon />}
                onClick={() => setImageUpload(true)}>Insert an Image</Button>
        </Alert>
        <MDEditor
            value={value || ''}
            preview='edit'
            onChange={handleChange}
        />
        <ErrorMessage
            name={name} render={error => <FormHelperText
                error>{error}</FormHelperText>} />
    </>
}
