import { createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import { useList } from "../../../apollo/networking";
import { VideoResourceFragment, VideoResourceModel } from "../../../models/video-resource-item";
import { EmptyListComponent } from "../../../ui/empty-list.component";
import { LoadingDots } from "../../../ui/loading-dots";
import { ResourceItem } from "./resource-item";

type Props = {
    video_id: number;
}

export const VideoResources = ({
    video_id
}: Props) => {
    const classes = useStyles({});
    const {
        items,
        loading,
    } = useList<VideoResourceModel>({
        entity_name: 'video_resource',
        fragment: VideoResourceFragment,
        query_inner: `where: {
            video_id: {_eq: $video_id},
        }, order_by: {id: asc}`,
        clause_outer: `$video_id: bigint!`,
        variables: {
            video_id,
        }
    })
    return <>
        {!loading && items.length === 0 ? <EmptyListComponent
            message='No resources found.' /> : null}
        {items.map(item => <ResourceItem
            item={item}
            key={item.id}
        />)}
        {loading ? <LoadingDots /> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({

}))