import { createStyles, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import AnalyticsIcon from '@material-ui/icons/BarChart';
import EditIcon from '@material-ui/icons/Edit';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import ShareIcon from '@material-ui/icons/Share';
import moment from "moment";
import React from "react";
import { useAppDialog } from "../../app-dialog";
import { environment } from "../../environment";
import { VideoPublishStatus, VideoPublishStatusOptions } from "../../models/video-publish-status";
import { VideoModel } from "../../models/video.model";
import { LoadingButton } from "../../ui/loading-button";
import { UnstyledLink } from "../../ui/unstyled-link";
import { VideoShare } from "../video-play/video-share";

type Props = {
    video: VideoModel;
    onEdit: () => void;
    onUpdate: (v: any) => void;
    updating?: boolean;
}

export const VideoBar = ({
    video,
    onEdit,
    onUpdate,
    updating,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const [show_share, setShowShare] = React.useState(false);

    const submitForReview = () => {
        onUpdate({
            in_review_at: 'now()',
        })
    }

    const _onEdit = () => {
        if (video.in_review_at) {
            app_dialog.showDialog({
                title: 'Remove from review?',
                message: 'This will remove the video from the Review queue.',
                buttons: [{
                    label: 'Cancel',
                }, {
                    label: 'Edit Video',
                    color: 'primary',
                    onClick: () => {
                        onUpdate({
                            in_review_at: null,
                        });
                        onEdit();
                    }
                }]
            })
        } else {
            onEdit();
        }
    }

    const status = React.useMemo(() => {
        if (video.in_review_at) {
            return 'In Review';
        }
        return VideoPublishStatusOptions.find(o => o.id === video.publish_status)?.label;
    }, [video])

    return <div className={classes.bar}>
        {show_share ? <VideoShare
            onClose={() => setShowShare(false)}
            name={video.name}
            video_id={video.id}
            is_live={video.publish_status === VideoPublishStatus.live}
        /> : null}
        <div style={{ flex: 1 }}>
            <Typography ><strong>{status}</strong></Typography>
            <Typography variant='body2' color='textSecondary'>Last updated {moment(video.updated_at).fromNow()}</Typography>
        </div>
        <Tooltip title='Play video'>
            <IconButton target='_blank'
                href={`/v/${video.id}/play`}>
                <PlayIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title='Share this video'>
            <IconButton onClick={() => setShowShare(true)}>
                <ShareIcon />
            </IconButton>
        </Tooltip>
        <Tooltip title='Video analytics'>
            <UnstyledLink to={`/v/${video.id}/analytics`}>
                <IconButton>
                    <AnalyticsIcon />
                </IconButton>
            </UnstyledLink>
        </Tooltip>
        <Tooltip title='Edit video details'>
            <IconButton onClick={_onEdit}>
                <EditIcon />
            </IconButton>
        </Tooltip>
        <LoadingButton
            loading={updating}
            variant='contained'
            onClick={submitForReview}
            disabled={video.publish_status === VideoPublishStatus.live || !!video.in_review_at}
            color='primary'>{video.in_review_at ? 'In Review' : 'Submit for Review'}</LoadingButton>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    bar: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1),
        backgroundColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
    }
}))