import { createStyles, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { UnstyledLink } from "../../ui/unstyled-link";
import { YtVideoModel } from "./yt-video.model";
import clsx from 'clsx';
import ApprovedIcon from '@material-ui/icons/CheckCircle';
import RejectIcon from '@material-ui/icons/Cancel';
import moment from "moment";

type Props = {
    item: YtVideoModel;
    onClick: () => void;
    approved?: boolean | null;
    current?: boolean;
}

export const YtVideoItem = ({
    item,
    onClick,
    approved,
    current,
}: Props) => {
    const classes = useStyles({});
    const thumbnail = React.useMemo(() => {
        const { thumbnails } = item.snippet;
        const sizes = Object.keys(thumbnails);
        if (sizes.length === 0) {
            return null;
        }
        if (sizes.indexOf('high')) {
            return thumbnails.high.url;
        }
        if (sizes.indexOf('medium')) {
            return thumbnails.medium.url;
        }
        if (sizes.indexOf('small')) {
            return thumbnails.small.url;
        }
    }, [item.snippet.thumbnails])
    return <div className={clsx(
        classes.item, {
        [classes.approved]: approved === true,
        [classes.rejected]: approved === false,
        [classes.current]: !!current,
    })} onClick={onClick}>
        <div className='thumbnail'>
            {thumbnail ? <img src={thumbnail} /> : null}
            {approved === true ? <ApprovedIcon style={{color: 'green'}} /> : null}
            {approved === false ? <RejectIcon style={{color: 'red'}} /> : null}
        </div>
        <div className={classes.content}>
            <Typography variant='body1'>
                <strong>{item.snippet.title}</strong>
            </Typography>
            <Typography variant='body2'>
                {moment(item.snippet.publishTime).format('LLL')}
            </Typography>
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    item: {
        cursor: 'pointer',
        marginBottom: theme.spacing(2),
        '& .thumbnail': {
            height: 0,
            paddingTop: '56.5%',
            position: 'relative',
            background: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            '& img': {
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',

            },
            '& svg': {
                position: 'absolute',
                left: 5,
                top: 5,
                width: 30,
                height: 30,
            }
        },
        '&:hover': {
            '& .thumbnail': {
                boxShadow: theme.shadows[4],
            }
        },
    },
    content: {
        padding: theme.spacing(1),
    },
    approved: {
        '& .thumbnail': {
            border: `solid 5px ${theme.palette.success.main}`
        }
    },
    rejected: {
        '& .thumbnail': {
            border: `solid 5px ${theme.palette.primary.main}`
        }
    },
    current: {
        background: theme.palette.success.light,
    },
}))