import * as yup from 'yup';
import { VideoModuleModel } from '../../../models/video-module.model';

export const VideoModuleInitialValues = (
	module?: VideoModuleModel,
) => ({
	name: module?.name || '',
	duration: module?.duration || 0,
	finish_threshold: module?.finish_threshold || 90,
	youtube_url: module?.youtube_url || '',
});

export const EditVideoModuleValidationSchema = yup.object().shape({
	name: yup.string().required('Name is required. Make it something informative'),
	youtube_url: yup.string().required('Please provide a youtube url'),
	duration: yup.number().required('Please input a duration'),
	finish_threshold: yup.number().required('Percentage required for completion'),
})

export const sanitizeVideoModuleData = ({
	...values
}: any) => {
	return {
		...values,
	};
}

