import { Button, createStyles, makeStyles } from "@material-ui/core"
import React from "react"
import { UnstyledLink } from "../../ui/unstyled-link";
import VideoIcon from '@material-ui/icons/PlayArrow';
import NextIcon from '@material-ui/icons/ChevronRight';
import { VideoModuleModel } from "../../models/video-module.model";

type Props = {
    next_checkpoint?: VideoModuleModel;
}

export const VideoNextButton = ({
    next_checkpoint,
}: Props) => {
    const classes = useStyles({});

    if (!next_checkpoint) {
        return <UnstyledLink to={`/v`}>
            <Button variant='contained' color='primary' size='large'
                startIcon={<VideoIcon />}>Explore Videos</Button>
        </UnstyledLink>
    }
    return <UnstyledLink to={`/v/${next_checkpoint.video_id}?ck=${next_checkpoint.id}`}>
        <Button variant='contained' color='primary' size='large' endIcon={<NextIcon />}>
            Next Module
        </Button>
    </UnstyledLink>
}

const useStyles = makeStyles(theme => createStyles({

}))