import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useAppDialog } from '../../../app-dialog';
import { ProfileFragment, ProfileModel } from '../../../models/profile.model';
import { BasicDialogActions } from '../../../ui/dialog';
import { LoadingButton } from '../../../ui/loading-button';
import { getSubmissionData, ProfileEditorInitialValues, ProfileEditorValidationSchema } from './profile-creator.validation';
import { ProfileEditorFormComponent } from './profile-editor-form.component';


const PROFILE_EDITOR_INSERT_MUTATION = gql`
	mutation insert_profile($object: profile_insert_input!) {
		insert_profile_one(object: $object) {
			${ProfileFragment}
		}
	}
`;

type Props = {
	profile?: ProfileModel;
	onFinish?: (profile: ProfileModel, is_new?: boolean) => void;
}

export const ProfileAddContainer = ({
	profile,
	onFinish,
	...props
}: Props) => {
	const app_dialog = useAppDialog();
	const [insertProfile, insert_result] = useMutation(
		PROFILE_EDITOR_INSERT_MUTATION
	);

	const onSubmit = async ({ ...v }: any) => {
		try {
			const { data } = await insertProfile({
				variables: {
					object: {
						...getSubmissionData(v),
					}
				},
			});
			if (data?.insert_profile_one) {
				app_dialog.showSnackbar('Profile created');
				if (onFinish) {
					onFinish(data.insert_profile_one, true);
				}
			}
		} catch (e) {
			app_dialog.showError(e);
		}
	}

	return <Formik
		initialValues={ProfileEditorInitialValues(
			profile,
		)}
		validationSchema={ProfileEditorValidationSchema}
		onSubmit={onSubmit}>{form_props => <Form noValidate>
			<ProfileEditorFormComponent
				{...props}
				{...form_props}
			/>
			<BasicDialogActions {...form_props}>
				<LoadingButton
					type='submit'
					variant='contained'
					color='primary'
					loading={insert_result.loading}
					size='large'>Create Profile</LoadingButton>
			</BasicDialogActions>
		</Form>}</Formik>
}