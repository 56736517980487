import { Box, Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import EmailIcon from '@material-ui/icons/Email';
import WelcomeIcon from '@material-ui/icons/EmojiPeople';
import { RouteComponentProps } from "@reach/router";
import qs from 'query-string';
import React from "react";
import { useAppDialog } from "../../app-dialog";
import firebase from '../../firebase';
import { EdContainer } from "../../ui/ed-container";
import { authErrors } from "./firebase-auth-errors";
import { GuestLayout } from "./guest-layout";
import { SigninForm } from "./signin-form";
import { SignupForm } from "./signup-form";

type Props = {

}

export const SigninPage = ({
    location,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const params = qs.parse(location?.search || '');
    const napp = params?.napp;
    const app_dialog = useAppDialog();
    const [is_signin, setIsSignin] = React.useState<'signin' | 'signup' | false>((params?.state as any) || false);
    const [loading, setLoading] = React.useState(false);

    const onSubmit = async (values: { email: string; password: string; }) => {
        setLoading(true);
        try {
            if (is_signin === 'signin') {
                await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
            } else {
                await firebase.auth().createUserWithEmailAndPassword(values.email, values.password);
            }
        } catch (e) {
            const code = e.code.split('/');
            let message = 'Something went wrong on our end. Please contact help@edropin.com if this continues';
            if (code.length === 2) {
                const error = authErrors[code[1]];
                if (error) {
                    message = error;
                }
            }

            app_dialog.showDialog({
                title: is_signin === 'signin' ? 'Signin failed' : 'Failed to create account',
                message,
                buttons: [],
            })

        } finally {
            setLoading(false);
        }
    }

    return <GuestLayout>
        <EdContainer max_width='md'>
            <div className={classes.content}>
                <Typography variant='h2'><strong className='blue'>Edropin</strong> Studio</Typography>
                <Typography variant='h6'>Where your lessons change the dental world</Typography>
            </div>
            {is_signin === false ? <div className={classes.socialLogin}>
                <Button
                    className='email'
                    size='large'
                    onClick={() => setIsSignin('signin')}
                    startIcon={<EmailIcon />}
                    fullWidth variant='contained'>Login with Email</Button>
                <Button
                    color='primary'
                    size='large'
                    onClick={() => setIsSignin('signup')}
                    startIcon={<WelcomeIcon />}
                    fullWidth variant='contained'>Create an account</Button>
            </div> : <Box pt={2} pb={4} maxWidth={500} margin='0 auto'>
                    <Typography
                        gutterBottom
                        align='center'
                        variant='h6'>
                        {is_signin === 'signin' ? 'Log in with email' : 'Create a new account'}</Typography>
                    {is_signin === 'signin' ? <SigninForm onSubmit={onSubmit}
                        loading={loading} /> : <SignupForm onSubmit={onSubmit}
                            loading={loading} />}
                    <Box pt={2}>
                        <Button color='secondary'
                            onClick={() => setIsSignin(s => s === 'signin' ? 'signup' : 'signin')}
                            fullWidth>
                            {is_signin === 'signin' ? 'Don\'t have an account? Sign Up' : 'Have an account? Sign in instead'}
                        </Button>
                    </Box>
                </Box>}
        </EdContainer>
    </GuestLayout>
}


const useStyles = makeStyles(theme => createStyles({
    content: {
        padding: theme.spacing(4, 0),
        textAlign: 'center',
        '& .blue': {
            color: '#145E8B',
        }
    },
    socialLogin: {
        maxWidth: 500,
        margin: '0 auto',
        paddingBottom: theme.spacing(4),
        '& button': {
            margin: theme.spacing(2, 0),
            padding: theme.spacing(1.5, 0),
            color: 'white',
        },
        '& .email': {
            background: theme.palette.grey[900],
        },
    },
    download: {
        padding: theme.spacing(2, 0),
        maxWidth: 500,
        textAlign: 'center',
        margin: '0 auto',
        '& img': {
            maxWidth: "100%"
        }
    }
}))