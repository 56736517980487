import { Grid } from "@material-ui/core";
import ProfileIcon from '@material-ui/icons/People';
import React from "react";
import { VideoProfileModel } from "../models/video-profile.model";
import { Section } from "../ui/section";
import { ProfileCard } from "./profile-card";

type Props = {
    title: string;
    subtitle?: string;
    profiles: VideoProfileModel[];
}

export const Profiles = ({
    title,
    subtitle,
    profiles,
}: Props) => {
    if ((profiles || []).length === 0) {
        return null;
    }
    return <Section
        icon={<ProfileIcon />}
        title={title}
        subtitle={subtitle}>
        <Grid container spacing={2}>
            {profiles.map(profile =>
                <Grid key={profile.id}
                    item
                    sm={12}
                    md={profiles.length > 1 ? 6 : 12}>
                    <ProfileCard
                        {...profile}
                    />
                </Grid>)}
        </Grid>
    </Section>
}
