
export type VideoModuleStreamModel = {
	id: number;
	created_at: string;
	updated_at: string;
	video_module_id: number;
	playback_position: number;
	progress_tracker: string;
	percent_complete: number;
	total_credit: number;
	completed_at?: string;
}

export const VideoModuleStreamFragment = `
	id
	created_at
	updated_at
	video_module_id
	playback_position
	progress_tracker
	percent_complete
	total_credit
	completed_at
`;