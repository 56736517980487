import * as yup from 'yup';
import { VideoEvaluationModel } from '../../../models/video-evaluation.model';

export const VideoEvaluationValues = (model?: VideoEvaluationModel) => ({
    overall_course: model?.overall_course || 0,
    overall_instructor: model?.overall_instructor || 0,
    overall_expectations: model?.overall_expectations || 0,
    comments: model?.comments || '',
    data: {
        administration: model?.data.administration || 0,
        objective: model?.data.objective || 0,
        materials: model?.data.materials || 0,
        instructor_knowledge: model?.data.instructor_knowledge || 0,
        instructor_interest: model?.data.instructor_interest || 0,
        audio_visual: model?.data.audio_visual || 0,
        handout_materials: model?.data.handout_materials || 0,
        user_interface: model?.data.user_interface || 0,
        other_topics: model?.data.other_topics || '',
    },
});

export const VideoEvaluationValidation = yup.object().shape({
    overall_course: yup.number()
        .min(1, 'Please rate the overall course'),
    overall_instructor: yup.number()
        .min(1, 'Please rate the overall instructor'),
    overall_expectations: yup.number()
        .min(1, 'Please rate how this course met your expectations'),
    comments: yup.string().optional(),
    data: yup.object().optional(),
})