import { gql } from '@apollo/client';
import { Box, Button, List, Typography } from '@material-ui/core';
import { Alert, AlertTitle, Pagination } from '@material-ui/lab';
import { RouteComponentProps, useNavigate } from '@reach/router';
import React from 'react';
import { AppLayout } from '../../layout/app-layout';
import { ProfileFragment, ProfileModel } from '../../models/profile.model';
import { EmptyListComponent } from '../../ui/empty-list.component';
import { ErrorComponent } from '../../ui/errors';
import { LoadingDots } from '../../ui/loading-dots';
import { PageHeader } from '../../ui/page-header';
import { SearchBar } from '../../ui/search-bar.component';
import { useLazySearch } from '../../utils/use-lazy-search';
import { ProfileEditorDialog } from './profile-editor';
import { ProfileItem } from './profile-item';
import SearchIcon from '@material-ui/icons/Search';
import * as qs from 'query-string';
import { useKeysetPagination } from '../../apollo/networking/use-keyset-pagination';
import { LoadingButton } from '../../ui/loading-button';
import moment from 'moment';

type Props = {
}


const QUERY_SEARCH = gql`
    query search_profile($query: String) {
        search_profile(
            args: {search: $query},
        ) {
            ${ProfileFragment}
        }
    }
`;

export const ProfilesPage = ({
	location,
}: RouteComponentProps<Props>) => {
	const navigate = useNavigate();
	const cursor = React.useMemo(() => moment().endOf('hour').toISOString(), []);
	const params = qs.parse(location?.search || '');
	const search = params?.search;
	const {
		items,
		loading,
		error,
		refetch,
		fetchMore,
		has_more,
	} = useKeysetPagination<ProfileModel>({
		entity_name: 'profile',
		query_inner: `where: {
			created_at: {_lt: $cursor}
		}, order_by:{created_at: desc}`,
		fragment: ProfileFragment,
		clause_outer: `$cursor: timestamptz!`,
		variables: {
			cursor,
		}
	})

	const {
		query,
		onSearch,
		onClearSearch,
		is_searching,
		data,
		loading: loading_search,
	} = useLazySearch({
		fragment: QUERY_SEARCH,
		// initial: typeof search === 'string' ? search : undefined,
	});

	const loadMore = () => {
		if (items.length === 0) {
			return;
		}
		fetchMore({
			cursor: items[items.length - 1].created_at,
		})
	}

	const [editor_dialog, setEditorDialog] = React.useState<{
		profile?: ProfileModel;
		action: 'clone' | 'add' | 'edit';
	} | undefined>();

	const onFinish = (profile: ProfileModel, is_new?: boolean) => {
		setEditorDialog(undefined);
		if (is_new) {
			refetch();
		}
	}

	const onEdit = React.useCallback((profile: ProfileModel) => () => {
		setEditorDialog({
			action: 'edit',
			profile,
		})
	}, [setEditorDialog]);


	const onAdd = React.useCallback(() => {
		setEditorDialog({
			action: 'add',
		})
	}, [setEditorDialog]);

	const _items = is_searching ? (data?.search_profile || []) as ProfileModel[]
		: items;

	return <AppLayout>
		{!!editor_dialog ? <ProfileEditorDialog
			profile={editor_dialog.profile}
			action={editor_dialog.action}
			onFinish={onFinish}
			onClose={() => setEditorDialog(undefined)} /> : null}
		<PageHeader title='Profiles'
			subtitle={<>
				<Typography variant='body2'
					color='textSecondary'
					gutterBottom>Speakers, Sponsors and Organizers on Edropin</Typography>
				<Button color='primary' variant='contained'
					onClick={onAdd}>+ Add a Profile</Button>
			</>} />
		<Box pb={2}>
			<Alert severity='info'>
				<AlertTitle>Please search before adding</AlertTitle>
			Make sure you try to search for the person, sponsor or organization
			 before creating a new profile
		</Alert>
		</Box>
		<SearchBar
			query={query}
			onClearSearch={onClearSearch}
			onSearch={onSearch}
			loading={loading} />
		{is_searching && !loading && items.length === 0 ? <EmptyListComponent
			message={`No results found for '${query}'`}
			icon={<SearchIcon />}
		/> : null}
		{error ? <ErrorComponent error={error} title='Failed to load profiles' /> : null}
		{loading || loading_search ? <LoadingDots /> : null}
		<List>
			{_items.map(item => <ProfileItem key={item.id}
				onEdit={onEdit(item)}
				item={item} />)}
		</List>
		{!is_searching ? <Box pt={2} pb={2}>
			<LoadingButton
				loading={loading}
				onClick={loadMore}
				fullWidth
				disabled={!has_more}>Load More</LoadingButton>
		</Box> : null}
	</AppLayout>
}